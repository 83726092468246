import React from "react";
import { useGetConversationParticipantsQuery } from "../../services/conversation.api";
import { Modal } from "../../components/common/Modals";
import AddParticipantsForm from "./AddParticipantsForm";
import dayjs from "dayjs";

interface ConversationParticipantsProps {
  conversationId: string;
}
const ConversationParticipants: React.FC<ConversationParticipantsProps> = ({
  conversationId,
}) => {
  const [open, setOpen] = React.useState(false);

  const take = 10;
  const skip = 0;
  const { data, isLoading } = useGetConversationParticipantsQuery({
    take,
    skip,
    conversationId,
  });
  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div className="my-4 overflow-x-auto bg-white rounded shadow-md">
      <button
        className="px-3 py-2 mb-2 text-white bg-blue-500 rounded-md"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add Participants
      </button>

      <table className="min-w-full bg-white">
        <thead className="text-white bg-gray-800">
          <tr>
            <th className="px-4 py-3 text-sm font-semibold uppercase">
              Participant Name
            </th>
            <th className="px-4 py-3 text-sm font-semibold uppercase">Email</th>
            <th className="px-4 py-3 text-sm font-semibold uppercase">
              Joined At
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {data?.data.map((participant) => (
            <tr key={participant.id}>
              <td className="px-4 py-3">{`${participant.user.firstName} ${participant.user.lastName}`}</td>
              <td className="px-4 py-3">{participant.user.email}</td>
              <td className="px-4 py-3">
                {dayjs(participant.createdAt).format("MMM DD, YYYY hh:mm a")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal title="Add Participants" open={open} setOpen={setOpen}>
        <AddParticipantsForm conversationId={conversationId} />
      </Modal>
    </div>
  );
};

export default ConversationParticipants;
