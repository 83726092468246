import React from "react";
import RTE from "react-rte";
import parse from "html-react-parser";
import {
  Field,
  FieldProps,
  useField,
} from "formik";

interface ComponentProps {
  name: string;
}
const RichTextEditor: React.FC<ComponentProps> = ({ name }) => {
  const [field] = useField(name);
  const [value, setValue] = React.useState<any>(
    RTE.createValueFromString(field.value, "html") || RTE.createEmptyValue()
  );

  const handleChange = (val: any) => {
    setValue(val);
  };
  return (
    <Field name={name}>
      {(props: FieldProps) => {
        return (
          <>
            <RTE
              value={value}
              onChange={(value: any) => {
                props.form.setFieldValue(name, value.toString("html"));
                handleChange(value);
              }}
            />
            <span className="font-semibold">Preview</span>
            <div className="list-disc">{parse(value.toString("html"))}</div>
          </>
        );
      }}
    </Field>
  );
};

export default RichTextEditor;
