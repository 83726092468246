import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateTicketMutation,
} from "../../../services/ticket.api";
import * as Yup from "yup";
interface FormValues {
  section: string;
  row: string;
  seat: number;
  eventTicketId: string;
}
interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateTicket: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateTicketMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    section: "",
    row: "",
    seat: 0,
    eventTicketId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-3">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input placeholder="Name" id="name" {...field} />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 rounded bticket"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateTicket;
