import React from "react";
import { useGetEventQuery } from "../../../../services/event.api";
import dayjs from "dayjs";

interface ComponentProps {
  eventId: string;
  keyId?: number;
  position?: boolean;
}

const EventsCard: React.FC<ComponentProps> = ({ eventId, keyId, position }) => {
  const { data: event, isLoading } = useGetEventQuery(eventId);

  if (isLoading)
    return <h2 className="flex justify-center text-white">Loading..</h2>;

  if (!event)
    return (
      <h2 className="flex justify-center text-white">Something went wrong..</h2>
    );

  return (
    <div
      key={keyId}
      className="flex justify-between w-full border-2 border-x-0 border-dashed py-3 "
    >
      {position ? (
        <>
          <div className="pr-5">
            <div className="w-full text-5xl font-semibold m-0 p-0">
              <a
                href={process.env.REACT_APP_BASE_URL + "/event/" + event?.slug}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="object-cover h-28 w-28"
                  src={
                    event.image?.fileName
                      ? process.env.REACT_APP_IMAGE_BASE_URL +
                        event.image?.fileName
                      : "https://via.placeholder.com/150"
                  }
                />
              </a>
            </div>
          </div>
          <div className="flex flex-col items-end text-right justify-center break-word tracking-normal">
            <p className="text-white text-sm">{event?.name}</p>
            <p className="text-white text-xs">{event?.venue.name}</p>
            <p className="text-white text-xs">
              {dayjs(event.dateTime).format("MMM DD, YYYY hh:mm A")}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-start justify-center break-word tracking-normal">
            <p className="text-white text-sm">{event?.name}</p>
            <p className="text-white text-xs">{event?.venue.name}</p>
            <p className="text-white text-xs">
              {dayjs(event.dateTime).format("MMM DD, YYYY hh:mm A")}
            </p>
          </div>
          <div className="pr-5 items-end">
            <div className="w-full text-5xl font-semibold m-0 p-0">
              <a
                href={process.env.REACT_APP_BASE_URL + "/event/" + event?.slug}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="object-cover h-28 w-28 flex"
                  src={
                    event.image?.fileName
                      ? process.env.REACT_APP_IMAGE_BASE_URL +
                        event.image?.fileName
                      : "https://via.placeholder.com/150"
                  }
                />
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventsCard;
