import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Label } from "../../../components/common";
import { Modal } from "../../../components/common/Modals/Modal";
import { Genre } from "../../../models/genre.model";

import { GenreSelect } from "../../Genre";
import { Option } from "../../../types/general-types";
import {
  useCreatePerformerGenreMutation,
  useDeletePerformerGenreMutation,
  CustomError,
} from "../../../services/performer.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  genres: Genre[];
  performerId: string;
}

interface FormValues {
  genre: Option | undefined;
  performerId: string;
}

export const PerformerGenre: React.FC<ComponentProps> = ({
  genres,
  performerId,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [performerGenre, setPerformerGenre] = React.useState<Genre>();
  const [createPerformerGenre, { isError, error, isLoading, isSuccess }] =
    useCreatePerformerGenreMutation();

  const [
    deletePerformerGenre,
    {
      isError: deleteEventPerformerIsError,
      error: deleteEventPerformerError,
      isLoading: deleteEventPerformerIsLoading,
    },
  ] = useDeletePerformerGenreMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    performerId: performerId,
    genre: undefined,
  };

  const validationSchema = Yup.object().shape({
    performerId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    createPerformerGenre({
      performerId: values.performerId,
      genreId: values.genre?.value || "",
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <div className="mb-2 lg:mb-4">
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Performer Genre</h2>
          <Button title="Add Genre" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {genres.map((genre: Genre, index: number) => (
              <ul className="grid justify-end grid-cols-3 mb-2" key={index}>
                <li>{genre.name}</li>
                <li>{genre.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setPerformerGenre(genre);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Performer Genre" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="performers">Genre</Label>
                  <GenreSelect />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {performerGenre && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Performer Genre`}
          size="s"
          action={deletePerformerGenre}
          item={{
            performerId,
            genreId: performerGenre.id,
          }}
        />
      )}
    </div>
  );
};
