import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../components/common";

import {
  CarouselCustomError,
  useGetCarouselQuery,
  useRemoveCarouselPhotoMutation,
  useUpdateCarouselMutation,
  useUploadCarouselImageMutation,
} from "../../../services/carousel.api";
import * as Yup from "yup";

interface FormValues {
  title: string;
  description?: string;
  buttonText?: string;
  buttonPosition?: string;
  buttonUrl?: string;
  position?: number;
  status: boolean;
}

interface ComponentProps {
  carouselId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateCarousel: React.FC<ComponentProps> = ({
  setOpen,
  carouselId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateCarouselMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadCarouselImageMutation();

  const { data: carousel, isLoading: carouselIsLoading } =
    useGetCarouselQuery(carouselId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveCarouselPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (carousel) {
      update({
        id: carousel.id,
        ...values,
      });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: carouselId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(carouselId);
  };

  if (isLoading || carouselIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!carousel) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    title: carousel.title || "",
    description: carousel?.description || "",
    buttonText: carousel?.buttonText || "",
    buttonPosition: carousel?.buttonPosition || "",
    buttonUrl: carousel?.buttonUrl || "",
    position: carousel?.position || 0,
    status: carousel?.status || false,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CarouselCustomError).data.message.map(
                      (message) => (
                        <li>{message}</li>
                      )
                    )}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {carousel && carousel.image ? (
                    <img
                      alt={`${carousel.title}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        carousel.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="title">Title</Label>
                <Field name="title">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Title" id="title" {...field} />
                  )}
                </Field>

                <Label htmlFor="description">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>

                <Label htmlFor="position">Position</Label>
                <Field name="position">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Position 0 1 2 3"
                      id="position"
                      {...field}
                      type="number"
                    />
                  )}
                </Field>
                <Label htmlFor="description">Button Text</Label>
                <Field name="buttonText">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Text" id="buttonText" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Button Position</Label>
                <Field name="buttonPosition">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Position" id="buttonPosition" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Button Url</Label>
                <Field name="buttonUrl">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Url" id="buttonUrl" {...field} />
                  )}
                </Field>
                <Label htmlFor="status">Status</Label>
                <Field name="status">
                  {({ field }: FieldProps) => (
                    <Checkbox id="status" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
