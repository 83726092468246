import { ErrorMessage, Field } from "formik";
import React from "react";
import { useGetVenuesQuery } from "../../../services/venue.api";

interface ComponentProps {
  className?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const VenueSelect: React.FC<TExtendedProps> = ({ className, ...props }) => {
  const { data: venue } = useGetVenuesQuery({});

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`py-2 border rounded `}
        as="select"
        name="venueId"
      >
        <option hidden>-- Select Venue --</option>
        {venue &&
          venue.data.map((venue, index) => (
            <option key={index} value={venue.id}>
              {venue.name}
            </option>
          ))}
      </Field>
      <ErrorMessage name="venueId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default VenueSelect;
