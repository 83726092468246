import { UpdateEvent } from "../models/event.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  DeleteEventTicket,
  EventTicket,
  EventTicketByEvent,
} from "../models/event-ticket.model";
import { Conversation, CreateConversation } from "../models/conversation.model";
import { CreateEventTicketInclusion, DeleteEventTicketInclusion, EventTicketInclusion } from "../models";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface EventTickets {
  data: EventTicket[];
  skip: number;
  take: number;
  count: number;
}
interface EventTicketConversations {
  data: Conversation[];
  skip: number;
  take: number;
  count: number;
}
interface EventTicketsByEvent {
  data: EventTicketByEvent[];
  currentCount: number;
  skip: number;
  take: number;
  count: number;
}

interface EventTicketInclusions {
  data: EventTicketInclusion[];
  currentCount: number;
  skip: number;
  take: number;
  count: number;
}

interface EventsQuery {
  skip?: number;
  take?: number;
  search?: string;
  eventId?: string;
}

interface EventTicketInclusionQuery {
  skip?: number;
  take?: number;
  search?: string;
  eventTicketId?: string;
}

interface EventsTicketConversationQuery {
  skip?: number;
  take?: number;
  search?: string;
  eventTicketId?: string;
}


export const eventTicketApi = createApi({
  reducerPath: "eventTicketApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/event-ticket",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["EventTickets", "EventTicket", "EventTicketsByEvent", "EventTicketConversation", "EventTicketInclusions"],
  endpoints: (builder) => ({
    // createEvent: builder.mutation<EventTicket, CreateEventTicket>({
    //   query: (body) => ({
    //     url: "/",
    //     method: "POST",
    //     body,
    //   }),
    //   invalidatesTags: ["EventTickets"],
    // }),
    createEventTicketConversation: builder.mutation<Conversation, CreateConversation>({
      query: (body) => {
        console.log("createEventTicketConversation", body)
        return {
          url: `/${body.eventTicketId}/conversations`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["EventTicketConversation"],
    }),
    createEventTicketInclusion: builder.mutation<EventTicketInclusion, CreateEventTicketInclusion>({
      query: (body) => {
        return {
          url: `/${body.eventTicketId}/inclusions`,
          method: "POST",
          body,
        }
      },
      invalidatesTags: ["EventTicketInclusions"],
    }),
    deleteEventTicketInclusion: builder.mutation<unknown, DeleteEventTicketInclusion>({
      query: (body) => {
        return {
          url: `/${body.eventTicketId}/inclusions/${body.eventTicketInclusionId}`,
          method: "DELETE",
          body,
        }
      },
      invalidatesTags: ["EventTicketInclusions"],
    }),
    getEventTicketInclusion: builder.query<EventTicketInclusions, EventTicketInclusionQuery>({
      query: ({ take, skip, search, eventTicketId }) => {
        return {
          url: `/${eventTicketId}/inclusions`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["EventTicketInclusions"],
    }),
    getEventTicketOrders: builder.query<void, string>({
      query: (eventTicketId) => {
        return {
          url: `/${eventTicketId}/orders`,
          responseHandler: async (response) => {
            const dllink = document.createElement("a");
            dllink.href = window.URL.createObjectURL(await response.blob());
            dllink.setAttribute("download", `Event-Ticket-Orders-(${eventTicketId}).csv`); // Added Line
            dllink.click();
          },
          cache: "no-cache",
        };
      },
    }),

    getEventTicketConversation: builder.query<EventTicketConversations, EventsTicketConversationQuery>({
      query: ({ take, skip, search, eventTicketId }) => {
        return {
          url: `/${eventTicketId}/conversations`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["EventTicketsByEvent"],
    }),

    getEventTicketByEvent: builder.query<EventTicketsByEvent, EventsQuery>({
      query: ({ take, skip, search, eventId }) => {
        return {
          url: `/${eventId}/tickets`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["EventTicketsByEvent"],
    }),

    getEventTickets: builder.query<EventTickets, EventsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["EventTickets"],
    }),
    getEvent: builder.query<EventTicket, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["EventTicket"],
    }),
    updateEvent: builder.mutation<EventTicket, UpdateEvent>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["EventTickets", "EventTicket"],
    }),

    deleteEventTicket: builder.mutation<void, DeleteEventTicket>({
      query: ({ eventId, eventTicketId }) => ({
        url: `/${eventId}/event-ticket/${eventTicketId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventTicket"],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetEventTicketsQuery,
  useGetEventTicketByEventQuery,
  useUpdateEventMutation,
  useDeleteEventTicketMutation,
  useGetEventTicketConversationQuery,
  useCreateEventTicketConversationMutation,
  useLazyGetEventTicketsQuery,
  useCreateEventTicketInclusionMutation,
  useGetEventTicketInclusionQuery,
  useDeleteEventTicketInclusionMutation,
  useLazyGetEventTicketOrdersQuery
} = eventTicketApi;
