import React from "react";

interface ComponentProps {
  title: string;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export const DashboardCard: React.FC<ComponentProps> = ({
  children,
  title,
  className,
}) => {
  return (
    <div className="flex flex-col overflow-hidden border rounded-[8px] rounded">
      <div className="p-2 lg:p-4 bg-lightBlue-500">
        <h2 className="text-2xl text-white capitalize">{title}</h2>
      </div>
      <div className={`flex-grow p-2 lg:p-4 ${className}`}>{children}</div>
    </div>
  );
};
