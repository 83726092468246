import React from "react";
import { Modal } from "../../components/common/Modals/Modal";
import CreateTicket from "../../features/Ticket/CreateTicket";
import TicketList from "../../features/Ticket/TicketList";

export const TicketPage = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };
  return (
    <div>
      <div className="flex items-center justify-between p-6 mb-6 rounded shadow-sm bticket align">
        <h2 className="text-2xl font-semibold ">Ticket</h2>
        <div className="flex items-center flex-1 px-6 ">
          <input
            className="flex-1 px-4 py-2 bticket bticket-blue-900 rounded-l-md"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            className="px-4 py-2 text-white bg-blue-900 bticket-r bticket-blue-900 bticket-y rounded-r-md"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <button
          className="w-24 px-4 py-2 text-blue-900 rounded bticket bticket-blue-900"
          type="button"
          onClick={() => setOpen(true)}
        >
          Add
        </button>
      </div>
      <TicketList search={search} />
      <Modal open={open} setOpen={setOpen} title="Add Ticket" size="m">
        <CreateTicket setOpen={setOpen} />
      </Modal>
    </div>
  );
};
