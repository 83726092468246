import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../components/common";
import { Ticket } from "../../../models/ticket.model";
import {
  useDeleteTicketMutation,
  useGetTicketsQuery,
} from "../../../services/ticket.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import UpdateTicket from "../UpdateTicket";

interface ComponentProps {
  search?: string;
}
const TicketList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [ticket, setTicket] = React.useState<Ticket>();
  const { data, isLoading } = useGetTicketsQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteTicket] = useDeleteTicketMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div>
        <ul className="grid grid-cols-6 p-4 font-semibold">
          <li className="mr-4">ID</li>
          <li>Event Name</li>
          <li>Date</li>
          <li>Code</li>
          <li>Status</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((ticket: Ticket, index: number) => (
              <ul
                className="grid items-center grid-cols-6 p-4 rounded shadow-sm bticket"
                key={index}
              >
                <li className="mr-4">{ticket.id}</li>
                <li>{ticket.eventTicket.event?.name}</li>
                <li>{ticket.eventTicket.event?.dateTime.toString()}</li>
                <li>{ticket.code}</li>
                <li className="text-white">
                  {ticket.status === "available" ? (
                    <span className="px-4 py-2 bg-green-400 rounded">
                      {ticket.status}
                    </span>
                  ) : (
                    <span className="px-4 py-2 bg-red-400 rounded">
                      {ticket.status}
                    </span>
                  )}
                </li>
                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setTicket(ticket);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setTicket(ticket);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {ticket && (
        <Modal open={open} setOpen={setOpen} title="Update Ticket" size="m">
          <UpdateTicket setOpen={setOpen} ticketId={ticket.id} />
        </Modal>
      )}
      {ticket && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Ticket`}
          size="s"
          action={deleteTicket}
          item={ticket.id}
        />
      )}
    </div>
  );
};

export default TicketList;
