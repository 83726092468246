import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { Input, Label } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals";
import { Event } from "../../../../models/event.model";
import CarouselSelect from "../../Components/SelectCarousel";
import SelectEvent from "../../Components/SelectEvent";
import SelectUser from "../../Components/SelectUser";
import EventAnnouncementEmail from "./EventAnnouncementEmail";

interface FormValues {
  eventId: string;
  eventName: string;
  eventDate: string;
  imageFileName: string;
  eventAddress: string;
  userEmails?: string[];
  receiver?: string;
  title: string;
  description: string;
}

const AnnouncementTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<Event | undefined>();
  const [item, setItem] = React.useState<FormValues>();
  const [values, setValues] = React.useState<FormValues | undefined>();
  const [usersEmail, setUsersEmail] = React.useState<string[]>([]);

  const initialValues: FormValues = {
    eventId: event?.id || "",
    eventName: event?.name || "",
    receiver: "default",
    eventDate: dayjs(event?.dateTime).format("MMMM DD, YYYY - hh:mmA") || "",
    eventAddress: event?.venue?.address1 || "",
    imageFileName: event?.image?.fileName || "",
    userEmails: usersEmail || [],
    title: "Important Information for your Event",
    description: "Announcement: ",
  };

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().required("Event ID is required."),
    eventName: Yup.string().required("Event Name is required."),
    eventDate: Yup.string().required("Date is required."),
    eventAddress: Yup.string().required("Address is required."),
    userEmails: Yup.array(),
    title: Yup.string().required("Date is required."),
    description: Yup.string().required("Date is required."),
    imageFileName: Yup.string().required("Image is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setOpen(true);
    setItem({
      ...values,
    });
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title="Send Email Announcement"
        size="s"
      >
        <EventAnnouncementEmail
          setOpen={setOpen}
          item={item}
          setItem={setItem}
        />
      </Modal>
      <div className="flex flex-col items-center justify-center gap-5 p-3 font-roboto lg:items-start lg:flex-row ">
        <Formik
          innerRef={(formikActions) =>
            formikActions
              ? setValues(formikActions.values)
              : setValues(undefined)
          }
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <div className="grid p-5 w-[500px] gap-2 border rounded-md">
              <SelectEvent
                setUsersEmail={setUsersEmail}
                event={event}
                setEvent={setEvent}
              />
              {event && (
                <Form>
                  <div className="grid items-center grid-cols-3 gap-6 pt-6">
                    <Label htmlFor="eventName">Event Name</Label>
                    <Field name="eventName">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Name"
                          id="eventName"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDate">Date</Label>
                    <Field name="eventDate">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Date"
                          id="eventDate"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventAddress">Address</Label>
                    <Field name="eventAddress">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Address"
                          id="eventAddress"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="imageFileName">Change Image</Label>
                    <CarouselSelect
                      name="imageFileName"
                      className="col-span-2"
                    />
                    <Label htmlFor="title">Announcement Title</Label>
                    <Field name="title">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Announcement Title"
                          id="title"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="description">
                      Announcement Description
                    </Label>
                    <Field name="description">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Announcement Description"
                          id="description"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="userEmails">Add Users</Label>
                    <SelectUser
                      className="col-span-2"
                      setUsersEmail={setUsersEmail}
                      eventId={event.id}
                      emailType="ANNOUNCEMENT"
                    />

                    <Label htmlFor="receiver">Receiver</Label>
                    <Field name="receiver">
                      {({ field }: FieldProps) => (
                        <select {...field}>
                          <option value="default">Default</option>
                          <option value="all">All</option>
                        </select>
                      )}
                    </Field>
                    <div className="col-span-3">
                      <button
                        type="submit"
                        className="w-full px-4 py-2 font-bold text-white border rounded bg-darkBlue-700"
                      >
                        Send Event Announcement Email
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </Formik>
        <div className="w-[500px] h-fit border rounded-md font-roboto tracking-wider">
          <div className="p-6 font-semibold text-center text-white bg-lightBlue-300 rounded-t-md">
            {values?.title || "Announcement Title"}
          </div>
          <div className="w-full h-fit">
            <div className="flex items-center justify-center object-cover w-full h-full overflow-hidden bg-black">
              {values?.imageFileName && values.eventName && (
                <img
                  alt={`${values.eventName}_image`}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}${values.imageFileName}`}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col gap-1 py-4 border-t">
              {values?.description || "Announcement Description"}
            </div>
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="font-bold text-md">
                {values?.eventName || "Event Name"}
              </p>
              <p className="text-sm">{values?.eventDate || "Event Date"}</p>
              <p className="text-sm">
                {values?.eventAddress || "Event Address"}
              </p>
              <a
                target="_blank"
                href={process.env.REACT_APP_BASE_URL + "/event/" + event?.slug}
                rel="noreferrer"
              >
                <div className="p-2 text-sm font-semibold text-center text-white rounded-md bg-darkBlue-700">
                  CHECK TICKET
                </div>
              </a>
            </div>
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="font-bold text-md">Stay tuned for more updates!</p>
              <p className="pb-3 text-sm">
                We hope this announcement brings clarity. Should you have any
                questions or need further information, please don't hesitate to
                reach out. Thank you for your continued support!
              </p>
              <p className="text-xs text-end">
                © 2022 Ticketnation Philippines
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementTemplate;
