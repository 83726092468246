import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

interface ComponentProps {
  className: string;
  children?: JSX.Element | JSX.Element[];
}
const Sidebar: React.FC<ComponentProps> = ({ children, className }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    if (isDesktopOrLaptop) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isDesktopOrLaptop]);
  
  return (
    <div className={` ${className} relative`}>
      <button
        className="absolute right-[-75px] top-20 bg-blue-500 text-white z-100 rounded-r-full px-2 py-1 w-20"
        onClick={() => setOpen(!open)}
      >
        {open ? "Close" : "Open"}
      </button>

      {open ? (
        <div className="p-2 overflow-y-auto lg:p-6 w-60">{children}</div>
      ) : (
        <div className="w-12" />
      )}
    </div>
  );
};

export default Sidebar;
