import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CarouselCustomError,
  useCreateCarouselMutation,
} from "../../../services/carousel.api";
import * as Yup from "yup";

interface FormValues {
  title: string;
  buttonText?: string;
  buttonPosition?: string;
  buttonUrl?: string;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCarousel: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateCarouselMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    title: "",

  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required."),
    description: Yup.string().required("Description is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {

      create({
        ...values,
      });
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values }) => {
  
        return (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CarouselCustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="title">Name</Label>
                <Field name="title">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Title" id="title" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Description" id="description" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Button Text</Label>
                <Field name="buttonText">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Text" id="buttonText" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Button Position</Label>
                <Field name="buttonPosition">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Position" id="buttonPosition" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Button Url</Label>
                <Field name="buttonUrl">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Button Url" id="buttonUrl" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
