import React from "react";
import { PaginationContainer, TabModal } from "../../../components/common";
import { User } from "../../../models/user.model";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../../services/user.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import UpdateUser from "../UpdateUser";
import UpdateUserRole from "../UserRole";
import ActionDropdown from "../../../components/common/ActionDropdown";
import { Edit, Eye, Tool, XCircle } from "react-feather";

interface ComponentProps {
  search?: string;
}

const UserList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState("Update User");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  const [user, setUser] = React.useState<User>();
  const { data, isLoading } = useGetUsersQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteUser] = useDeleteUserMutation();
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <>
      {data && (
        <div>
          <div>
            <table className="w-full border-separate border-spacing-y-2">
              <thead className="border-gray-200 bg-gray-50">
                <tr className="rounded-lg">
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    ID
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Photo
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Email
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Subscribe
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Role
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Organization
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Is Owner
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Is Active
                  </th>
                  <th className="p-3 text-sm tracking-wide text-left font semibold">
                    Action
                  </th>
                </tr>
              </thead>
              {data && (
                <tbody>
                  {data.data?.map((user: User, index: number) => (
                    <tr
                      key={index}
                      className={`bg-opacity-50 shadow-sm hover:shadow-md hover:bg-blue-200 ${
                        (index + 1) % 2 == 0 ? "bg-blue-100" : "bg-white"
                      }`}
                    >
                      <td className="p-3 text-sm text-gray-700 border-l rounded-l whitespace-nowrap border-y">
                        <button
                          className="px-2 py-1 border rounded-md hover:text-white hover:bg-blue-500"
                          onClick={() => navigator.clipboard.writeText(user.id)}
                        >
                          COPY ID
                        </button>
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        <div className="flex items-center justify-center overflow-hidden bg-black rounded w-14 h-14">
                          {user && user.image ? (
                            <img
                              alt={`${user.firstName}_image`}
                              src={
                                process.env.REACT_APP_IMAGE_BASE_URL +
                                user.image?.fileName
                              }
                            />
                          ) : (
                            <div className="bg-black " />
                          )}
                        </div>
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.email}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.subscribe ? "Subscribe" : "Not Subscribe"}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.userRoles
                          ?.map((role) => role.role?.name)
                          .join(", ")}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.organization
                          ? user.organization.name
                          : "No Organization"}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.isOrgOwner ? (
                          <span className="px-2 py-1 text-white bg-green-500 rounded-md">
                            Owner
                          </span>
                        ) : (
                          <span className="px-2 py-1 text-white bg-red-500 rounded-md">
                            Not Owner
                          </span>
                        )}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap border-y border-x-0">
                        {user.isActive ? (
                          <span className="px-2 py-1 text-white bg-green-500 rounded-md">
                            Active
                          </span>
                        ) : (
                          <span className="px-2 py-1 text-white bg-red-500 rounded-md">
                            Not Active
                          </span>
                        )}
                      </td>
                      <td className="p-3 text-sm text-gray-700 border-r rounded-r whitespace-nowrap border-y">
                        <ActionDropdown
                          placeholder="Actions"
                          icon={<Tool className="w-5 h-5" />}
                          options={[
                            {
                              label: "View",
                              onClick: () => {},
                              icon: <Eye className="w-5 h-5" />,
                            },
                            {
                              label: "Edit",
                              onClick: () => {
                                setOpen(true);
                                setUser(user);
                              },
                              icon: <Edit className="w-5 h-5" />,
                            },
                            {
                              label: "Delete",
                              onClick: () => {
                                setOpenDelete(true);
                                setUser(user);
                              },
                              icon: <XCircle className="w-5 h-5" />,
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          <PaginationContainer
            totalCount={data.count}
            currentPage={page}
            pageSize={take}
            onPageChange={handlePageChange}
          />
        </div>
      )}
      {user && (
        <TabModal
          open={open}
          setOpen={setOpen}
          active={active}
          setActive={setActive}
          titles={["Update User", "User Role"]}
          size="m"
        >
          {active === "Update User" ? (
            <UpdateUser setOpen={setOpen} userId={user.id} />
          ) : (
            <UpdateUserRole setOpen={setOpen} userId={user.id} />
          )}
        </TabModal>
      )}
      {user && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete User`}
          size="s"
          action={deleteUser}
          item={user.id}
        />
      )}
    </>
  );
};

export default UserList;
