import React from "react";

import {
  CustomError,
  useCreateArtistInquiryMutation,
} from "../../../services/artist-inquiry.api";
import { ArtistInquiryForm } from "../ArtistInquiryForm";

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateArtistInquiry: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateArtistInquiryMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <ArtistInquiryForm
      onSubmit={create}
      isError={isError}
      error={error as CustomError}
      isSuccess={isSuccess}
    />
  );
};
