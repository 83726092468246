import React from "react";
import { ProductVariantList } from "./ProductVariantList";
import { Modal } from "../../../components/common/Modals";
import { CreateProductVariant } from "./CreateProductVariant";

interface ComponentProps {
  productId: string;
}
const ProductVariant: React.FC<ComponentProps> = ({ productId }) => {
  const [open, setOpen] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [search, setSearch] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };
  return (
    <div>
      <h2 className="text-2xl font-semibold ">Product Variants</h2>
      <div className="flex items-center flex-1 px-6 ">
        <input
          className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <button
          className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="flex justify-end my-2">
      <button
        className="w-24 px-4 py-2 text-blue-900 border border-blue-900 rounded"
        type="button"
        onClick={() => setOpen(true)}
      >
        Add
      </button>
      </div>

      <ProductVariantList productId={productId} search={search} />
      <Modal open={open} setOpen={setOpen} title="Add Product Variant" size="m">
        <CreateProductVariant setOpen={setOpen} productId={productId}/>
      </Modal>
    </div>
  );
};

export default ProductVariant;
