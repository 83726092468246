import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import React from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import Dropdown from "../../../../components/common/Dropdown";
import { EventGroup } from "../../../../models/event-group.model";
import { Order } from "../../../../models/order.model";
import { useGetOrdersByEventGroupQuery } from "../../../../services/order.api";
import TicketsModal from "../../../Event/UpdateEvent/EventOrders/TicketsModal";
import ResendEmailModal from "../../../Event/UpdateEvent/ResendEmailModal";
import "./index.css";

interface ComponentProps {
  eventGroup: EventGroup;
}

const EventGroupEventOrders: React.FC<ComponentProps> = ({ eventGroup }) => {
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [eventDate, setEventDate] = React.useState("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [ticketModal, setTicketModal] = React.useState<string>("");
  const [resendEmailId, setResendEmailId] = React.useState<string>("");
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { data, isLoading } = useGetOrdersByEventGroupQuery({
    id: eventGroup.id,
    from,
    to,
    search,
    eventDate,
  });

  const getTotalTicketFee = (order: Order) =>
    `${order.orderEventTickets.reduce((prev, curr) => {
      return prev + (curr.eventTicket.perTicketFee || 0) * curr.quantity;
    }, 0)}.00`;

  const getSubTotal = (order: Order) =>
    `${order.orderEventTickets.reduce((prev, curr) => {
      return prev + curr.price * curr.quantity;
    }, 0)}.00`;

  const getTotal = (order: Order) =>
    `${
      order.orderFee +
      order.orderEventTickets.reduce((prev, curr) => {
        return prev + (curr.eventTicket.perTicketFee || 0) * curr.quantity;
      }, 0) +
      order.orderEventTickets.reduce((prev, curr) => {
        return prev + curr.total;
      }, 0)
    }.00`;

  if (isLoading) <h2>Loading...</h2>;

  return (
    <>
      <div className="p-4 border rounded shadow-sm" ref={componentRef}>
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Group Orders</h2>
          <Button
            className="no-print"
            title="Print Event Group Orders"
            onClick={handlePrint}
          />
        </div>
        <div className="mb-2">
          <h3>Filters</h3>
          <div className="flex">
            <input
              className="flex-1 p-2 mr-2 border rounded-md"
              value={searchInput}
              placeholder="Search"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <Button title="Search" onClick={() => setSearch(searchInput)} />
          </div>
          <div className="flex items-center my-5 justify-between">
            <div className="flex items-center">
              <label className="mr-2 font-semibold">Date from</label>
              <input
                className="p-2 mr-2 border rounded-md"
                type="date"
                placeholder="From"
                onChange={(e) => {
                  setFrom(e.target.value + " 00:00:00");
                }}
              />
              <label className="mr-2 font-semibold">Date to</label>
              <input
                className="p-2 border rounded-md"
                type="date"
                placeholder="To"
                onChange={(e) => {
                  setTo(e.target.value + " 23:59:59");
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="mr-2 font-semibold">Filter Date</label>
              <Dropdown
                onChange={(value) => setEventDate(value)}
                iconPosition="right"
                icon={<FontAwesomeIcon icon={faAngleDown} />}
                setValue={setEventDate}
                placeholder={
                  eventDate
                    ? dayjs(eventDate).format("MMMM DD, YYYY - hh:mm A")
                    : "Select Event Date"
                }
                defaultValue={eventDate}
                options={
                  eventGroup?.eventGroupEvents.map(({ event }) => ({
                    label: dayjs(event.dateTime).format(
                      "MMMM DD, YYYY - hh:mm A"
                    ),
                    value: event.dateTime,
                  })) || []
                }
              />
            </div>
          </div>
        </div>
        <div className="">
          <ul
            className="grid justify-end grid-cols-12 mb-4 text-lg font-semibold"
            // style={{ gridTemplateColumns: "repeat(13, minmax(0, 1fr))" }}
          >
            <li className="col-span-2">
              <div className="flex gap-8">
                <span />
                <span>Order ID</span>
              </div>
            </li>
            <li className="col-span-1">Email</li>
            <li className="col-span-1">Event Date</li>
            <li className="col-span-1 col-print">Order Date</li>
            <div className="grid grid-cols-7 col-span-7 text-center grid-print">
              <li>Order Fee</li>
              <li>Ticket Type</li>
              <li>Quantity</li>
              <li>Total Ticket Fee</li>
              <li>Sub-Total</li>
              <li>Total</li>
              <li className="col-span-1 pr-5 text-right no-print">Actions</li>
            </div>
          </ul>
          <div className="mb-4">
            {data &&
              data.data.map((order: Order, index) => (
                <ul
                  key={index}
                  className="grid items-center justify-end grid-cols-12 mb-2"
                >
                  <li className="col-span-2">
                    <div className="flex gap-8">
                      <span>{index + 1}</span>
                      <span className="pr-4">{order.id}</span>
                    </div>
                  </li>
                  <li className="flex flex-wrap col-span-1 pr-2">
                    <p className="break-all ...">{order.user.email}</p>
                  </li>
                  <li className="flex flex-wrap col-span-1 pr-2">
                    {dayjs(
                      order.orderEventTickets[0].eventTicket.event?.dateTime
                    ).format("MMM-DD-YYYY")}
                  </li>
                  <li className="col-span-1 col-print">
                    {dayjs(order.createdAt).format("MM-DD-YY HH:MM A")}
                  </li>
                  {order.orderEventTickets.length > 0 && (
                    <div className="grid items-center grid-cols-7 col-span-7 text-center grid-print">
                      <li>{order.orderFee}.00</li>
                      <li>
                        {order.orderEventTickets.reduce((prev, curr) => {
                          return prev + prev !== ""
                            ? ","
                            : "" + curr.eventTicket.section;
                        }, "")}
                      </li>
                      <li>
                        {order.orderEventTickets.reduce((prev, curr) => {
                          return prev + curr.quantity;
                        }, 0)}
                      </li>
                      <li>
                        {/* {order.orderEventTickets[0].eventTicket.perTicketFee ||
                          0 * order.orderEventTickets[0].quantity} */}
                        {getTotalTicketFee(order)}
                      </li>

                      <li>{getSubTotal(order)}</li>
                      <li>{getTotal(order)}</li>
                      <li className="flex flex-col justify-end col-span-1 md:flex-row no-print">
                        <Button
                          title="Resend"
                          variant="secondary"
                          className="px-2 mr-1"
                          onClick={() => {
                            setOpen(true);
                            setResendEmailId(order.id);
                          }}
                        />
                        <Button
                          title="View"
                          variant="secondary"
                          className="px-2"
                          onClick={() => {
                            setTicketModal(order.id);
                          }}
                        />
                      </li>
                    </div>
                  )}
                  {ticketModal === order.id && (
                    <TicketsModal
                      isOpen={ticketModal === order.id}
                      setOpen={setTicketModal}
                      orderEventTickets={order.orderEventTickets}
                    />
                  )}
                </ul>
              ))}
          </div>
        </div>
        <ul className="grid justify-end grid-cols-12 mb-4 text-lg font-semibold">
          <li className="col-span-5 col-span-print" />
          <div className="grid grid-cols-6 col-span-6 text-center">
            <li className="text-center">
              {data?.data.reduce((prev, curr) => {
                return prev + curr.orderFee;
              }, 0)}
              .00
            </li>
            <li className="col-span-1" />
            <li className="col-span-1">
              {data?.data
                .map((d) =>
                  d.orderEventTickets.length > 0
                    ? d.orderEventTickets.reduce((prev, curr) => {
                        return prev + curr.quantity;
                      }, 0)
                    : 0
                )
                .reduce((partialSum, a) => partialSum + a, 0)}
            </li>
            <li className="col-span-1">
              {data?.data
                .map((d) =>
                  d.orderEventTickets.length > 0
                    ? d.orderEventTickets.reduce((prev, curr) => {
                        return (
                          prev +
                          (curr.eventTicket.perTicketFee || 0) * curr.quantity
                        );
                      }, 0)
                    : 0
                )
                .reduce((partialSum, a) => partialSum + a, 0)}
              .00
            </li>
            <li className="col-span-1">
              {data?.data
                .map((d) =>
                  d.orderEventTickets.length > 0
                    ? d.orderEventTickets.reduce((prev, curr) => {
                        return prev + curr.price * curr.quantity;
                      }, 0)
                    : 0
                )
                .reduce((partialSum, a) => partialSum + a, 0)}
              .00
            </li>
            <li className="col-span-1">
              {data?.data
                .map(
                  (d) =>
                    (d.orderEventTickets.length > 0
                      ? d.orderEventTickets.reduce((prev, curr) => {
                          return prev + curr.total;
                        }, 0)
                      : 0) +
                    (d.orderFee ? d.orderFee : 0) +
                    d.orderEventTickets.reduce((prev, curr) => {
                      return (
                        prev +
                        (curr.eventTicket.perTicketFee || 0) * curr.quantity
                      );
                    }, 0)
                )
                .reduce((partialSum, a) => partialSum + a, 0)}
              .00
            </li>
          </div>
          <li />
        </ul>
      </div>
      <Modal open={open} setOpen={setOpen} title="Confirm Resend" size="xs">
        <ResendEmailModal
          setOpen={setOpen}
          resendEmailId={resendEmailId}
          setResendEmailId={setResendEmailId}
        />
      </Modal>
    </>
  );
};

export default EventGroupEventOrders;
