import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import CreateOrder from "../../../features/Order/CreateOrder";
import OrderList from "../../../features/Order/OrderList";
import CreateOrderV2 from "../../../features/Order/CreateOrder/CreateOrderV2";

export const OrderPage = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };
  return (
    <div className="flex flex-col flex-grow h-full ">
      <div className="flex flex-col p-2 mb-6 border rounded shadow-sm lg:p-6 lg:justify-between lg:flex-row align">
        <h2 className="mb-1 text-2xl font-semibold lg:mb-0">Order</h2>
        <div className="flex items-center flex-1 mb-1 lg:px-6 lg:mb-0">
          <input
            className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <button
          className="w-24 px-4 py-2 text-blue-900 border border-blue-900 rounded"
          type="button"
          onClick={() => setOpen(true)}
        >
          Add
        </button>
      </div>
      <OrderList search={search} />
      <Modal open={open} setOpen={setOpen} title="Add Order" size="m">
        {/* <CreateOrder setOpen={setOpen} /> */}
        <CreateOrderV2 setOpen={setOpen} />
      </Modal>
    </div>
  );
};
