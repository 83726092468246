import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../";
import * as Yup from "yup";

export interface FieldDefinition {
  name: string;
  type: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  customInput?: any;
  defaultValue?: any | boolean; // Add boolean to defaultValue
  customValidation?: any;
}

interface FormValues {
  [key: string]: any;
}

interface ComponentProps {
  buttonText?: string
  fields: FieldDefinition[];
  onSubmit: (values: FormValues) => void;
}

const DynamicForm: React.FC<ComponentProps> = ({ fields, onSubmit, buttonText="Submit" }) => {
  const initialValues: FormValues = fields.reduce<FormValues>((acc, field) => {
    acc[field.name] =
      field.defaultValue !== undefined
        ? field.defaultValue
        : field.required
        ? ""
        : undefined; // Use null for non-required fields
    return acc;
  }, {});

  const validationSchema = Yup.object().shape(
    fields.reduce<Record<string, Yup.BaseSchema>>((acc, field) => {
      if (field.customValidation) {
        acc[field.name] = field.customValidation;
      } else {
        acc[field.name] = field.required
          ? Yup.string().required()
          : Yup.mixed().nullable();
      }
      // Use Yup's mixed() and nullable() methods for non-required fields
      return acc;
    }, {})
  );

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    const parsedValues: FormValues = {};
    Object.keys(values).forEach((key) => {
      const fieldDefinition = fields.find((field) => field.name === key);
      if (fieldDefinition && fieldDefinition.type === "number") {
        parsedValues[key] = parseFloat(values[key]);
      } else {
        parsedValues[key] = values[key];
      }
    });
    onSubmit(parsedValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {fields.map((field) => (
              <div key={field.name} className="grid grid-cols-2 gap-2">
                <Label htmlFor={field.name}>
                  {field.label || field.name} {field.required ? "*" : ""}
                </Label>
                <Field name={field.name}>
                  {({ field: formikField, form }: FieldProps) => {
                    if (field.customInput) {
                      if (field.type === "toggle") {
                        formikField.checked = formikField.value;
                        return React.cloneElement(field.customInput, {
                          ...formikField,
                          onClick: () =>
                            form.setFieldValue(
                              field.name,
                              !formikField.checked
                            ),
                        });
                      } else {
                        return React.cloneElement(
                          field.customInput,
                          formikField
                        );
                      }
                    } else {
                      return (
                        <Input
                          type={field.type}
                          placeholder={field.placeholder || ""}
                          id={field.name}
                          {...formikField}
                        />
                      );
                    }
                  }}
                </Field>
              </div>
            ))}
            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              {buttonText}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicForm;
