import React from "react";
import DynamicForm, {
  FieldDefinition,
} from "../../../../components/common/DynamicForm";
import {
  useCreateProductVariantMutation,
  CustomError,
} from "../../../../services/product-variant.api";

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: string
}

export const CreateProductVariant: React.FC<ComponentProps> = ({ setOpen, productId }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateProductVariantMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const formFields: FieldDefinition[] = [
    { name: "name", type: "text", label: "Name", required: true },
    { name: "description", type: "text", label: "Description" },
    { name: "slug", type: "text", label: "Slug" },
    { name: "size", type: "text", label: "Size" },
    { name: "color", type: "text", label: "Color" },
    { name: "weight", type: "number", label: "Weight", defaultValue: 0 },
    { name: "height", type: "number", label: "Height", defaultValue: 0 },
    { name: "quantity", type: "number", label: "Quantity", required: true },
    { name: "price", type: "number", label: "Price", required: true },
    { name: "sku", type: "text", label: "SKU" },
    { name: "isPublished", type: "toggle", label: "Publish" },
  ];

  const handleSubmit = (values: any) => {
    create({ productId: productId, ...values });
  };

  if (isLoading) return <h2>Loading...</h2>;
  return <DynamicForm fields={formFields} onSubmit={handleSubmit} />;
};
