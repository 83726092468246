import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  ProductOrder,
  ProductOrderVariant,
} from "../models/product-order.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ProductOrders {
  data: ProductOrder[];
  skip: number;
  take: number;
  count: number;
}

interface ProductOrderVariants {
  data: ProductOrderVariant[];
  skip: number;
  take: number;
  count: number;
}

interface ProductOrderQuery {
  id?: string | string[];
  skip?: number;
  take?: number;
  search?: string;
  from?: string;
  exchange?: string;
  status?: string;
  to?: string;
  eventDate?: string;
  isWithdraw?: boolean;
  emailType?: string;
  eventsId?: string[];
}

export const productOrderApi = createApi({
  reducerPath: "productOrderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/product-order",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: [
    "ProductOrders",
    "ProductOrder",
    "EventProductOrders",
    "EventProductOrder",
  ],
  endpoints: (builder) => ({
    getProductOrders: builder.query<ProductOrders, ProductOrderQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["ProductOrders"],
    }),
    getProductOrder: builder.query<ProductOrder, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["ProductOrder"],
    }),
    getEventProductOrders: builder.query<ProductOrders, ProductOrderQuery>({
      query: ({ id, ...rest }) => {
        return {
          url: `/events/${id}`,
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["ProductOrders"],
    }),
    getEventProductOrderVariants: builder.query<
      ProductOrderVariants,
      ProductOrderQuery
    >({
      query: ({ id, ...rest }) => {
        return {
          url: `/product-order-variant/${id}`,
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["ProductOrders"],
    }),
  }),
});

export const {
  useGetProductOrdersQuery,
  useGetProductOrderQuery,
  useGetEventProductOrdersQuery,
  useGetEventProductOrderVariantsQuery,
} = productOrderApi;
