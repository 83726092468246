import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CreateSocialMedia, SocialMedia } from "../models/social-media.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

export const socialMediaApi = createApi({
  reducerPath: "socialMediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/social-media",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["SocialMedias", "SocialMedia"],
  endpoints: (builder) => ({
    createSocialMedia: builder.mutation<SocialMedia, CreateSocialMedia>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SocialMedias", "SocialMedia"],
    }),
    getSocialMedia: builder.query<SocialMedia, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["SocialMedias"],
    }),
    getSocialMediaByOrganization: builder.query<SocialMedia[], string>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
      }),
      providesTags: ["SocialMedias", "SocialMedia"],
    }),
    deleteSocialMediaById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SocialMedias", "SocialMedia"],
    }),
  }),
});

export const {
  useCreateSocialMediaMutation,
  useGetSocialMediaQuery,
  useGetSocialMediaByOrganizationQuery,
  useDeleteSocialMediaByIdMutation,
} = socialMediaApi;
