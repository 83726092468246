import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Label } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Performer } from "../../../../models/performer.model";

import PerformerSelect from "../../../Performer/PerformerSelect";
import { Option } from "../../../../types/general-types";
import {
  useCreateGroupPerformerMutation,
  useDeleteGroupPerformerMutation,
  CustomError,
} from "../../../../services/group.api";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  performers: Performer[];
  groupId: string;
}

interface FormValues {
  performerId: string;
  groupId: string;
}

export const GroupPerformer: React.FC<ComponentProps> = ({
  performers,
  groupId,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [groupPerformer, setGroupPerformer] = React.useState<Performer>();
  const [createGroupPerformer, { isError, error, isLoading, isSuccess }] =
    useCreateGroupPerformerMutation();

  const [
    deleteGroupPerformer,
    {
      isError: deleteEventGroupIsError,
      error: deleteEventGroupError,
      isLoading: deleteEventGroupIsLoading,
    },
  ] = useDeleteGroupPerformerMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    groupId: groupId,
    performerId: "",
  };

  const validationSchema = Yup.object().shape({
    groupId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    createGroupPerformer({
      groupId: values.groupId,
      performerId: values.performerId,
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <div className="mb-2 lg:mb-4">
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Group Performer</h2>
          <Button title="Add Performer" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {performers.map((performer: Performer, index: number) => (
              <ul className="grid justify-end grid-cols-3 mb-2" key={index}>
                <li>{performer.name}</li>
                <li>{performer.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setGroupPerformer(performer);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Group Performer" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="groups">Performer</Label>
                  <PerformerSelect />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {groupPerformer && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Group Performer`}
          size="s"
          action={deleteGroupPerformer}
          item={{
            groupId,
            performerId: groupPerformer.id,
          }}
        />
      )}
    </div>
  );
};
