import React, { useRef } from "react";
import { Image } from "../../../../models/image.model";
import {
  useRemoveGroupBannerMutation,
  useUploadGroupBannerMutation,
} from "../../../../services/group.api";

interface ComponentProps {
  banner?: Image;
  groupId: string;
}
export const GroupBanner: React.FC<ComponentProps> = ({ banner, groupId }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadGroupBannerMutation();
  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveGroupBannerMutation();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: groupId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(groupId);
  };
  return (
    <div>
      <h2>Banner</h2>
      <div className="flex items-center">
        <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
          {banner ? (
            <img
              alt={`${groupId}_banner`}
              src={process.env.REACT_APP_IMAGE_BASE_URL + banner?.fileName}
            />
          ) : (
            <div className="bg-black " />
          )}
        </div>
        <div className="grid grid-cols-3 gap-1">
          <input
            name="upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={ref}
          />
          <button
            className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
            type="button"
            onClick={() => handleBrowse()}
          >
            Update Photo
          </button>
          <button
            className="px-4 py-2 text-white bg-red-500 border rounded"
            type="button"
            onClick={() => handlePhotoRemove()}
          >
            Remove Photo
          </button>
          <p className="col-span-2 text-xs">
            Image should be at least 400 x 400px as a png or jpeg file.
          </p>
        </div>
      </div>
    </div>
  );
};
