import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import { AddConversationParticipants, Conversation, ConversationParticipant, CreateConversation } from "../models/conversation.model";

export interface CustomError {
  data: {
    message: [string];
  };
}
interface Conversations {
  data: Conversation[];
  skip: number;
  take: number;
  count: number;
}

interface ConversationParticipants {
  data: ConversationParticipant[];
  skip: number;
  take: number;
  count: number;
}
interface ConversationsQuery {
  skip?: number;
  take?: number;
  search?: string;
}
interface ParticipantsQuery {
  skip?: number;
  take?: number;
  search?: string;
  conversationId: string;
}

interface ConversationQuery {
  id: string;
}


export const conversationApi = createApi({
  reducerPath: "conversationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/conversations",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: [ "Conversations", "Conversation", "Participants"],
  endpoints: (builder) => ({
    createConversation: builder.mutation<Conversation, CreateConversation>({
      query: ({ eventTicketId, ...body }) => {
        console.log("body",body)
        return {
          url: `/`,
          method: "POST",
          body,
        }
      },
    }),
    addConversationParticipants: builder.mutation<ConversationParticipant, AddConversationParticipants>({
      query: ({ conversationId, ...body }) => ({
        url: `/${conversationId}/participants`,
        method: "POST",
        body,
      }),
    }),
    getConversations: builder.query<Conversations, ConversationsQuery>({
      query: ({ take, skip, search, }) => {
        return {
          url: `/`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Conversations"],
    }),
    getConversationParticipants: builder.query<ConversationParticipants, ParticipantsQuery>({
      query: ({ take, skip, search, conversationId}) => {
        return {
          url: `/${conversationId}/participants`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Participants"],
    }),
    getConversation: builder.query<Conversation, ConversationQuery>({
      query: ({ id }) => {
        return {
          url: `/${id}`,
        };
      },
      providesTags: ["Conversation"],
    })
  }),
});

export const {
  useGetConversationsQuery,
  useCreateConversationMutation,
  useGetConversationQuery,
  useGetConversationParticipantsQuery,
  useAddConversationParticipantsMutation,
} = conversationApi;
