import dayjs from "dayjs";
import React from "react";
import { Edit, Send, Tool, XCircle, Copy, Download, Eye } from "react-feather";
import { Modal } from "../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../components/common";
import ActionDropdown from "../../../components/common/ActionDropdown";
import { Order, OrderEventTicket } from "../../../models/order.model";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import {
  useDeleteOrderMutation,
  useDownloadOrderInvoicePDFMutation,
  useGetOrdersQuery,
  useSendOrderEmailMarketingMutation,
} from "../../../services/order.api";
import { SubHeader } from "../SubHeader";
import UpdateOrder from "../UpdateOrder";
import SendEmailMaketingModal from "../SendEmailMaketingModal";
import DynamicTable, { TableData } from "../../../components/common/Table";
import { formatCurrency } from "../../../helpers/general";
import { HiFilter } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

interface ComponentProps {
  search?: string;
}
interface Filter {
  [key: string]: any;
}

const OrderList: React.FC<ComponentProps> = ({ search }) => {
  const [filter, setFilter] = React.useState<Filter>({});
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [sendEmailId, setSendEmailId] = React.useState<string>("");
  const [eventId, setEventId] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  const [order, setOrder] = React.useState<Order | "">();
  const { data, isLoading } = useGetOrdersQuery({
    skip,
    take,
    search: search || "",
    ...filter,
  });
  const [deleteOrder] = useDeleteOrderMutation();
  const navigate = useNavigate();

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  const [
    downloadOrderInvoicePDF,
    { isLoading: downloadIsLoading, isSuccess: downloadIsSuccess },
  ] = useDownloadOrderInvoicePDFMutation();

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  const completedStatus = ["COMPLETED", "CAPTURED", "SUCCEEDED"];

  const columns = [
    {
      key: "isExpiredEmailSent",
      label: "Expired Orders",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "orderNumber",
      label: "Order#",
    },
    {
      key: "orderDate",
      label: "Order-Date",
    },
    {
      key: "payment",
      label: "Payment",
    },
    {
      key: "exchange",
      label: "Exchange",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "event",
      label: "Event",
    },
    {
      key: "quantity",
      label: "Quantity",
    },
    {
      key: "totalPerTicketFee",
      label: "Total Ticket Fee",
    },
    {
      key: "orderFee",
      label: "Order Fee",
    },
    {
      key: "total",
      label: "Total",
    },
  ];

  if (isLoading) return <h2>Loading...</h2>;
  const tableData: TableData[] =
    data?.data.map((order: Order) => ({
      id: order.id || "",
      orderNumber: order.orderNumber || "",
      orderDate: dayjs(order.createdAt).format("MM-DD-YY hh:mma") || "",
      exchange: order.exchange || "",
      email: order.user.email || "",
      event: (
        <div className="flex flex-col w-40">
          <span className="text-sm font-semibold md:text-md">
            {order.orderEventTickets.length > 0
              ? order.orderEventTickets[0].eventTicket.event?.name
              : ""}
          </span>
          <span className="text-sm">
            {order.orderEventTickets.length > 0
              ? order.orderEventTickets[0].eventTicket.event?.venue?.name
              : ""}
          </span>
          <span className="text-xs">
            {order.orderEventTickets.length > 0
              ? dayjs(
                  order.orderEventTickets[0].eventTicket.event?.dateTime
                ).format("MM-DD-YYYY hh:mm a")
              : ""}
          </span>
        </div>
      ),
      quantity:
        order.orderEventTickets.reduce((prev, curr) => {
          return prev + curr.quantity;
        }, 0) || 0,
      totalPerTicketFee: order.totalPerTicketFee
        ? formatCurrency(order.totalPerTicketFee)
        : 0,
      orderFee: order.orderFee ? formatCurrency(order.orderFee) : 0,
      total: order.total ? formatCurrency(order.total) : 0,
      status: order.status ? (
        <div className="p-1 text-sm text-gray-700 whitespace-nowrap ">
          {completedStatus.includes(order.status) ? (
            <span className="px-4 py-2 bg-green-400 rounded">
              {order.status}
            </span>
          ) : (
            <span className="px-4 py-2 bg-red-400 rounded">{order.status}</span>
          )}
        </div>
      ) : (
        ""
      ),
      payment: order.payments.length > 0 ? order.payments[0].provider : "N/A",
      isExpiredEmailSent: (
        <div className="p-1 text-sm text-gray-700 whitespace-nowrap ">
          {order.status === "EXPIRED" ? (
            order.isExpiredEmailSent ? (
              <span className="px-4 py-2 bg-green-400 rounded">SENT</span>
            ) : (
              <span className="px-4 py-2 bg-red-400 rounded">NOT SENT</span>
            )
          ) : (
            <span className="px-4 py-2 bg-blue-400 rounded">N/A</span>
          )}
        </div>
      ),
    })) || [];

  const getOptions = (order: Order) => {
    const optionsArr = [
      {
        label: "Copy ID",
        onClick: () => {
          navigator.clipboard.writeText(order.id);
        },
        icon: <Copy className="w-5 h-5" />,
      },
      {
        label: "Download",
        onClick: () => {
          downloadOrderInvoicePDF({
            orderId: order.id,
            orderNumber: order.orderNumber,
          });
        },
        icon: <Download className="w-5 h-5" />,
      },

      {
        label: "View",
        onClick: () => {
          navigate(`/order/${order.id}/view`);
        },
        icon: <Eye className="w-5 h-5" />,
      },
      {
        label: "Edit",
        onClick: () => {
          setOpen(true);
          setOrder(order);
        },
        icon: <Edit className="w-5 h-5" />,
      },
      {
        label: "Delete",
        onClick: () => {
          setOpenDelete(true);
          setOrder(order);
        },
        icon: <XCircle className="w-5 h-5" />,
      },
    ];

    if (order.status === "EXPIRED") {
      optionsArr.push({
        label: "Email",
        onClick: () => {
          setSendEmail(true);
          setSendEmailId(order.id);
          order.orderEventTickets.length > 0 &&
            order.orderEventTickets[0].eventTicket.event &&
            setEventId(order.orderEventTickets[0]?.eventTicket?.event.id);
        },
        icon: <Send className="w-5 h-5" />,
      });
    }

    return optionsArr;
  };

  return (
    <div className="">
      <div className="flex flex-col p-2 md:flex-row md:justify-between">
        <div className="flex flex-wrap gap-1 mb-2 lg:flex-row md:mb-0">
          {Object.values(filter).length > 0 &&
            Object.values(filter).map((value: any, index) => {
              return (
                <div
                  className="flex justify-between px-2 py-1 text-white bg-blue-500 rounded-md"
                  key={index}
                >
                  <div>
                    <span className="mr-1 font-semibold capitalize">
                      {Object.keys(filter)[index]}:
                    </span>
                    {`${value}`}
                  </div>
                  <button
                    className="w-6 h-6 ml-2 bg-red-400 rounded-full hover:cursor-pointer"
                    onClick={() => {
                      const tempFilter = filter;
                      delete tempFilter[Object.keys(filter)[index]];
                      setFilter({ ...tempFilter });
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
        </div>
        <button
          onClick={() => setOpenFilter(true)}
          className="flex items-center px-2 py-1 overflow-hidden text-white bg-blue-500 rounded-md"
        >
          <HiFilter /> <span className="ml-2">Filter</span>
        </button>
      </div>
      <DynamicTable
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={take}
        total={data?.count || 0}
        columns={columns}
        data={tableData}
        actionComponent={({ id }) => {
          const order = data?.data.find((order) => order.id === id);
          if (!order) return null;
          return (
            <div className="p-2 text-sm text-gray-700 break-words">
              <ActionDropdown
                placeholder="Actions"
                icon={<Tool className="w-5 h-5" />}
                options={getOptions(order)}
              />
            </div>
          );
        }}
      />
      {order && open && (
        <Modal open={open} setOpen={setOpen} title="Update Order" size="m">
          <UpdateOrder setOpen={setOpen} orderId={order.id} />
        </Modal>
      )}
      {openDelete && order && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Order`}
          size="s"
          action={deleteOrder}
          setItem={setOrder}
          item={order.id}
        />
      )}
      <Modal
        open={sendEmail}
        setOpen={setSendEmail}
        title="Confirm Resend"
        size="xs"
      >
        <SendEmailMaketingModal
          eventId={eventId}
          setOpen={setSendEmail}
          sendEmailId={sendEmailId}
          setSendEmailId={setSendEmailId}
        />
      </Modal>
      <Modal open={openFilter} setOpen={setOpenFilter} title="Order Filter">
        <OrderFilter
          filter={filter}
          onClose={() => setOpenFilter(false)}
          setFilter={setFilter}
        />
      </Modal>
    </div>
  );
};

export default OrderList;

interface FilterProps {
  setFilter: React.Dispatch<React.SetStateAction<{}>>;
  onClose: () => void;
  filter: any;
}

const OrderFilter: React.FC<FilterProps> = ({ setFilter, onClose, filter }) => {
  const [exchange, setExchange] = React.useState(filter.exchange || "");
  const [status, setStatus] = React.useState(filter.status || "");
  const [from, setFrom] = React.useState(filter.from || "");
  const [to, setTo] = React.useState(filter.to || "");
  const handleSubmit = () => {
    const filter: any = {};
    if (exchange) filter.exchange = exchange;
    if (status) filter.status = status;
    if (from) filter.from = from;
    if (to) filter.to = to;
    setFilter(filter);
    onClose();
  };
  return (
    <div className="bg-gray-500">
      <table className="w-full">
        <tbody>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">Exchange</label>
            </td>
            <td className="w-[99%] p-2">
              <select
                className="w-full px-3 py-2 rounded-md"
                value={exchange}
                onChange={(e) => setExchange(e.target.value)}
              >
                <option value="">All</option>
                <option value="ticketnation">Ticketnation</option>
                <option value="shoppee">Shopee</option>
                <option value="tiktok">Tiktok</option>
                <option value="Lazada">Lazada</option>
              </select>
            </td>
          </tr>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">Status</label>
            </td>
            <td className="w-[99%] p-2">
              <select
                className="w-full px-3 py-2 rounded-md"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="PENDING">PENDING</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="EXPIRED">EXPIRED</option>
                <option value="FAILED">FAILED</option>
                <option value="CUSTOM">CUSTOM</option>
              </select>
            </td>
          </tr>
          <tr className="">
            <td className="flex p-2">
              <label className="mr-2 text-white">From</label>
            </td>
            <td className="w-[99%] p-2">
              <input
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                className="w-full px-3 py-2 rounded-md"
                type="date"
                max={new Date().toISOString().split("T")[0]}
              />
            </td>
          </tr>
          <tr>
            <td className="flex p-2">
              <label className="mr-2 text-white">To</label>
            </td>
            <td className="w-[99%] p-2">
              <input
                value={to}
                onChange={(e) => setTo(e.target.value)}
                className="w-full px-3 py-2 rounded-md"
                type="date"
                max={new Date().toISOString().split("T")[0]}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        onClick={() => handleSubmit()}
        className="w-full col-span-2 px-3 py-2 text-white bg-blue-500 rounded-md"
      >
        Filter
      </button>
    </div>
  );
};
