import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../components/common";

import {
  CustomError,
  useGetGroupQuery,
  useRemoveGroupPhotoMutation,
  useUpdateGroupMutation,
  useUploadGroupImageMutation,
} from "../../../services/group.api";
import * as Yup from "yup";
import { GroupGenre } from "./GroupGenre/GroupGenre";
import { GroupSong } from "./GroupSong/GroupSong";
import { GroupPerformer } from "./GroupPerformer/GroupPerformer";
import { GroupBanner } from "./GroupBanner";

interface FormValues {
  name: string;
  description: string;
  isPartner: boolean;
}

interface ComponentProps {
  groupId: string;
}

const UpdateGroup: React.FC<ComponentProps> = ({ groupId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateGroupMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadGroupImageMutation();

  const { data: group, isLoading: groupIsLoading } = useGetGroupQuery(groupId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveGroupPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (group)
      update({
        id: group.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: groupId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(groupId);
  };

  if (isLoading || groupIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!group) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: group?.name || "",
    description: group?.description || "",
    isPartner: group?.isPartner || false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    isPartner: Yup.boolean().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {group && group.image ? (
                    <img
                      alt={`${group.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        group.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>

              <GroupBanner banner={group.banner} groupId={group.id} />
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="isPartner">Is Partner</Label>
                <Field name="isPartner">
                  {({ field }: FieldProps) => (
                    <Checkbox
                      className="col-span-1"
                      id="isPartner"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <GroupGenre groupId={group.id} genres={group.genres} />
      <GroupSong groupId={group.id} songs={group.songs} />
      <GroupPerformer groupId={group.id} performers={group.performers} />
    </>
  );
};

export default UpdateGroup;
