import React from "react";
import { useParams } from "react-router-dom";
import ViewPerformer from "../../features/Performer/ViewPerformer";

type ViewPerformerParams = {
  id: string;
};

export const ViewPerformerPage: React.FC = () => {
  const { id } = useParams<ViewPerformerParams>() as ViewPerformerParams;

  return <ViewPerformer id={id} />;
};
