import React from "react";
import ActionDropdown from "../../../../components/common/ActionDropdown";
import { Bell, CreditCard, Layout, List, Send } from "react-feather";

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTemplate: React.Dispatch<React.SetStateAction<string>>;
  template: string;
}

const SelectTemplate: React.FC<ComponentProps> = ({
  setOpen,
  setTemplate,
  template,
}) => {
  return (
    <>
      <ActionDropdown
        placeholder={`Select Template: ${template}`}
        icon={<Layout className="w-5 h-5" />}
        options={[
          {
            label: "Pre-sale",
            onClick: () => {
              setOpen(false);
              setTemplate("Pre-sale");
            },
            icon: <CreditCard className="w-5 h-5" />,
          },
          {
            label: "Reminder",
            onClick: () => {
              setOpen(false);
              setTemplate("Reminder");
            },
            icon: <Bell className="w-5 h-5" />,
          },
          {
            label: "Promotion",
            onClick: () => {
              setOpen(false);
              setTemplate("Promotion");
            },
            icon: <Bell className="w-5 h-5" />,
          },
          {
            label: "Upcoming Events",
            onClick: () => {
              setOpen(false);
              setTemplate("Upcoming Events");
            },
            icon: <List className="w-5 h-5" />,
          },
          {
            label: "Announcement",
            onClick: () => {
              setOpen(false);
              setTemplate("Announcement");
            },
            icon: <Send className="w-5 h-5" />,
          },
        ]}
      />
    </>
  );
};

export default SelectTemplate;
