import { ErrorMessage, Field } from "formik";
import React from "react";
import { useGetCarouselsQuery } from "../../../../services";

interface ComponentProps {
  name: string;
  className?: string;
  excludeIds?: string[];
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const SelectCarousel: React.FC<TExtendedProps> = ({
  className,
  excludeIds,
  name,
  ...props
}) => {
  const { data: carousel } = useGetCarouselsQuery({});

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`py-2 border rounded `}
        as="select"
        name={name}
      >
        <option hidden>-- Select Image --</option>
        {carousel &&
          carousel.data
            .filter((data) => !excludeIds?.includes(data.id))
            .filter((data) => data.title !== " ")
            .map((carousel, index) => (
              <option key={index} value={carousel.image?.fileName}>
                {carousel.title}
              </option>
            ))}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default SelectCarousel;
