import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { DatePicker, Input, Label } from "../../../components/common";

import {
  CustomError,
  useGetEventGroupQuery,
  useRemoveEventGroupPhotoMutation,
  useUpdateEventGroupMutation,
  useUploadEventGroupImageMutation,
} from "../../../services/event-group.api";
import * as Yup from "yup";

interface FormValues {
  name: string;
  slug: string;
  start: Date;
  end: Date;
  description: string;
  isActive: boolean;
}

interface ComponentProps {
  eventGroupId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateEventGroup: React.FC<ComponentProps> = ({
  setOpen,
  eventGroupId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateEventGroupMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadEventGroupImageMutation();

  const { data: eventGroup, isLoading: eventGroupIsLoading } =
    useGetEventGroupQuery(eventGroupId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveEventGroupPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (eventGroup)
      update({
        id: eventGroup.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: eventGroupId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(eventGroupId);
  };

  if (
    isLoading ||
    eventGroupIsLoading ||
    uploadIsLoading ||
    removePhotoIsLoading
  )
    return <h2>Loading...</h2>;
  if (!eventGroup) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: eventGroup?.name || "",
    slug: eventGroup?.slug || "",
    description: eventGroup?.description || "",
    isActive: eventGroup?.isActive || false,
    start: new Date(eventGroup.start),
    end: new Date(eventGroup.end),
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {eventGroup && eventGroup.image ? (
                    <img
                      alt={`${eventGroup.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        eventGroup.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2 mb-5">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="slug">Slug</Label>
                <Field name="slug">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Slug" id="slug" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="venue">Start</Label>
                <DatePicker name="start" />
                <Label htmlFor="venue">End</Label>
                <DatePicker name="end" />
                <Label htmlFor="active">Active</Label>
                <Field name="isActive">
                  {({ field, form }: FieldProps) => (
                    <button
                      type="button"
                      className={`px-4 py-2 font-bold text-white 
                       border rounded ${
                         field.value ? "bg-green-400" : "bg-red-400"
                       }`}
                      onClick={() =>
                        form.setFieldValue(field.name, !field.value)
                      }
                    >
                      {field.value ? "Active" : "Inactive"}
                    </button>
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
