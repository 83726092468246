import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { EventVoucher } from "../models/event.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

export const eventVoucherApi = createApi({
  reducerPath: "eventVoucherApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/event-voucher",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["EventVoucher", "EventVouchers"],
  endpoints: (builder) => ({
    getEventVoucherByEvent: builder.query<EventVoucher[], string>({
      query: (eventId) => {
        return {
          url: `/${eventId}`,
        };
      },
      providesTags: ["EventVoucher"],
    }),
  }),
});

export const { useGetEventVoucherByEventQuery } = eventVoucherApi;
