import React from "react";
import { Permission } from "../../../models/permission.model";
import { Role, RolePermission } from "../../../models/role.model";
import { useGetPermissionsQuery } from "../../../services/permission.api";
import { useAddPermissionToRoleMutation, useRemovePermissionToRoleMutation } from "../../../services/role.api";

interface ComponentProps {
  role: Role;
}

export const RolePermissionUpdate: React.FC<ComponentProps> = ({ role }) => {
  const { data: permissions, isLoading: getPermissionsLoading } =
    useGetPermissionsQuery({});
  const [addPermission, { isLoading, isError, error, isSuccess }] =
    useAddPermissionToRoleMutation();
  const [removePermission, { isLoading: removePermissionLoading }] =
    useRemovePermissionToRoleMutation();
  if (getPermissionsLoading || isLoading) return <span>Loading...</span>;
  return (
    <div>
      <h1>Role Permissions</h1>
      <div>
        {permissions?.data.map((permission: Permission) => {
          return (
            <div className="flex items-center justify-between">
              {permission.name}
              {role.rolePermissions.filter(
                (rolePermission: RolePermission) =>
                  rolePermission.permissionId === permission.id
              ).length > 0 ? (
                <button
                  className="w-24 px-2 py-1 bg-red-400 border rounded"
                  onClick={() =>
                    removePermission({
                      permissionId: permission.id,
                      roleId: role.id,
                    })
                  }
                >
                  Remove
                </button>
              ) : (
                <button
                  className="w-24 px-2 py-1 bg-green-400 border rounded"
                  onClick={() =>
                    addPermission({
                      permissionId: permission.id,
                      roleId: role.id,
                    })
                  }
                >
                  Add
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
