import React from "react";
import { useParams } from "react-router-dom";
import EventList from "../../../features/Event/EventList";
import EventTickets from "../../../features/Event/EventTicketList";

type EventTicketParams = {
  eventId: string;
};

export const EventTicketPage = () => {
  const { eventId } = useParams<EventTicketParams>() as EventTicketParams;

  const [search, setSearch] = React.useState("");
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };

  return (
    <>
      <div className="flex items-center justify-between p-6 mb-6 border rounded shadow-sm align">
        <h2 className="text-2xl font-semibold ">Event Tickets</h2>
        <div className="flex items-center flex-1 px-6 ">
          <input
            className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
      <EventTickets search={search} eventId={eventId} />
    </>
  );
};
