import { CreateVenue, UpdateVenue, Venue } from "../models/venue.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Venues {
  data: Venue[];
  skip: number;
  take: number;
  count: number;
}

interface VenuesQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const venueApi = createApi({
  reducerPath: "venueApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/venue",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Venues", "Venue"],
  endpoints: (builder) => ({
    createVenue: builder.mutation<Venue, CreateVenue>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Venues"],
    }),
    getVenues: builder.query<Venues, VenuesQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Venues"],
    }),
    getVenue: builder.query<Venue, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Venue"],
    }),
    updateVenue: builder.mutation<Venue, UpdateVenue>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Venues"],
    }),
    uploadVenueImage: builder.mutation<Venue, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Venues", "Venue"],
    }),
    deleteVenue: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Venues"],
    }),
    removeVenuePhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Venues", "Venue"],
    }),
  }),
});

export const {
  useCreateVenueMutation,
  useGetVenueQuery,
  useGetVenuesQuery,
  useUpdateVenueMutation,
  useUploadVenueImageMutation,
  useDeleteVenueMutation,
  useRemoveVenuePhotoMutation,
} = venueApi;
