import React from "react";
import { PaginationContainer, TabModal } from "../../../components/common";
import { Organization } from "../../../models/organization.model";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
} from "../../../services/organization.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import UpdateOrganization from "../UpdateOrganization";
import { Link } from "react-router-dom";
import UpdateSocialMedia from "../SocialMedia";

interface ComponentProps {
  search?: string;
}
const OrganizationList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [active, setActive] = React.useState("Update Organization");
  const [take] = React.useState(5);
  const [organization, setOrganization] = React.useState<Organization>();
  const { data, isLoading } = useGetOrganizationsQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteOrganization] = useDeleteOrganizationMutation();
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Status</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((organization: Organization, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center gap-1">
                    <li>
                      <button
                        className="px-2 py-1 border rounded-md"
                        onClick={() =>
                          navigator.clipboard.writeText(organization.id || "")
                        }
                      >
                        COPY ID
                      </button>
                    </li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {organization && organization.image ? (
                          <img
                            alt={`${organization.name}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              organization.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{organization.name}</li>
                <li className="text-white">
                  {organization.isActive ? (
                    <span className="px-4 py-2 bg-green-400 rounded">
                      Active
                    </span>
                  ) : (
                    <span className="px-4 py-2 bg-red-400 rounded">
                      Not Active
                    </span>
                  )}
                </li>
                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <Link
                    to={`${organization.id}/view`}
                    className="px-4 py-2 bg-green-400 rounded"
                  >
                    View
                  </Link>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setOrganization(organization);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setOrganization(organization);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {organization && (
        <TabModal
          open={open}
          setOpen={setOpen}
          active={active}
          setActive={setActive}
          titles={["Update Organization", "Social Media"]}
          size="m"
        >
          {active === "Update Organization" ? (
            <UpdateOrganization
              setOpen={setOpen}
              organizationId={organization.id}
            />
          ) : (
            <UpdateSocialMedia
              setOpen={setOpen}
              organizationId={organization.id}
            />
          )}
        </TabModal>
      )}
      {organization && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Organization`}
          size="s"
          action={deleteOrganization}
          item={organization.id}
        />
      )}
    </div>
  );
};

export default OrganizationList;
