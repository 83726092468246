import React from "react";
import { useNavigate } from "react-router-dom";
import { Eye, Tool, XCircle, Edit, Archive, Copy } from "react-feather";
import { PaginationContainer } from "../../../components/common";
import ActionDropdown from "../../../components/common/ActionDropdown";
import { formatDateTime } from "../../../helpers/general";
import { Event } from "../../../models/event.model";
import {
  useDeleteEventMutation,
  useGetEventsQuery,
} from "../../../services/event.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  search?: string;
  activeFilter?: boolean | undefined;
}
const EventList: React.FC<ComponentProps> = ({ search, activeFilter }) => {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  const [event, setEvent] = React.useState<Event>();
  const { data, isLoading } = useGetEventsQuery({
    isActive: activeFilter,
    skip,
    take,
    search: search || "",
  });
  const [deleteEvent] = useDeleteEventMutation();
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div className="text-sm">
        <ul className="grid grid-cols-10 p-4 font-semibold">
          <li>Action</li>
          <li>Photo</li>
          <li className="col-span-2">Name</li>
          <li className="col-span-2">Slug</li>
          <li>Date and Time</li>
          <li>Start</li>
          <li>End</li>
          <li>Status</li>
        </ul>
      </div>
      {data && data?.data && (
        <PaginationContainer
          label="Events"
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1 text-sm">
            {data?.data.map((event: Event, index: number) => {
              return (
                <div
                  key={index}
                  className={`bg-opacity-50 ${
                    (index + 1) % 2 == 0 ? "bg-blue-100" : "bg-white"
                  }`}
                >
                  <ul
                    className={`grid gap-1 items-center grid-cols-10 p-4 border rounded shadow-sm hover:shadow-md hover:bg-blue-200`}
                    key={index}
                  >
                    <li className="grid gap-1">
                      <ActionDropdown
                        placeholder="Actions"
                        icon={<Tool className="w-5 h-5" />}
                        options={[
                          {
                            label: "Copy ID",
                            onClick: () => {
                              navigator.clipboard.writeText(event.id);
                            },
                            icon: <Copy className="w-5 h-5" />,
                          },
                          {
                            label: "View",
                            onClick: () => {
                              navigate(`/event/${event.id}`);
                            },
                            icon: <Eye className="w-5 h-5" />,
                          },
                          {
                            label: "View Event Tickets",
                            onClick: () => {
                              navigate(`/event/${event.id}/ticket`);
                            },
                            icon: <Archive className="w-5 h-5" />,
                          },
                          {
                            label: "Edit",
                            onClick: () => {
                              navigate(`/event/${event.id}/edit`);
                            },
                            icon: <Edit className="w-5 h-5" />,
                          },
                          {
                            label: "Delete",
                            onClick: () => {
                              setOpenDelete(true);
                              setEvent(event);
                            },
                            icon: <XCircle className="w-5 h-5" />,
                          },
                        ]}
                      />
                    </li>
                    <li>
                      <ul className="flex items-center">
                        <li>
                          <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                            {event && event.image ? (
                              <img
                                alt={`${event.name}_image`}
                                src={
                                  process.env.REACT_APP_IMAGE_BASE_URL +
                                  event.image?.fileName
                                }
                              />
                            ) : (
                              <div className="bg-black " />
                            )}
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li className="col-span-2 break-words">{event.name}</li>
                    <li className="col-span-2 break-words">{event.slug}</li>
                    <li>{formatDateTime(event.dateTime)}</li>
                    <li>{formatDateTime(event.start)}</li>
                    <li>{formatDateTime(event.end)}</li>
                    <li>
                      {event.isActive ? (
                        <span className="px-2 py-1 text-white bg-green-500 rounded-md">
                          Active
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-white bg-red-500 rounded-md">
                          Inactive
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </PaginationContainer>
      )}
      {event && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event`}
          size="s"
          action={deleteEvent}
          item={event.id}
        />
      )}
    </div>
  );
};

export default EventList;
