import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label, Checkbox } from "../../../components/common";

import { CustomError, useCreateUserMutation } from "../../../services/user.api";
import * as Yup from "yup";
import OrganizationSelect from "../../Organization/OrganizationSelect";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationId: string;
  mobileNumber: string;
  isOrgOwner: boolean;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateUser: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateUserMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    organizationId: "",
    mobileNumber: "",
    isOrgOwner: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().required(),
    isOrgOwner: Yup.string().required(),
    mobileNumber: Yup.string().required(),
    // organizationId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create({
      ...values,
      organizationId:values.organizationId,
    });
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-3 gap-2">
              <Label htmlFor="firstName">First Name</Label>
              <Field name="firstName">
                {({ field }: FieldProps) => (
                  <Input
                    className="col-span-2"
                    placeholder="First Name"
                    id="firstName"
                    {...field}
                  />
                )}
              </Field>

              <Label htmlFor="lastName">Last Name</Label>
              <Field name="lastName">
                {({ field }: FieldProps) => (
                  <Input
                    className="col-span-2"
                    placeholder="Last Name"
                    id="lastName"
                    {...field}
                  />
                )}
              </Field>

              <Label htmlFor="email">Email</Label>
              <Field name="email">
                {({ field }: FieldProps) => (
                  <Input
                    className="col-span-2"
                    placeholder="Email"
                    id="email"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="mobileNumber">Mobile Number</Label>
              <Field name="mobileNumber">
                {({ field }: FieldProps) => (
                  <Input
                    className="col-span-2"
                    placeholder="Mobiel Number"
                    id="mobileNumber"
                    {...field}
                  />
                )}
              </Field>

              <Label htmlFor="password">Password</Label>
              <Field name="password">
                {({ field }: FieldProps) => (
                  <Input
                    className="col-span-2"
                    placeholder="Password"
                    id="password"
                    {...field}
                    type="password"
                  />
                )}
              </Field>
              <Label htmlFor="isOrgOwner">Organization</Label>
              <OrganizationSelect className="col-span-2" />
              <Label htmlFor="isOrgOwner">Is Organization Owner</Label>
              <Field name="isOrgOwner">
                {({ field }: FieldProps) => (
                  <Checkbox className="col-span-2" id="isOrgOwner" {...field} />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateUser;
