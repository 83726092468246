import React from 'react'
import { Modal } from "../../components/common/Modals/Modal";
import { ProductList, CreateProduct } from "../../features/Product";

const Product = () => {
    const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };
  return (
    <div><div className="flex items-center justify-between p-6 mb-6 border rounded shadow-sm align">
    <h2 className="text-2xl font-semibold ">Product</h2>
    <div className="flex items-center flex-1 px-6 ">
      <input
        className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
      />
      <button
        className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
    <button
      className="w-24 px-4 py-2 text-blue-900 border border-blue-900 rounded"
      type="button"
      onClick={() => setOpen(true)}
    >
      Add
    </button>
  </div>
  <ProductList search={search} />
  <Modal open={open} setOpen={setOpen} title="Add Product" size="m">
    <CreateProduct setOpen={setOpen} />
  </Modal></div>
  )
}

export default Product