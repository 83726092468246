import React from "react";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "../../../components/common/Tabs";
import { useParams } from "react-router-dom";
import { UpdateProductForm } from "./UpdateProductForm";
import ProductVariant from "../ProductVariant/ProductVariant";

type UpdateProductParams = {
  id: string;
};

export const UpdateProduct = () => {
  const { id } = useParams<UpdateProductParams>() as UpdateProductParams;
  return (
    <Tabs>
      <div
        id="product-page"
        className="flex flex-grow h-full text-xs lg:flex-col lg:text-md"
      >
        <TabList className="flex flex-col items-start gap-4">
          <Tab>Product Details</Tab>
          <Tab>Variants</Tab>
        </TabList>
        <div className="flex-1 ">
          <TabPanels>
            <TabPanel>
              <UpdateProductForm productId={id} />
            </TabPanel>
            <TabPanel>
              <ProductVariant productId={id} />
            </TabPanel>
          </TabPanels>
        </div>
      </div>
    </Tabs>
  );
};

export default UpdateProduct;
