import React from "react";
import { Button } from "../../Button";
import { Modal } from "../Modal";
import "./ConfirmModal.css";
import {
  AnimatedLoading,
  AnimatedSuccess,
} from "../../../../features/Event/UpdateEvent/ResendEmailModal";
import { CustomError } from "../../../../services";
import { SerializedError } from "@reduxjs/toolkit";

interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  size: "xs" | "s" | "m" | "l";
  item: string | object | undefined;
  action: Function;
  setItem?: React.Dispatch<React.SetStateAction<any>>;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: CustomError | undefined | SerializedError;
}
export const ConfirmModal: React.FC<ComponentProps> = ({
  open,
  setOpen,
  title,
  size,
  item,
  action,
  setItem,
  isLoading,
  isSuccess,
  isError,
  error,
}) => {
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false);
        setItem && setItem("");
      }, 1500);
    }

    return () => {
      setItem && setItem("");
    };
  }, [isSuccess, setOpen, setItem]);

  return (
    <Modal open={open} setOpen={setOpen} title={title} size={size}>
      <div className="grid gap-6 justify-center p-8">
        {isLoading && !isSuccess && <AnimatedLoading />}
        {isSuccess && !isLoading && <AnimatedSuccess />}
        {isError && error && (
          <div className="text-red-500">
            <h3 className="font-semibold">Error(s)</h3>
            <ul>
              <li>{(error as CustomError).data.message}</li>
            </ul>
          </div>
        )}
        {!isSuccess && !isLoading && (
          <>
            <span> Are you sure you want to {title}? </span>
            <div className="flex justify-center gap-5">
              <Button
                title="Proceed"
                variant="secondary"
                onClick={() => {
                  action(item);
                }}
              />
              <Button
                title="Cancel"
                variant="warning"
                onClick={() => {
                  setOpen(false);
                  setItem && setItem("");
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
