import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../components/common";

import {
  CustomError,
  useGetPerformerQuery,
  useRemovePerformerPhotoMutation,
  useUpdatePerformerMutation,
  useUploadPerformerImageMutation,
} from "../../../services/performer.api";
import * as Yup from "yup";
import { PerformerGenre } from "../PerformerGenre/PerformerGenre";
import { PerformerSong } from "../PerformerSong/PerformerSong";
import { PerformerBanner } from "./PerformerBanner";

interface FormValues {
  name: string;
  slug: string;
  email: string;
  contact: string;
  facebook: string;
  youtube: string;
  instagram: string;
  first_name: string;
  last_name: string;
  birthday: string;
  description: string;
  isPartner: boolean;
}

interface ComponentProps {
  performerId: string;
}

const UpdatePerformer: React.FC<ComponentProps> = ({ performerId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdatePerformerMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadPerformerImageMutation();

  const { data: performer, isLoading: performerIsLoading } =
    useGetPerformerQuery(performerId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemovePerformerPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (performer)
      update({
        id: performer.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: performerId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(performerId);
  };

  if (
    isLoading ||
    performerIsLoading ||
    uploadIsLoading ||
    removePhotoIsLoading
  )
    return <h2>Loading...</h2>;
  if (!performer) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: performer?.name || "",
    slug: performer?.slug || "",
    description: performer?.description || "",
    email: performer?.email || "",
    contact: performer?.contact || "",
    facebook: performer?.facebook || "",
    youtube: performer?.youtube || "",
    instagram: performer?.instagram || "",
    first_name: performer?.first_name || "",
    last_name: performer?.last_name || "",
    birthday: performer?.birthday || "",
    isPartner: performer?.isPartner || false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className="grid gap-2 mb-2 lg:mb-4">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {performer && performer.image ? (
                    <img
                      alt={`${performer.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        performer.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>

              <PerformerBanner
                banner={performer.banner}
                performerId={performer.id}
              />

              <div className="grid grid-cols-1 gap-2 mb-2 lg:gap-4 lg:grid-cols-4 lg:mb-4">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="slug">Slug</Label>
                <Field name="slug">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Slug" id="slug" {...field} disabled />
                  )}
                </Field>
                <Label htmlFor="description">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
                {/* ____ */}
                <Label htmlFor="email">Email</Label>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Email" id="email" {...field} />
                  )}
                </Field>
                <Label htmlFor="contact">Contact</Label>
                <Field name="contact">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Contact" id="contact" {...field} />
                  )}
                </Field>
                <Label htmlFor="facebook">Facebook</Label>
                <Field name="facebook">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Facebook" id="facebook" {...field} />
                  )}
                </Field>
                <Label htmlFor="youtube">Youtube</Label>
                <Field name="youtube">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Youtube" id="youtube" {...field} />
                  )}
                </Field>
                <Label htmlFor="instagram">Instagram</Label>
                <Field name="instagram">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Instagram" id="instagram" {...field} />
                  )}
                </Field>
                <Label htmlFor="first_name">First Name</Label>
                <Field name="first_name">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="First Name"
                      id="first_name"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="last_name">Last Name</Label>
                <Field name="last_name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Last Name" id="last_name" {...field} />
                  )}
                </Field>
                <Label htmlFor="birthday">Birthday</Label>
                <Field name="birthday">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Birthday" id="birthday" {...field} />
                  )}
                </Field>
                {/* ----- */}
                <Label htmlFor="isPartner">Is Partner</Label>
                <Field name="isPartner">
                  {({ field }: FieldProps) => (
                    <Checkbox
                      className="col-span-1"
                      id="isPartner"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded shadow-sm"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <PerformerGenre genres={performer.genres} performerId={performer.id} />
      <PerformerSong songs={performer.songs} performerId={performer.id} />
    </>
  );
};

export default UpdatePerformer;
