import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { DatePicker, Input, Label } from "../../components/common";
import * as Yup from "yup";
import { CustomError } from "../../services/artist-inquiry.api";
import { ArtistInquiry } from "../../models/artist-inquiry.model";

interface ComponentProps {
  isError?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  error?: CustomError;
  onSubmit: (values: FormValues) => void;
  disabledInputs?: string[];
  buttonText?: string;
  artistInquiry?: ArtistInquiry;
}

interface FormValues {
  id?: string;
  name: string;
  email: string;
  contact: string;
  description?: string;
  targetDate?: string;
  eventType?: string;
  assignedToId?: string;
  performerId?: string;
  groupId?: string;
}

export const ArtistInquiryForm: React.FC<ComponentProps> = ({
  isError,
  isSuccess,
  isLoading,
  error,
  onSubmit,
  disabledInputs,
  buttonText = "Submit",
  artistInquiry,
}) => {
  const initialValues: FormValues = {
    id: artistInquiry?.id || "",
    name: artistInquiry?.name || "",
    email: artistInquiry?.email || "",
    contact: artistInquiry?.contact || "",
    description: artistInquiry?.description || "",
    targetDate: artistInquiry?.targetDate || "",
    eventType: artistInquiry?.eventType || "",
    assignedToId: artistInquiry?.assignedToId || "",
    performerId: artistInquiry?.performerId || "",
    groupId: artistInquiry?.groupId || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    onSubmit(values);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="email">Email</Label>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Email" id="email" {...field} />
                  )}
                </Field>
                <Label htmlFor="contact">Contact</Label>
                <Field name="contact">
                  {({ field }: FieldProps) => (
                    <Input placeholder="contact" id="contact" {...field} />
                  )}
                </Field>
                <Label htmlFor="description">description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="eventType">Event Type</Label>
                <Field name="eventType">
                  {({ field }: FieldProps) => (
                    <Input placeholder="eventType" id="eventType" {...field} />
                  )}
                </Field>
                <Label htmlFor="targetDate">Target Date</Label>
                <DatePicker name="targetDate" />
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
