import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useCreateEventTicketConversationMutation } from "../../../../services/event-ticket.api";

interface FormValues {
  name: string;
}

const initialValues: FormValues = {
  name: "",
};
interface CreateEventTicketConversationFormProps {
  eventTicketId: string;
}
const CreateEventTicketConversationForm: React.FC<CreateEventTicketConversationFormProps> = ({eventTicketId}) => {
  const [create, {data, isLoading}] = useCreateEventTicketConversationMutation()
  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.name) {
      errors.name = "Required";
    }
    return errors;
  };

  const handleSubmit = (values: FormValues) => {
    console.log({...values, eventTicketId})
    create({...values, eventTicketId})
  };

  if(isLoading) return <h2>Loading...</h2>
  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched }) => (
        <Form>
          <div className="mb-4">
            <label className="mb-2 font-bold text-gray-700" htmlFor="name">
              Name
            </label>
            <Field
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
              id="name"
              name="name"
              placeholder="Enter your name"
            />
            {errors.name && touched.name && (
              <div className="mt-1 text-red-500">{errors.name}</div>
            )}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEventTicketConversationForm;
