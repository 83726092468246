import React from "react";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";
import { CustomError } from "../../../services/user.api";
import { SocMedList } from "./SocMedList";
import {
  useCreateSocialMediaMutation,
  useGetSocialMediaByOrganizationQuery,
} from "../../../services/social-media.api";

interface FormValues {
  organizationId: string;
  name: string;
  url: string;
}
interface ComponentProps {
  organizationId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateSocialMedia: React.FC<ComponentProps> = ({
  setOpen,
  organizationId,
}) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateSocialMediaMutation();

  const { data, isLoading: socialMediaIsLoading } =
    useGetSocialMediaByOrganizationQuery(organizationId);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(true);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading || socialMediaIsLoading) return <h2>Loading...</h2>;
  const initialValues: FormValues = {
    organizationId,
    name: "",
    url: "",
  };
  const validationSchema = Yup.object().shape({
    organizationId: Yup.string().required(),
    name: Yup.string().required(),
    url: Yup.string().required(),
  });
  return (
    <>
      <div className="flex flex-col gap-6 justify-center">
        <p className="font-bold">Organization Social Media:</p>
        <div className="grid grid-cols-4 gap-3">
          {data?.map((socMed, index) => (
            <React.Fragment key={index}>
              <SocMedList socMed={socMed} />
            </React.Fragment>
          ))}
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-3 gap-2 items-center my-10">
                <Label htmlFor="name">Name</Label>
                <Field name="name" className="cols-span-2">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Name"
                      id="name"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="url">URL</Label>
                <Field name="url" className="cols-span-2">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="URL"
                      id="url"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add Social Media
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateSocialMedia;
