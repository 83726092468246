import React, { useState } from "react";

export interface ActionDropdownOptions {
  label: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  value?: any;
}

interface ComponentProps {
  options: ActionDropdownOptions[];
  placeholder?: string;
  icon?: React.ReactNode;
  onClick?: (value: any) => void;
  className?: string;
  defaultValue?: string;
}

const ActionDropdown: React.FC<ComponentProps> = ({
  options,
  placeholder,
  icon,
  onClick,
  className,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={`relative cursor-pointer ${className}`}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className="flex items-center w-full p-2 border border-blue-900 rounded-lg">
        {icon && <div className="mr-2">{icon}</div>}
        {placeholder ? placeholder : "Action Dropdown"}
      </div>
      <div
        className={`absolute top-[42px] left-0 right-0 bg-white shadow-md shadow-gray-400 rounded-md grid overflow-hidden transition-all duration-[0.7s] ease-linear z-30  ${
          show ? "max-h-[600px]" : "max-h-0"
        }`}
      >
        {options.length > 0 &&
          options.map((opt, index) => (
            <div
              key={index}
              className="flex items-center p-2 transition-all duration-200 hover:bg-gray-100 hover:text-blue-90"
              onClick={() => {
                if (onClick) {
                  onClick(opt);
                }
                if (opt.onClick) {
                  opt.onClick();
                }
              }}
            >
              {opt.icon && <div className="mr-2">{opt.icon}</div>}
              {opt.label}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ActionDropdown;
