import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label, Checkbox } from "../../../components/common";

import {
  CustomError,
  useGetOrderQuery,
  useUpdateOrderMutation,
} from "../../../services/order.api";

interface FormValues {
  status: string;
  orderFee: number;
  totalPerTicketFee: number;
  total: number;
  subTotal: number;
}
interface ComponentProps {
  orderId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateOrder: React.FC<ComponentProps> = ({ setOpen, orderId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateOrderMutation();

  const { data: order, isLoading: orderIsLoading } = useGetOrderQuery(orderId);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (order)
      update({
        id: order.id,
        ...values,
      });
  };

  if (isLoading || orderIsLoading) return <h2>Loading...</h2>;
  if (!order) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    status: order.status || "",
    orderFee: order.orderFee || 0,
    totalPerTicketFee: order.totalPerTicketFee || 0,
    total: order.total || 0,
    subTotal: order.subTotal || 0,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid gap-2 lg:grid-cols-2">
                <Label htmlFor="orderFee">Order Fee</Label>
                <Field name="orderFee">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Order Fee"
                      id="orderFee"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="totalPerTicketFee">Total Per Ticket Fee</Label>
                <Field name="totalPerTicketFee">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Total Per Ticket"
                      id="totalPerTicketFee"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="subTotal">Sub-Total</Label>
                <Field name="subTotal">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Sub-Total"
                      id="subTotal"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="name">Total</Label>
                <Field name="total">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="Total"
                      id="total"
                      {...field}
                    />
                  )}
                </Field>

                <Label htmlFor="status">Status</Label>
                <Field
                  className={`py-2 border rounded `}
                  as="select"
                  name="status"
                >
                  <option hidden>-- Select Status --</option>
                  <option value="CUSTOM">CUSTOM</option>
                  <option value="PENDING">PENDING</option>
                  <option value="FAILED">FAILED</option>
                  <option value="EXPIRED">EXPIRED</option>
                  <option value="COMPLETED">COMPLETED</option>
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateOrder;
