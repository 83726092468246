import React from "react";
import { useNavigate } from "react-router-dom";
import { TabModal } from "../../../components/common";
import { PaginationContainer } from "../../../components/common";
import { EventGroup } from "../../../models/event-group.model";
import {
  useDeleteEventGroupMutation,
  useGetEventGroupsQuery,
} from "../../../services/event-group.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { UpdateEventGroup } from "../UpdateEventGroup";
import AddEventToEventGroup from "../UpdateEventGroup/AddEventToEventGroup";

interface ComponentProps {
  search?: string;
}
export const EventGroupList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [active, setActive] = React.useState("Event Group");
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [eventGroup, setEventGroup] = React.useState<EventGroup>();
  const navigate = useNavigate();

  const { data, isLoading } = useGetEventGroupsQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteEventGroup] = useDeleteEventGroupMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Slug</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((eventGroup: EventGroup, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                    <li className="mr-4">
                      <button
                        className="px-2 py-1 border rounded-md"
                        onClick={() =>
                          navigator.clipboard.writeText(eventGroup.id)
                        }
                      >
                        COPY ID
                      </button>
                    </li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {eventGroup && eventGroup.image ? (
                          <img
                            alt={`${eventGroup.name}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              eventGroup.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{eventGroup.name}</li>
                <li>{eventGroup.slug}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button
                    className="px-4 py-2 bg-green-400 rounded"
                    onClick={() => navigate(`/event-group/${eventGroup.id}`)}
                  >
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setEventGroup(eventGroup);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setEventGroup(eventGroup);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {eventGroup && (
        <TabModal
          open={open}
          setOpen={setOpen}
          active={active}
          setActive={setActive}
          titles={["Event Group", "Group Events"]}
          size="m"
        >
          {active === "Event Group" ? (
            <UpdateEventGroup setOpen={setOpen} eventGroupId={eventGroup.id} />
          ) : (
            <AddEventToEventGroup eventGroupId={eventGroup.id} />
          )}
        </TabModal>
      )}
      {eventGroup && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Group`}
          size="s"
          action={deleteEventGroup}
          item={eventGroup.id}
        />
      )}
    </div>
  );
};
