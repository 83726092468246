import React from "react";
import { Button } from "../../../../components/common";
import { useDownloadHardTicketsPDFMutation } from "../../../../services/order.api";
import "./DownloadHardTixModal.css";
import { Order } from "../../../../models/order.model";

const AnimatedSuccess = () => {
  return (
    <div className="wrapper">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

const AnimatedLoading = () => {
  return (
    <div className="flex justify-center">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

interface ComponentProps {
  eventId: string;
  eventName: string;
  setSelectedItems: React.Dispatch<React.SetStateAction<Order[]>>;
  orderIds: string[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadHardTixModal: React.FC<ComponentProps> = ({
  eventId,
  eventName,
  setOpen,
  setSelectedItems,
  orderIds,
}) => {
  const [downloadHardTicketsPDF, { isLoading, isSuccess }] =
    useDownloadHardTicketsPDFMutation();

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false);
        setSelectedItems([]);
      }, 1500);
    }
  }, [isSuccess, setOpen]);

  return (
    <div>
      {isLoading && !isSuccess && <AnimatedLoading />}
      {isSuccess && !isLoading && <AnimatedSuccess />}
      {!isSuccess && !isLoading && (
        <div>
          <p>Are you sure you want to download these hard tickets?</p>
          <div className="flex gap-5 mt-5">
            <Button
              title="Proceed"
              variant="secondary"
              onClick={() => {
                downloadHardTicketsPDF({ eventId, eventName, orderIds });
              }}
            />
            <Button
              title="Cancel"
              variant="warning"
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadHardTixModal;
