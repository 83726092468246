import React, { useRef } from "react";
import {
  useGetProductQuery,
  useRemoveProductPhotoMutation,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from "../../../services/product.api";
import DynamicForm, {
  FieldDefinition,
} from "../../../components/common/DynamicForm";
import SelectProductCategory from "../ProductCategory/SelectProductCategory";
import ImageUploader from "../../../components/common/ImageUploader";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import OrganizationSelect from "../../Organization/OrganizationSelect";

interface ComponentProps {
  productId: string;
}

export const UpdateProductForm: React.FC<ComponentProps> = ({ productId }) => {
  const [update, { isLoading }] = useUpdateProductMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadProductImageMutation();

  const { data: product, isLoading: productIsLoading } =
    useGetProductQuery(productId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveProductPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (values: any) => {
    if (product)
      update({
        id: product.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: productId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = (imageId: string) => {
    removePhoto({id: productId, imageId});
  };

  if (isLoading || productIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!product) return <h2>Something went wrong..</h2>;
  const formFields: FieldDefinition[] = [
    {
      name: "name",
      label: "Name",
      type: "text",
      defaultValue: product.name,
    },
    {
      name: "slug",
      label: "Slug",
      type: "text",
      defaultValue: product.slug,
    },
    {
      name: "commissionPercentage",
      label: "Commission Percentage",
      type: "number",
      defaultValue: product.commissionPercentage || 0,
    },
    {
      name: "isPublished",
      label: "Published",
      type: "toggle",
      defaultValue: product.isPublished || false,
      customInput: <ToggleSwitch />,
    },
    {
      name: "isSystemOwned",
      label: "Is System Owned",
      type: "toggle",
      defaultValue: product.isSystemOwned || false,
      customInput: <ToggleSwitch />,
    },
    {
      name: "productCategoryId",
      label: "Product Category",
      type: "text",
      defaultValue: product.productCategoryId,
      customInput: <SelectProductCategory />,
    },
    {
      name: "organizationId",
      label: "Organization",
      type: "text",
      defaultValue: product.organizationId,
      customInput: <OrganizationSelect />,
    },
  ];
  return (
    <>
      <ImageUploader
        onFileChange={handleFileChange}
        onBrowseClick={() => handleBrowse()}
        images={
          product.image
            ? [{ id: product.image?.id, fileName: product.image?.fileName }]
            : []
        }
        onRemoveClick={(id) => handlePhotoRemove(id)}
      />
      <DynamicForm fields={formFields} onSubmit={handleSubmit} />
    </>
  );
};
