import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Label } from "../../../components/common";
import { Modal } from "../../../components/common/Modals/Modal";
import { Song } from "../../../models/song.model";

import { SongSelect } from "../../Song";
import { Option } from "../../../types/general-types";
import {
  useCreatePerformerSongMutation,
  useDeletePerformerSongMutation,
  CustomError,
} from "../../../services/performer.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
interface ComponentProps {
  songs: Song[];
  performerId: string;
}

interface FormValues {
  song: Option | undefined;
  performerId: string;
}

export const PerformerSong: React.FC<ComponentProps> = ({
  songs,
  performerId,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [performerSong, setPerformerSong] = React.useState<Song>();
  const [createPerformerSong, { isError, error, isLoading, isSuccess }] =
    useCreatePerformerSongMutation();

  const [
    deletePerformerSong,
    {
      isError: deletePerformerSongIsError,
      error: deletePerformerSongError,
      isLoading: deletePerformerSongIsLoading,
    },
  ] = useDeletePerformerSongMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    performerId: performerId,
    song: undefined,
  };

  const validationSchema = Yup.object().shape({
    performerId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    createPerformerSong({
      performerId: values.performerId,
      songId: values.song?.value || "",
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <div>
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Performer Song</h2>
          <Button title="Add Song" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {songs.map((song: Song, index: number) => (
              <ul className="grid justify-end grid-cols-3 mb-2" key={index}>
                <li>{song.name}</li>
                <li>{song.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setPerformerSong(song);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Performer Song" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="performers">Song</Label>
                  <SongSelect />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {performerSong && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Performer Song`}
          size="s"
          action={deletePerformerSong}
          item={{
            performerId,
            songId: performerSong.id,
          }}
        />
      )}
    </div>
  );
};
