import { ErrorMessage } from "formik";
import React from "react";

interface ComponentProps {
  namex?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLInputElement>;
export const Checkbox: React.FC<TExtendedProps> = ({ className, ...props }) => {
  return (
    <div className={className}>
      <input
        checked={props.value ? true : false}
        {...props}
        type="checkbox"
        className={`border rounded p-6 h-6 w-6`}
      />
      {props.name && (
        <ErrorMessage name={props.name || ""}>
          {(message) => <span className="text-red-500">{message}</span>}
        </ErrorMessage>
      )}
    </div>
  );
};
