import React, { useState, useEffect } from "react";
import { useRefreshMutation } from "../../../services/auth.api";
import { setUser } from "../authSlice";
import { useAppDispatch } from "../../../app/hooks";
import jwtDecode from "jwt-decode";

interface ComponentProps {
  children: JSX.Element | JSX.Element[];
}

const AuthContainer: React.FC<ComponentProps> = ({ children }) => {
  const refreshTokenKey = "refreshToken";
  const dispatch = useAppDispatch();
  const [refresh, { data, isSuccess, isLoading, isError }] =
    useRefreshMutation();
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Function to trigger the initial refresh
    const initialRefresh = async () => {
      refresh({});
      setRefreshInterval();
    };

    // Check if the refresh token is available when the component loads
    if (localStorage.getItem(refreshTokenKey)) {
      initialRefresh();
    }
  }, []); // Empty dependency array to run this effect only once when the component mounts

  useEffect(() => {
    if (isError) {
      localStorage.removeItem(refreshTokenKey);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && !isLoading) {
      // Handle the successful refresh here and dispatch the updated user state
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );

      localStorage.setItem(refreshTokenKey, data.refreshToken);
    }
  }, [isSuccess, isLoading, data, dispatch]);

  const setRefreshInterval = () => {
    // Set a timer to refresh the user's session every 29 minutes
    const interval = setInterval(() => {
      refresh({});
    }, 29 * 60 * 1000);

    setRefreshTimer(interval);
  };

  useEffect(() => {
    // Clear the refresh timer when the component unmounts
    return () => {
      if (refreshTimer) {
        clearInterval(refreshTimer);
      }
    };
  }, [refreshTimer]);

  // if (isLoading) return <h2>Loading...</h2>;

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthContainer;
