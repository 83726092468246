import React from "react";
import ReactModal from "react-modal";
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./assets/css/clash-display.css";
import { useAppSelector } from "./app/hooks";
import { authSelect } from "./features/Auth/authSlice";
import "./assets/css/clash-display.css";
import Body from "./components/layout/Body";
import Sidebar from "./components/layout/Sidebar";
import Logout from "./features/Auth/Logout";
import {
  DashboardPage,
  EventPage,
  GroupPage,
  OrganizationPage,
  PerformerPage,
  ViewPerformerPage,
  SigninPage,
  SignupPage,
  UserPage,
  VenuePage,
  UpdateEventPage,
  SelectedEventPage,
  OrderPage,
  SplashScreen,
  TicketPage,
  ProductPage,
  CategoryPage,
  RolePage,
  PermissionPage,
  UpdatePerformerPage,
  TicketClaimsPage,
  EventTicketPage,
  EventGroupPage,
  WithdrawPage,
  SelectedEventGroup,
  EmailMarketing,
  SelectedOrderPage,
} from "./pages";
import { CarouselPage } from "./pages/Carousel";
import { VoucherPage } from "./pages/Voucher";
import { SongPage } from "./pages/Song";
import { GenrePage } from "./pages/Genre";
import { ArtistInquiryPage } from "./pages/ArtistInquiry";
import { UpdateGroupPage } from "./pages/Group/UpdateGroupPage";
import { BrandPage } from "./pages/Brand";
import ConversationPage from "./pages/Conversation";
import SelectedConversationPage from "./pages/Conversation/SelectedConversation";
import { UpdateProduct } from "./features/Product";
import SelectedOrganizationPage from "./pages/Organization/SelectedOrganization";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
}

interface LocationState {
  from?: string;
}

interface ComponentProps {
  children?: JSX.Element | JSX.Element[];
}
type ExtendentComponentProps = ProtectedRouteProps & ComponentProps;
const ProtectedRoute: React.FC<ExtendentComponentProps> = ({
  isAllowed,
  redirectPath = "/",
  children,
}): any => {
  const location = useLocation();
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

ReactModal.setAppElement("#root");
const App = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const { user } = useAppSelector(authSelect);
  const [splashScreen, setSplashScreen] = React.useState(false);

  React.useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", "corporate");
  }, []);

  React.useEffect(() => {
    setSplashScreen(true);
    setTimeout(() => {
      setSplashScreen(false);
    }, 2000);
  }, []);

  if (splashScreen) return <SplashScreen />;

  return (
    <div
      id="app"
      className="flex h-screen overflow-hidden"
      style={{ fontFamily: "ClashDisplay" }}
    >
      <Routes>
        <Route
          element={
            <ProtectedRoute
              isAllowed={user === null}
              redirectPath={state?.from || "/"}
            />
          }
        >
          <Route path="/signin" element={<SigninPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Route>
        <Route
          element={
            <div className="flex flex-grow w-full bg-blue-900">
              <Sidebar className="flex flex-col text-white">
                <div className="mb-10 text-2xl ">Ticketnation</div>
                <div className="flex-grow overflow-y-scroll">
                  <ul className="grid text-lg gap-y-2 ">
                    <Link to="/">Dashboard</Link>
                    <Link to="brand">Brand</Link>
                    <Link to="conversation">Conversation</Link>
                    <Link to="carousel">Carousel</Link>
                    <Link to="product">Product</Link>
                    <Link to="category">Category</Link>
                    <Link to="email-marketing">Email Marketing</Link>
                    <Link to="event">Event</Link>
                    <Link to="event-group">Event Group</Link>
                    <Link to="venue">Venue</Link>
                    <Link to="artist-inquiry">Artist Inquiry</Link>
                    <Link to="performer">Performer</Link>
                    <Link to="group">Group</Link>
                    <Link to="song">Song</Link>
                    <Link to="genre">Genre</Link>
                    <Link to="ticket">Ticket</Link>
                    <Link to="ticket-claims">Ticket Claims</Link>
                    <Link to="order">Order</Link>
                    <Link to="user">User</Link>
                    <Link to="role">Role</Link>
                    <Link to="permission">Permission</Link>
                    <Link to="organization">Organization</Link>
                    <Link to="voucher">Voucher</Link>
                    <Link to="withdraw">Withdraw</Link>
                  </ul>
                </div>
                <div className="flex items-center mb-2">
                  <div className="flex items-center justify-center w-12 h-12 mr-2 uppercase bg-black rounded-full">
                    {`${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`}
                  </div>
                  {`${user?.firstName} ${user?.lastName}`}
                </div>
                <Logout />
              </Sidebar>
              <Body>
                <Outlet />
              </Body>
            </div>
          }
        >
          <Route
            element={
              <ProtectedRoute
                isAllowed={user !== null}
                redirectPath="/signin"
              />
            }
          >
            <Route path="/" element={<DashboardPage />} />
            <Route path="brand" element={<BrandPage />} />
            <Route path="conversation">
              <Route index element={<ConversationPage />} />
              <Route
                path=":conversationId"
                element={<SelectedConversationPage />}
              />
            </Route>
            <Route path="email-marketing" element={<EmailMarketing />} />
            <Route path="event">
              <Route index element={<EventPage />} />
              <Route path=":eventId" element={<SelectedEventPage />} />
              <Route path=":eventId/edit" element={<UpdateEventPage />} />
              <Route path=":eventId/ticket" element={<EventTicketPage />} />
            </Route>
            <Route path="event-group">
              <Route index element={<EventGroupPage />} />
              <Route path=":eventGroupId" element={<SelectedEventGroup />} />
            </Route>
            <Route path="category" element={<CategoryPage />} />
            <Route path="carousel" element={<CarouselPage />} />
            <Route path="venue" element={<VenuePage />} />
            <Route path="ticket" element={<TicketPage />} />
            <Route path="ticket-claims">
              <Route index element={<TicketClaimsPage />} />
              <Route path=":id" element={<TicketClaimsPage />} />
            </Route>
            <Route path="organization">
              <Route index element={<OrganizationPage />} />
              <Route
                path=":id/view"
                element={<SelectedOrganizationPage />}
              />
            </Route>
            <Route path="order">
              <Route index element={<OrderPage />} />
              <Route path=":orderId/view" element={<SelectedOrderPage />} />
            </Route>
            <Route path="performer">
              <Route index element={<PerformerPage />} />
              <Route path=":id" element={<ViewPerformerPage />} />
              <Route path=":id/edit" element={<UpdatePerformerPage />} />
            </Route>
            <Route path="artist-inquiry" element={<ArtistInquiryPage />} />
            <Route path="group">
              <Route index element={<GroupPage />} />
              <Route path=":id/edit" element={<UpdateGroupPage />} />
            </Route>
            <Route path="song" element={<SongPage />} />
            <Route path="genre" element={<GenrePage />} />
            <Route path="product">
              <Route index element={<ProductPage />} />
              {/* <Route path=":id" element={<ViewPerformerPage />} /> */}
              <Route path=":id/edit" element={<UpdateProduct />} />
            </Route>
            <Route path="voucher" element={<VoucherPage />} />
            <Route path="user" element={<UserPage />} />
            <Route path="role" element={<RolePage />} />
            <Route path="permission" element={<PermissionPage />} />
            <Route path="withdraw" element={<WithdrawPage />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
