import {
  AddEventBrand,
  DeleteEventBrand,
  DeleteEventVoucher,
} from "./../models/event-ticket.model";
import {
  AddEventPerformer,
  CreateEvent,
  UpdateEvent,
  DeleteEventPerformer,
  DeleteEventGroup,
  AddEventGroup,
} from "./../models/event.model";
import { Event } from "../models/event.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  EventTicketRequest,
  AddEventVoucher,
  DeleteEventTicket,
} from "../models/event-ticket.model";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  CreateEventProduct,
  DeleteEventProduct,
  EventProduct,
} from "../models/event-product.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Events {
  data: Event[];
  skip: number;
  take: number;
  count: number;
}

interface EventsQuery {
  skip?: number;
  take?: number;
  search?: string;
  isActive?: boolean;
}

interface EventProducts {
  data: EventProduct[];
  skip: number;
  take: number;
  count: number;
}

interface EventProductsQuery {
  eventId: string;
  skip?: number;
  take?: number;
  search?: string;
}

interface DeleteVenueMapPayload {
  eventId: string;
  venueMapId: string
}

const eventProductApi = (builder: EndpointBuilder<any, any, any>) => ({
  createEventProduct: builder.mutation<EventProduct, CreateEventProduct>({
    query: ({ eventId, ...body }) => ({
      method: "POST",
      url: `${eventId}/product`,
      body,
    }),
    invalidatesTags: ["EventProducts"],
  }),
  deleteEventProduct: builder.mutation<EventProduct, DeleteEventProduct>({
    query: ({ eventId, productId }) => ({
      method: "DELETE",
      url: `${eventId}/products/${productId}`,
    }),
    invalidatesTags: ["EventProducts"],
  }),
  getEventProducts: builder.query<EventProducts, EventProductsQuery>({
    query: ({ eventId }) => ({
      method: "GET",
      url: `${eventId}/product`,
    }),
    providesTags: ["EventProducts"],
  }),
});

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/event",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Events", "Event", "EventProduct", "EventProducts"],
  endpoints: (builder) => ({
    createEvent: builder.mutation<Event, CreateEvent>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Events"],
    }),
    addEventTicket: builder.mutation<Event, EventTicketRequest>({
      query: ({ eventId, ...body }) => ({
        url: `${eventId}/ticket`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    updateEventTicket: builder.mutation<Event, EventTicketRequest>({
      query: ({ eventId, id, ...body }) => ({
        url: `${eventId}/event-ticket/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    addEventVoucher: builder.mutation<Event, AddEventVoucher>({
      query: ({ eventId, ...body }) => ({
        url: `${eventId}/voucher`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    addEventBrand: builder.mutation<Event, AddEventBrand>({
      query: ({ eventId, ...body }) => ({
        url: `${eventId}/brand`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    getEvents: builder.query<Events, EventsQuery>({
      query: ({ take, skip, search, isActive }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
            isActive,
          },
        };
      },
      providesTags: ["Events"],
    }),
    getEvent: builder.query<Event, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Event"],
    }),
    updateEvent: builder.mutation<Event, UpdateEvent>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Events", "Event"],
    }),
    uploadEventImage: builder.mutation<Event, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Events", "Event"],
    }),
    uploadEventVenueMap: builder.mutation<Event, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/venuemap-upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Events", "Event"],
    }),
    deleteEvent: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events"],
    }),
    addEventPerformer: builder.mutation<Event, AddEventPerformer>({
      query: ({ eventId, ...body }) => ({
        url: `${eventId}/performer`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEventPerformer: builder.mutation<void, DeleteEventPerformer>({
      query: ({ eventId, eventPerformerId }) => ({
        url: `/${eventId}/performer/${eventPerformerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEventTicket: builder.mutation<void, DeleteEventTicket>({
      query: ({ eventId, eventTicketId }) => ({
        url: `/${eventId}/event-ticket/${eventTicketId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEventVoucher: builder.mutation<void, DeleteEventVoucher>({
      query: ({ eventId, voucherId }) => ({
        url: `/${eventId}/event-voucher/${voucherId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEventBrand: builder.mutation<void, DeleteEventBrand>({
      query: ({ eventId, brandId }) => ({
        url: `/${eventId}/event-brand/${brandId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    removeEventPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events", "Event"],
    }),
    removeEventVenueMapPhoto: builder.mutation<void, DeleteVenueMapPayload>({
      query: ({eventId, venueMapId}) => ({
        url: `/${eventId}/venuemap-photo/${venueMapId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Events", "Event"],
    }),
    addEventGroup: builder.mutation<Event, AddEventGroup>({
      query: ({ eventId, ...body }) => ({
        url: `${eventId}/group`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Event"],
    }),
    deleteEventGroup: builder.mutation<void, DeleteEventGroup>({
      query: ({ eventId, eventGroupId }) => ({
        url: `/${eventId}/group/${eventGroupId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Event"],
    }),
    ...eventProductApi(builder),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventQuery,
  useGetEventsQuery,
  useUpdateEventMutation,
  useUploadEventImageMutation,
  useUploadEventVenueMapMutation,
  useDeleteEventMutation,
  useRemoveEventPhotoMutation,
  useRemoveEventVenueMapPhotoMutation,
  useAddEventPerformerMutation,
  useAddEventTicketMutation,
  useUpdateEventTicketMutation,
  useAddEventVoucherMutation,
  useAddEventBrandMutation,
  useDeleteEventPerformerMutation,
  useDeleteEventTicketMutation,
  useDeleteEventVoucherMutation,
  useDeleteEventBrandMutation,
  useAddEventGroupMutation,
  useDeleteEventGroupMutation,
  useCreateEventProductMutation,
  useGetEventProductsQuery,
  useDeleteEventProductMutation
} = eventApi;
