import React, { useState } from "react";
import {
  useGetEventProductOrderVariantsQuery,
  useGetProductOrderQuery,
} from "../../../../services/product-order.api";
import DynamicTable, { TableData } from "../../../../components/common/Table";
import { ProductOrderVariant } from "../../../../models/product-order.model";
import QRCode from "react-qr-code";

interface ComponentProps {
  id: string;
}

const ProductOrderVariantPage: React.FC<ComponentProps> = ({ id }) => {
  const [take, setTake] = useState(10);
  const [active, setActive] = React.useState("");
  const [skip, setSkip] = useState(0);
  const { data } = useGetEventProductOrderVariantsQuery({
    id,
    take,
    skip,
  });

  const columns = [
    {
      key: "name",
      label: "Product Name",
    },
    {
      key: "variant",
      label: "Variant",
    },
    {
      key: "color",
      label: "Color",
    },
    {
      key: "size",
      label: "Size",
    },
    {
      key: "price",
      label: "Price",
    },
    {
      key: "quantity",
      label: "Quantity",
    },
    {
      key: "isSystemOwned",
      label: "Owner",
    },
    {
      key: "subtotal",
      label: "Total",
    },
  ];

  const tableData: TableData[] =
    data?.data
      .filter(({ subtotal }) => subtotal !== 0)
      .map(
        ({ id, ProductVariant, quantity, subtotal }: ProductOrderVariant) => ({
          id,
          name: ProductVariant?.product?.name,
          quantity: quantity,
          price: ProductVariant?.price,
          subtotal,
          variant: ProductVariant?.name,
          color: ProductVariant?.color,
          size: ProductVariant?.size,
          isSystemOwned: (
            <div className={`flex justify-center  `}>
              <div
                className={`px-2 py-1 font-semibold text-white rounded-md w-fit ${
                  ProductVariant?.product?.isSystemOwned
                    ? "bg-green-400"
                    : "bg-gray-400"
                }`}
              >
                {ProductVariant?.product?.isSystemOwned
                  ? "System Owned"
                  : ProductVariant?.product?.organization?.name ||
                    "Partner Owned"}
              </div>
            </div>
          ),
        })
      ) || [];

  const productOrder = data?.data[0]?.ProductOrder;

  return (
    <div>
      {productOrder?.code && (
        <div className="w-full mb-5">
          {productOrder?.isClaimed && (
            <div className=" flex items-center justify-center text-4xl lg:text-[90px] font-bold text-red-500">
              <div className="relative flex items-center justify-center w-full h-full">
                <div className="absolute  border-8  border-red-500 rounded-full top-[10px] bg-white bg-opacity-90 z-0 w-52 lg:w-96 h-52 lg:h-96 " />
                <h1 className="absolute z-10 -rotate-45 lg:top-[180px] top-[105px]">
                  CLAIMED
                </h1>
              </div>
            </div>
          )}
          <QRCode value={productOrder?.code} size={400} className="w-full" />
        </div>
      )}

      <div></div>
      <DynamicTable
        data={tableData}
        columns={columns}
        total={data?.count || 0}
      />
    </div>
  );
};

export default ProductOrderVariantPage;
