import React from "react";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../../components/common";
import { Event } from "../../../../models/event.model";
import SelectEvent from "../../Components/SelectEvent";
import dayjs from "dayjs";
import CarouselSelect from "../../Components/SelectCarousel";
import SelectUser from "../../Components/SelectUser";
import { useSendEventPresaleEmailMutation } from "../../../../services/email-marketing.api";
import { ConfirmModal } from "../../../../components/common/Modals";
import { CustomError } from "../../../../models/email-marketing.model";
import SelectVoucher from "../../Components/SelectVoucher";
import SelectMultipleEvents from "../../Components/SelectMultipleEvents";
import { Voucher } from "../../../../models/voucher.model";

interface FormValues {
  eventId: string;
  eventName: string;
  eventDate: string;
  eventDescription: string;
  imageFileName: string;
  voucherCode: string;
  eventSlug: string;
  userEmails?: string[];
  isManyUsers: boolean;
  skip?: number;
  take?: number;
}

const PresaleTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<Event | undefined>();
  const [item, setItem] = React.useState<FormValues>();
  const [values, setValues] = React.useState<FormValues | undefined>();
  const [eventIds, setEventsId] = React.useState<string[]>([]);
  const [usersEmail, setUsersEmail] = React.useState<string[]>([]);
  const [sendEventPresaleEmail, { isLoading, isError, error, isSuccess }] =
    useSendEventPresaleEmailMutation();
  const [voucher, setVoucher] = React.useState<Voucher>();
  const initialValues: FormValues = {
    eventId: event?.id || "",
    eventName: event?.name || "",
    eventDate: dayjs(event?.dateTime).format("MMMM DD, YYYY - hh:mmA") || "",
    eventDescription: event?.description || "",
    eventSlug: event?.slug || "",
    voucherCode: "",
    imageFileName: event?.image?.fileName || "",
    userEmails: usersEmail || [],
    isManyUsers: false,
  };

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().required("Event ID is required."),
    eventName: Yup.string().required("Event Name is required."),
    eventDate: Yup.string().required("Date is required."),
    eventDescription: Yup.string().required("Event Description is required."),
    eventSlug: Yup.string().required("Event Slug is required."),
    voucherCode: Yup.string().required("Voucher Code is required."),
    userEmails: Yup.array(),
    isManyUsers: Yup.boolean(),
    imageFileName: Yup.string().required("Image is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setOpen(true);
    setItem({
      ...values,
      skip: 0,
      take: 100,
    });
  };

  return (
    <>
      {open && (
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          title={`Send Event Pre-sale Email`}
          size="s"
          action={sendEventPresaleEmail}
          item={item}
        />
      )}
      <div className="flex flex-col items-center justify-center gap-5 p-3 font-roboto lg:items-start lg:flex-row ">
        <Formik
          innerRef={(formikActions) =>
            formikActions
              ? setValues(formikActions.values)
              : setValues(undefined)
          }
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <div className="grid p-5 w-[500px] gap-2 border rounded-md">
              <SelectEvent
                setUsersEmail={setUsersEmail}
                event={event}
                setEvent={setEvent}
              />
              {event && (
                <Form>
                  {isError && error && (
                    <div className="text-red-500">
                      <h3 className="font-semibold">Error(s)</h3>
                      <ul>
                        <li>{(error as CustomError).data.message}</li>
                      </ul>
                    </div>
                  )}
                  <div className="grid items-center grid-cols-3 gap-6 pt-6 ">
                    <Label htmlFor="eventName">Event Name</Label>
                    <Field name="eventName">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Name"
                          id="eventName"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDate">Date</Label>
                    <Field name="eventDate">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Date"
                          id="eventDate"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="voucherCode">Select Voucher</Label>
                    <SelectVoucher
                      eventId={event.id}
                      name="voucherCode"
                      className="col-span-2"
                      setVoucher={setVoucher}
                    />
                    <Label htmlFor="voucherCode">Voucher Code</Label>
                    <Field name="voucherCode">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Voucher Code"
                          id="voucherCode"
                          disabled
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDescription">Event Description</Label>
                    <Field name="eventDescription">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Description"
                          id="eventDescription"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventSlug">Slug</Label>
                    <Field name="eventSlug">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Slug"
                          id="eventSlug"
                          disabled
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="imageFileName">Change Image</Label>
                    <CarouselSelect
                      name="imageFileName"
                      className="col-span-2"
                    />
                    <Label htmlFor="eventIds">
                      Select Event for ticket buyers targeting
                    </Label>
                    <SelectMultipleEvents
                      className="col-span-2"
                      setEventsId={setEventsId}
                    />
                    <Label htmlFor="isManyUsers">Mupltiple Users</Label>
                    <Field name="isManyUsers">
                      {({ field }: FieldProps) => (
                        <Checkbox
                          className="col-span-2"
                          id="isManyUsers"
                          {...field}
                        />
                      )}
                    </Field>
                    {!values?.isManyUsers && eventIds.length !== 0 && (
                      <>
                        <Label htmlFor="userEmails">Add Users</Label>
                        <SelectUser
                          className="col-span-2"
                          setUsersEmail={setUsersEmail}
                          eventId={event.id}
                          eventIds={eventIds}
                          emailType="PRESALE"
                        />
                      </>
                    )}
                    <div className="col-span-3">
                      <button
                        type="submit"
                        className="w-full px-4 py-2 font-bold text-white border rounded bg-darkBlue-700"
                      >
                        Send Event Presale Email
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </Formik>
        <div className="w-[500px] h-fit border rounded-md font-roboto tracking-wider">
          <div className="p-6 font-semibold text-center text-white bg-lightBlue-300 rounded-t-md">
            <div className="flex flex-col items-center justify-center pt-5 text-center">
              <a
                className="mb-6"
                target="_blank"
                href="https://www.ticketnation.ph/"
                rel="noreferrer"
              >
                <img
                  className="w-36"
                  alt={`tnlogowhite`}
                  src={process.env.REACT_APP_IMAGE_BASE_URL + "tnlogowhite.png"}
                />
              </a>
              <p className="text-base">
                Your Exclusive Access to
                <br />
                <span className="text-xl">{values?.eventName}</span>
                <br />
                Presale Tickets
              </p>
            </div>
          </div>
          <div className="w-full h-fit">
            <div className="flex items-center justify-center object-cover w-full h-full overflow-hidden bg-black">
              {values?.imageFileName && values.eventName && (
                <img
                  alt={`${values.eventName}_image`}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}${values.imageFileName}`}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="text-lg font-bold">
                {values?.eventName || "Event Name"}
              </p>
              <p className="text-sm">{values?.eventDate || "Event Date"}</p>
              <p className="text-sm">
                Code:{" "}
                <span className="text-base font-bold text-darkBlue-700">
                  {values?.voucherCode || "Voucher Code"}
                </span>
              </p>
              {values && (
                <a
                  target="_blank"
                  href={
                    process.env.REACT_APP_BASE_URL +
                    "/event/" +
                    values?.eventSlug
                  }
                  rel="noreferrer"
                >
                  <div className="p-2 text-sm font-semibold text-center text-white rounded-md bg-darkBlue-700">
                    SEE TICKETS
                  </div>
                </a>
              )}
            </div>
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="text-lg font-bold">Get your Tickets Now!</p>
              <p className="pb-3 text-sm">
                {values?.eventDescription || "Event Description"}
              </p>
              <p className="text-xs text-end">
                © 2022 Ticketnation Philippines
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresaleTemplate;
