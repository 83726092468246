import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label, Checkbox } from "../../../components/common";

import {
  CustomError,
  useGetTicketQuery,
  useUpdateTicketMutation,
} from "../../../services/ticket.api";

interface FormValues {
  section: string;
  row: string;
  seat: number;
  eventTicketId: string;
  isClaimed: boolean;
}
interface ComponentProps {
  ticketId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateTicket: React.FC<ComponentProps> = ({ setOpen, ticketId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateTicketMutation();

  const { data: ticket, isLoading: ticketIsLoading } =
    useGetTicketQuery(ticketId);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (ticket)
      update({
        id: ticket.id,
        ...values,
      });
  };

  if (isLoading || ticketIsLoading) return <h2>Loading...</h2>;
  if (!ticket) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    section: ticket.section || "",
    row: ticket.section || "",
    seat: ticket.seat || 0,
    eventTicketId: ticket.eventTicketId,
    isClaimed: ticket.isClaimed || false,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-3 gap-2">
                <Label htmlFor="isClaimed">isClaimed</Label>
                <Field name="isClaimed">
                  {({ field }: FieldProps) => (
                    <Checkbox id="isClaimed" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 rounded bticket"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateTicket;
