import React from "react";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi";
import { OrderDetailsGrouped } from "..";

interface TicketAccordionProps {
  items: OrderDetailsGrouped[];
  titleCase: (str: string) => string;
  currency: string;
}

const TicketAccordion = ({
  items,
  titleCase,
  currency,
}: TicketAccordionProps) => {
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const toggleItem = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          <div
            key={index}
            onClick={() => toggleItem(index)}
            className="cursor-pointer hover:bg-blue-300 mx-4 py-4 md:flex-row gap-4 md:p-8 border border-x-0 border-b-0 border-t-1 border-blue-100"
          >
            <div className="flex flex-col">
              <ul
                className={`flex justify-between w-full items-center ${
                  activeIndex === index && "font-medium"
                }`}
              >
                <li className="font-medium">{item.eventTicketName}</li>
                <span className="flex flex-col md:flex-row gap-1 justify-between w-1/4">
                  <li className="flex justify-end">{currency} </li>
                  <li className={`flex justify-end `}>{item.subTotal}</li>
                </span>
              </ul>
            </div>
          </div>
          {activeIndex === index && (
            <div className="mx-4 py-4 md:px-8 md:pb-8 md:pt-4">
              {item.transactions.map((transaction, i) => (
                <div key={i}>
                  {transaction.transactionType !== "TICKET_FEE" && (
                    <div>
                      <ul className="flex justify-between w-full items-center">
                        <li className="flex items-center gap-1">
                          {transaction.type === "DEBIT" && (
                            <HiMinusCircle color="red" />
                          )}
                          {transaction.type === "CREDIT" && (
                            <HiPlusCircle color="green" />
                          )}
                          {titleCase(transaction.transactionType)}
                        </li>
                        <span className="flex flex-col md:flex-row gap-1 justify-between w-2/4">
                          <li className="flex justify-end">
                            {transaction.quantity} {" x "}
                            {Number(transaction.amount).toFixed(2)}
                          </li>
                          <li className="flex gap-2 justify-end md:justify-between w-full md:w-1/2">
                            <span>{currency}</span>
                            <span>{Number(transaction.total).toFixed(2)}</span>
                          </li>
                        </span>
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TicketAccordion;
