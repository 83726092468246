import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useRef } from "react";
import * as Yup from "yup";
import { Input, Label } from "../../../components/common";

import DatePicker from "../../../components/common/DatePicker";
import RichTextEditor from "../../../components/common/RichTextEditor";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "../../../components/common/Tabs";
import { TimezonePicker } from "../../../components/common/TimezonePicker";
import ToggleSwitch from "../../../components/common/ToggleSwitch";
import { VenueMap } from "../../../models/venue-image.model";
import {
  CustomError,
  useGetEventQuery,
  useRemoveEventPhotoMutation,
  useRemoveEventVenueMapPhotoMutation,
  useUpdateEventMutation,
  useUploadEventImageMutation,
  useUploadEventVenueMapMutation,
} from "../../../services/event.api";
import OrganizationSelect from "../../Organization/OrganizationSelect";
import VenueSelect from "../../Venue/VenueSelect";
import CurrencySelect from "../CurrencySelect";
import EventBrands from "./EventBrands";
import EventGroups from "./EventGroups";
import EventOrders from "./EventOrders";
import UpdateEventPerformers from "./EventPerformers";
import EventProduct from "./EventProduct";
import EventProductOrders from "./EventProductOrders";
import { EventTickets } from "./EventTickets";
import EventVouchers from "./EventVouchers";

interface FormValues {
  name: string;
  slug: string;
  promo?: string;
  terms?: string;
  venuePointers?: string;
  description: string;
  title?: string;
  subTitle?: string;
  venueId: string;
  organizationId: string;
  eventOrderFee: number;
  discount: number;
  dateTime: Date;
  start: Date;
  end: Date;
  isActive: boolean;
  isGroup: boolean;
  upcomingEvents: boolean;
  isReseller: boolean;
  currency: string;
  timezone: string;
}
interface ComponentProps {
  eventId: string;
}

const UpdateEvent: React.FC<ComponentProps> = ({ eventId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateEventMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadEventImageMutation();
  const [venueMapUpload, { isLoading: venueMapUploadIsLoading }] =
    useUploadEventVenueMapMutation();

  const { data: event, isLoading: eventIsLoading } = useGetEventQuery(eventId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveEventPhotoMutation();
  const [removeVenueMapPhoto, { isLoading: removeVenueMapPhotoIsLoading }] =
    useRemoveEventVenueMapPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);
  const venueImageRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (event)
      update({
        id: event.id,
        ...values,
        venueId: values.venueId,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: eventId,
        file: e.target.files[0],
      });
  };

  const handleVenueImageFileChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files?.length > 0)
      venueMapUpload({
        id: eventId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handleVenueImageBrowse = () => {
    if (venueImageRef.current) venueImageRef.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(eventId);
  };

  const handleVenueMapPhotoRemove = (venueMapId: string) => {
    removeVenueMapPhoto({ venueMapId, eventId });
  };

  if (
    isLoading ||
    eventIsLoading ||
    uploadIsLoading ||
    venueMapUploadIsLoading ||
    removePhotoIsLoading ||
    removeVenueMapPhotoIsLoading
  )
    return <h2>Loading...</h2>;
  if (!event) return <h2>Something went wrong..</h2>;

  const initialValues: FormValues = {
    name: event?.name || "",
    slug: event?.slug || "",
    promo: event?.promo || "",
    terms: event?.terms || "",
    venuePointers: event?.venuePointers || "",
    description: event?.description || "",
    title: event?.title || "",
    subTitle: event?.subTitle || "",
    venueId: event?.venue?.id.toString() || "",
    organizationId: event?.organizationId?.toString() || "",
    upcomingEvents: event?.upcomingEvents || false,
    isReseller: event?.isReseller || false,
    dateTime: new Date(event.dateTime),
    start: new Date(event.start),
    end: new Date(event.end),
    isActive: event.isActive,
    isGroup: event.isGroup,
    eventOrderFee: event.eventOrderFee || 0,
    discount: event.discount || 0,
    currency: event.currency || "PHP",
    timezone: event.timezone || "Asia/Manila",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    venueId: Yup.string().required(),
    dateTime: Yup.date().required(),
  });

  return (
    <>
      <Tabs>
        <div
          id="update_event"
          className="flex flex-grow h-full text-xs lg:flex-col lg:text-md"
        >
          <TabList grid={6}>
            <Tab>Info</Tab>
            <Tab>Performers</Tab>
            <Tab>Groups</Tab>
            <Tab>Tickets</Tab>
            <Tab>Brands</Tab>
            <Tab>Vouchers</Tab>
            <Tab>Orders</Tab>
            <Tab>Products</Tab>
            <Tab>Product Orders</Tab>
          </TabList>
          <div className="flex-1">
            <TabPanels>
              <TabPanel>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form>
                      <div className="grid gap-2 p-4 mb-2 border rounded shadow-sm">
                        {isError && error && (
                          <div className="text-red-500">
                            <h3 className="font-semibold">Error(s)</h3>
                            <ul>
                              {(error as CustomError).data.message.map(
                                (message) => (
                                  <li>{message}</li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                        <div className="flex items-center">
                          <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                            {event && event.image ? (
                              <img
                                alt={`${event.name}_image`}
                                src={
                                  process.env.REACT_APP_IMAGE_BASE_URL +
                                  event.image?.fileName
                                }
                              />
                            ) : (
                              <div className="bg-black " />
                            )}
                          </div>
                          <div className="grid grid-cols-3 gap-1">
                            <input
                              name="upload"
                              type="file"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                              ref={ref}
                            />
                            <button
                              className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                              type="button"
                              onClick={() => handleBrowse()}
                            >
                              Update Photo
                            </button>
                            <button
                              className="px-4 py-2 text-white bg-red-500 border rounded"
                              type="button"
                              onClick={() => handlePhotoRemove()}
                            >
                              Remove Photo
                            </button>
                            <p className="col-span-2 text-xs">
                              Image should be at least 400 x 400px as a png or
                              jpeg file.
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Label htmlFor="name">Venue Map</Label>
                          <div className="flex items-center">
                            <div className="flex flex-col items-center justify-center gap-1 mr-4 ">
                              {event && event.VenueMap.length > 0 ? (
                                <>
                                  {event.VenueMap.map((venueMap: VenueMap) => {
                                    return (
                                      <div className="">
                                        <img
                                          className="overflow-hidden bg-black rounded w-96 h-36"
                                          alt={`${event.name}_venue_map_image`}
                                          src={
                                            process.env
                                              .REACT_APP_IMAGE_BASE_URL +
                                            venueMap.image.fileName
                                          }
                                        />
                                        <button
                                          className="px-4 py-2 text-white bg-red-500 border rounded"
                                          type="button"
                                          onClick={() =>
                                            handleVenueMapPhotoRemove(
                                              venueMap.id
                                            )
                                          }
                                        >
                                          Remove Photo
                                        </button>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <div className="bg-black " />
                              )}
                            </div>
                            <div className="grid grid-cols-3 gap-1">
                              <input
                                name="upload"
                                type="file"
                                onChange={handleVenueImageFileChange}
                                style={{ display: "none" }}
                                ref={venueImageRef}
                              />
                              <button
                                className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                                type="button"
                                onClick={() => handleVenueImageBrowse()}
                              >
                                Update Photo
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <Label htmlFor="name">Name</Label>
                          <Field name="name">
                            {({ field }: FieldProps) => (
                              <Input placeholder="Name" id="name" {...field} />
                            )}
                          </Field>
                          <Label htmlFor="slug">Slug</Label>
                          <Field name="slug">
                            {({ field }: FieldProps) => (
                              <Input placeholder="Slug" id="slug" {...field} />
                            )}
                          </Field>
                          <Label htmlFor="description">Description</Label>
                          <Field name="description">
                            {({ field }: FieldProps) => (
                              <Input
                                placeholder="Description"
                                id="description"
                                {...field}
                              />
                            )}
                          </Field>
                          <Label htmlFor="title">Title</Label>
                          <Field name="title">
                            {({ field }: FieldProps) => (
                              <Input
                                placeholder="Title"
                                id="title"
                                {...field}
                              />
                            )}
                          </Field>
                          <Label htmlFor="subTitle">Sub Title</Label>
                          <Field name="subTitle">
                            {({ field }: FieldProps) => (
                              <Input
                                placeholder="Sub Title"
                                id="subTitle"
                                {...field}
                              />
                            )}
                          </Field>
                          <Label htmlFor="venue">Date and Time</Label>
                          <DatePicker />
                          <Label htmlFor="timezone">Timezone</Label>
                          <Field name="timezone">
                            {({ form }: FieldProps) => (
                              <TimezonePicker
                                name="timezone"
                                formik={form}
                                initialTimezone={{
                                  label: form.values.timezone,
                                  value: form.values.timezone,
                                }}
                              />
                            )}
                          </Field>
                          {/* To do check default value */}
                          <Label htmlFor="rte">Promo</Label>
                          <RichTextEditor name="promo" />
                          <Label htmlFor="terms">Terms</Label>
                          <RichTextEditor name="terms" />
                          <Label htmlFor="venue">Venue</Label>
                          <VenueSelect />
                          <Label htmlFor="rte">Venue Pointers</Label>
                          <RichTextEditor name="venuePointers" />
                          <Label htmlFor="venue">Start</Label>
                          <DatePicker name="start" />
                          <Label htmlFor="venue">End</Label>
                          <DatePicker name="end" />
                          <Label htmlFor="venue">Organization</Label>
                          <OrganizationSelect />
                          <Label htmlFor="active">Active</Label>
                          <Field name="isActive">
                            {({ field, form }: FieldProps) => (
                              <button
                                type="button"
                                className={`px-4 py-2 font-bold text-white 
                       border rounded ${
                         field.value ? "bg-green-400" : "bg-red-400"
                       }`}
                                onClick={() =>
                                  form.setFieldValue(field.name, !field.value)
                                }
                              >
                                {field.value ? "Active" : "Inactive"}
                              </button>
                            )}
                          </Field>
                          <Label htmlFor="eventOrderFee">Order Fee</Label>
                          <Field name="eventOrderFee">
                            {({ field }: FieldProps) => (
                              <Input
                                type="number"
                                placeholder="Order Fee"
                                id="eventOrderFee"
                                {...field}
                              />
                            )}
                          </Field>
                          <Label htmlFor="discount">Discount</Label>
                          <Field name="discount">
                            {({ field }: FieldProps) => (
                              <Input
                                type="number"
                                placeholder="Discount"
                                id="discount"
                                {...field}
                              />
                            )}
                          </Field>
                          <Label htmlFor="currency">Currency</Label>
                          <CurrencySelect name="currency" />
                          <Label htmlFor="upcomingEvents">
                            Upcoming Events Toggle
                          </Label>
                          <Field name="upcomingEvents">
                            {({ field, form }: FieldProps) => {
                              return (
                                <ToggleSwitch
                                  onClick={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  value={field.value}
                                />
                              );
                            }}
                          </Field>
                          <Label htmlFor="isReseller">Is Reseller Toggle</Label>
                          <Field name="isReseller">
                            {({ field, form }: FieldProps) => {
                              return (
                                <ToggleSwitch
                                  onClick={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  value={field.value}
                                />
                              );
                            }}
                          </Field>
                          <Label htmlFor="isGroup">Is Group</Label>
                          <Field name="isGroup">
                            {({ field, form }: FieldProps) => {
                              return (
                                <ToggleSwitch
                                  onClick={(value) => {
                                    form.setFieldValue(field.name, value);
                                  }}
                                  value={field.value}
                                />
                              );
                            }}
                          </Field>

                          <div />

                          <button
                            type="submit"
                            className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </TabPanel>
              <TabPanel>
                <UpdateEventPerformers event={event} />
              </TabPanel>
              <TabPanel>
                <EventGroups event={event} />
              </TabPanel>
              <TabPanel>
                <EventTickets event={event} />
              </TabPanel>
              <TabPanel>
                <EventBrands event={event} />
              </TabPanel>
              <TabPanel>
                <EventVouchers event={event} />
              </TabPanel>
              <TabPanel>
                <EventOrders event={event} />
              </TabPanel>
              <TabPanel>
                <EventProduct event={event} />
              </TabPanel>
              <TabPanel>
                <EventProductOrders event={event} />
              </TabPanel>
            </TabPanels>
          </div>
        </div>
      </Tabs>
    </>
  );
};

export default UpdateEvent;
