import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../../components/common";

import * as Yup from "yup";
import {
  useGetProductCategoriesQuery,
  useGetProductCategoryQuery,
  useUploadProductCategoryImageMutation,
  useRemoveProductCategoryPhotoMutation,
  useUpdateProductCategoryMutation,
  CustomError
} from "../../../../services/product-category.api";

interface FormValues {
  name: string;
  slug: string;
}

interface ComponentProps {
  productCategoryId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateProductCategory: React.FC<ComponentProps> = ({
  setOpen,
  productCategoryId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
  useUpdateProductCategoryMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadProductCategoryImageMutation();

  const { data: product, isLoading: productIsLoading } =
    useGetProductCategoryQuery(productCategoryId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
  useRemoveProductCategoryPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (product)
      update({
        id: product.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: productCategoryId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(productCategoryId);
  };

  if (isLoading || productIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!product) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: product?.name || "",
    slug: product?.slug || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    slug: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {product && product.image ? (
                    <img
                      alt={`${product.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        product.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="slug">Slug</Label>
                <Field name="slug">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Slug" id="slug" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
