import {
  CreateEventGroup,
  UpdateEventGroup,
  EventGroup,
  AddEventGroupEvent,
  EventGroupEvent,
} from "../models/event-group.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface EventGroups {
  data: EventGroup[];
  skip: number;
  take: number;
  count: number;
}

interface EventGroupsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const eventGroupApi = createApi({
  reducerPath: "eventGroupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/event-groups",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: [
    "EventGroups",
    "EventGroup",
    "EventGroupEvent",
    "EventGroupEvents",
  ],
  endpoints: (builder) => ({
    createEventGroup: builder.mutation<EventGroup, CreateEventGroup>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["EventGroups", "EventGroupEvent", "EventGroupEvents"],
    }),
    addEventGroupEvent: builder.mutation<EventGroupEvent, AddEventGroupEvent>({
      query: ({ id, ...body }) => ({
        url: `${id}/event`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EventGroupEvent", "EventGroupEvents"],
    }),
    removeEventGroupEventById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/event`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventGroupEvent", "EventGroupEvents"],
    }),
    getEventGroups: builder.query<EventGroups, EventGroupsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["EventGroups", "EventGroupEvent", "EventGroupEvents"],
    }),
    getEventGroup: builder.query<EventGroup, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["EventGroup", "EventGroupEvent", "EventGroupEvents"],
    }),
    updateEventGroup: builder.mutation<EventGroup, UpdateEventGroup>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["EventGroups", "EventGroupEvent", "EventGroupEvents"],
    }),
    uploadEventGroupImage: builder.mutation<
      EventGroup,
      { id: string; file: File }
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["EventGroups", "EventGroup"],
    }),
    deleteEventGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventGroups"],
    }),
    removeEventGroupPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventGroups", "EventGroup"],
    }),
  }),
});

export const {
  useCreateEventGroupMutation,
  useGetEventGroupQuery,
  useGetEventGroupsQuery,
  useUpdateEventGroupMutation,
  useUploadEventGroupImageMutation,
  useDeleteEventGroupMutation,
  useRemoveEventGroupPhotoMutation,
  useAddEventGroupEventMutation,
  useRemoveEventGroupEventByIdMutation,
} = eventGroupApi;
