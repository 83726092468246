import React, { useState } from "react";
import { Modal } from "../../../../components/common/Modals/Modal";
import { EventTicket } from "../../../../models/event-ticket.model";
import { useUpdateEventTicketMutation } from "../../../../services/event.api";
import { EventTicketForm } from "./EventTicketForm";
import { EventTicketInclusionForm } from "./EventTicketInclusionForm";
import { Button, TabModal } from "../../../../components/common";
import EventTicketInclusionList from "./EventTicketInclusionList";
interface ComponentProps {
  eventTicket: EventTicket;
}
export const EventTicketInclusionModal: React.FC<ComponentProps> = ({
  eventTicket,
}) => {
  const [active, setActive] = React.useState("Inclusions");
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        title="Inclusions"
        variant="secondary"
        onClick={() => setOpen(true)}
        className="mr-2"
      />
      <TabModal
        open={open}
        setOpen={setOpen}
        active={active}
        setActive={setActive}
        titles={["Inclusions", "Add Inclusions"]}
        size="m"
      >
        {active === "Inclusions" ? (
          <div>
            <EventTicketInclusionList eventTicketId={eventTicket.id}/>
          </div>
        ) : (
          <div>
            <EventTicketInclusionForm eventTicketId={eventTicket.id} setActive={setActive}/>
          </div>
        )}
      </TabModal>
    </>
  );
};
