import React from "react";
import { Modal } from "../../../../components/common/Modals";
import CreateEventTicketConversationForm from "./CreateEventTicketConversationForm";
import { useGetEventTicketConversationQuery } from "../../../../services/event-ticket.api";
import { Link, useLocation } from "react-router-dom";

interface EventTicketConversationsProps {
  eventTicketId: string;
}
const EventTicketConversations: React.FC<EventTicketConversationsProps> = ({ eventTicketId }) => {
  const take = 10;
  const skip = 0;
  const [open, setOpen] = React.useState(false);
  const { data, error, isLoading } = useGetEventTicketConversationQuery({
    eventTicketId,
    take,
    skip,
  });

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div className="overflow-x-auto">
      <button
        className="p-3 py-2 mb-2 text-white bg-blue-500 rounded-md"
        onClick={() => setOpen(true)}
      >
        Create Conversation
      </button>
      <table className="table w-full table-compact">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data.map((conversation) => (
              <tr key={conversation.id}>
                <td className="w-20">
                  <button
                    className="px-2 py-1 border rounded-md"
                    onClick={() =>
                      navigator.clipboard.writeText(conversation.id || "")
                    }
                  >
                    COPY ID
                  </button>
                </td>
                <td>{conversation.name}</td>
                <td>{conversation.description}</td>
                <td><Link className="px-3 py-2 text-white bg-blue-500 rounded-md" to={`/conversation/${conversation.id}`}>View Conversation</Link></td>
              </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </tfoot>
      </table>
      <Modal title="Create Conversation" open={open} setOpen={setOpen}>
        <CreateEventTicketConversationForm eventTicketId={eventTicketId} />
      </Modal>
    </div>
  );
};

export default EventTicketConversations;
