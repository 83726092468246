import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import { Organization } from "../../../models/organization.model";
import { useGetOrganizationsQuery } from "../../../services/organization.api";

interface ComponentProps {
  className?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const OrganizationSelect: React.FC<TExtendedProps> = ({
  className,
  ...props
}) => {
  const { data: organization } = useGetOrganizationsQuery({});

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`py-2 border rounded `}
        as="select"
        name="organizationId"
      >
        <option hidden>-- Select Organization --</option>
        {organization &&
          organization.data.map((organization: Organization, index: number) => (
            <option key={index} value={organization.id}>
              {organization.name}
            </option>
          ))}
      </Field>
      <ErrorMessage name="organizationId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default OrganizationSelect;
