import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Label, Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Event, EventGroup } from "../../../../models/event.model";
import GroupSelect from "../../../Group/GroupSelect";
import {
  CustomError,
  useAddEventGroupMutation,
  useDeleteEventGroupMutation,
} from "../../../../services/event.api";
import { Group } from "../../../../models/group.model";
import { Option } from "../../../../types/general-types";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  event: Event;
}

interface FormValues {
  eventId: string;
  groupId: string | Option;
}

const EventGroups: React.FC<ComponentProps> = ({ event }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventGroup, setEventGroup] = React.useState<Group>();
  const [addEventGroup, { isError, error, isLoading, isSuccess }] =
    useAddEventGroupMutation();

  const [
    deleteEventGroup,
    {
      isError: deleteEventGroupIsError,
      error: deleteEventGroupError,
      isLoading: deleteEventGroupIsLoading,
    },
  ] = useDeleteEventGroupMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    eventId: event.id.toString(),
    groupId: "",
  };

  const validationSchema = Yup.object().shape({
    groupId: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }).required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    addEventGroup({
      groupId: (values.groupId as Option).value,
      eventId: values.eventId,
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <>
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Groups</h2>
          <Button title="Add Group" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {event.groups.map(({ group }: EventGroup, i) => (
              <ul key={i} className="grid justify-end grid-cols-3 mb-2">
                <li>{group.name}</li>
                <li>{group.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setEventGroup(group);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Group" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message, i) => (
                        <li key={i}>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="groups">Group</Label>
                  <GroupSelect
                    isMulti={false}
                    name="groupId"
                    selectedOptions={event.groups.map(
                      ({ group }: EventGroup) => {
                        return {
                          label: group.name,
                          value: group.id.toString(),
                        };
                      }
                    )}
                  />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {eventGroup && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Group`}
          size="s"
          action={deleteEventGroup}
          item={{ eventId: event.id, eventGroupId: eventGroup.id }}
        />
      )}
    </>
  );
};

export default EventGroups;
