import React from "react";
import SignupForm from "../../features/Auth/SignupForm";

export const SignupPage = () => {
  return (
    <div className="grid flex-1 lg:grid-cols-2">
      <div className="items-center justify-center hidden text-white bg-blue-900 lg:flex">
        <div>
          <h2 className="text-6xl">Ticketnation</h2>
          <p>Admin Dashboard</p>
        </div>
      </div>
      <SignupForm />
    </div>
  );
};