import { Ticket, CreateTicket, UpdateTicket } from "../models/ticket.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { TicketClaim } from "../models/ticket-claims.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface TicketClaims {
  data: TicketClaim[];
  skip: number;
  take: number;
  count: number;
}

interface TicketClaimsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const ticketClaimsApi = createApi({
  reducerPath: "ticketClaimsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/ticket-claim",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Ticket-Claims"],
  endpoints: (builder) => ({
    getTicketClaim: builder.query<TicketClaims, TicketClaimsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Ticket-Claims"],
    }),
  }),
});

export const { useGetTicketClaimQuery } = ticketClaimsApi;
