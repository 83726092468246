import {
  DownloadHardTicketsPDF,
  DownloadOrderInvoicePDF,
  Order,
  OrderSummary,
  SendEmailMarketing,
  UpdateOrder,
} from "../models/order.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { EmailMarketing } from "../models/email-marketing.model";
import { User } from "../models/user.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Orders {
  data: Order[];
  skip: number;
  take: number;
  count: number;
}

interface Users {
  data: User[];
  skip: number;
  take: number;
  count: number;
}

interface OrdersQuery {
  id?: string | string[];
  skip?: number;
  take?: number;
  search?: string;
  from?: string;
  exchange?: string;
  status?: string;
  to?: string;
  eventDate?: string;
  isWithdraw?: boolean;
}

interface ExportOrdersQuery {
  id?: string;
  search?: string;
  from?: string;
  to?: string;
  voucherCode?: string;
  eventName?: string;
}

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/order",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Orders", "Order"],
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Orders"],
    }),
    getOrders: builder.query<Orders, OrdersQuery>({
      query: ({ take, skip, search, exchange, status, from, to }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
            exchange,
            status,
            from,
            to,
          },
        };
      },
      providesTags: ["Orders"],
    }),
    getOrdersByEvent: builder.query<Orders, OrdersQuery>({
      query: ({ id, ...rest }) => {
        return {
          url: `/events/${id}`,
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["Orders"],
    }),

    getEventOrderSummary: builder.query<OrderSummary, OrdersQuery>({
      query: ({ id, ...rest }) => {
        return {
          url: `/events/${id}/summary`,
        };
      },
      providesTags: ["Orders"],
    }),
    getOrdersByEventGroup: builder.query<Orders, OrdersQuery>({
      query: ({ id, ...rest }) => {
        return {
          url: `/event-groups/${id}`,
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["Orders"],
    }),
    getOrder: builder.query<Order, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Order"],
    }),
    resendEmail: builder.mutation<void, string>({
      query: (id) => ({
        url: `/resend/${id}`,
        method: "POST",
      }),
    }),
    downloadOrderInvoicePDF: builder.mutation<void, DownloadOrderInvoicePDF>({
      query: ({ orderId, orderNumber }) => ({
        url: `/download-order/${orderId}`,
        method: "POST",
        responseHandler: async (response) => {
          const dllink = document.createElement("a");
          dllink.href = window.URL.createObjectURL(await response.blob());
          dllink.setAttribute("download", `OrderInvoice${orderNumber}.pdf`);
          dllink.click();
        },
        cache: "no-cache",
      }),
      invalidatesTags: ["Orders"],
    }),
    downloadHardTicketsPDF: builder.mutation<void, DownloadHardTicketsPDF>({
      query: ({ eventId, eventName, ...body }) => ({
        url: `/download-pdf/${eventId}`,
        method: "POST",
        body,
        responseHandler: async (response) => {
          const dllink = document.createElement("a");
          dllink.href = window.URL.createObjectURL(await response.blob());
          dllink.setAttribute("download", `Hard-Tickets(${eventName}).pdf`); // Added Line
          dllink.click();
        },
        cache: "no-cache",
      }),
      invalidatesTags: ["Orders"],
    }),
    sendOrderEmailMarketing: builder.mutation<
      EmailMarketing,
      SendEmailMarketing
    >({
      query: ({ sendEmailId, ...body }) => ({
        url: `/order-mktg/${sendEmailId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Orders"],
    }),
    updateOrder: builder.mutation<Order, UpdateOrder>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Orders"],
    }),

    deleteOrder: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
    deleteExpiredOrder: builder.mutation({
      query: () => ({
        url: `/pending/expired`,
        method: "DELETE",
      }),
      invalidatesTags: ["Orders"],
    }),
    exportOrderByEvent: builder.mutation<void, ExportOrdersQuery>({
      query: ({ id, eventName, ...rest }) => {
        return {
          url: `/export/${id}`,
          params: {
            ...rest,
          },
          responseHandler: async (response) => {
            const dllink = document.createElement("a");
            dllink.href = window.URL.createObjectURL(await response.blob());
            dllink.setAttribute("download", `Sales Report ${eventName}.csv`); // Added Line
            dllink.click();
          },
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrderQuery,
  useGetOrdersQuery,
  useGetOrdersByEventQuery,
  useGetOrdersByEventGroupQuery,
  useGetEventOrderSummaryQuery,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useDeleteExpiredOrderMutation,
  useResendEmailMutation,
  useDownloadOrderInvoicePDFMutation,
  useDownloadHardTicketsPDFMutation,
  useSendOrderEmailMarketingMutation,
  useExportOrderByEventMutation,
} = orderApi;
