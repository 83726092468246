import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import Select from "react-select/async";
import { useLazyGetEventTicketsQuery } from "../../../services/event-ticket.api";

interface ComponentProps {
  excludeIds?: string[];
  className?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
}

interface FormValues {
  [key: string]: any;
  userId?: string;
  firstName: string;
  lastName: string;
  referenceId: string;
  eventTicketIds: Array<{ label: string; value: string }>;
  quantity: number;
}

const SelectEventTicket: React.FC<ComponentProps> = ({
  excludeIds,
  className,
  disabled = false,
  name,
  id,
}) => {
  const [skip] = React.useState(0);
  const [take] = React.useState(10);
  const [trigger, { data, isLoading, isError }] = useLazyGetEventTicketsQuery();
  console.log("isLoading", isLoading);
  const { setFieldValue, values } = useFormikContext<FormValues>();
  useEffect(() => {
    trigger({ skip, take });
  }, [trigger, skip, take]);

  const filterOptions = (inputValue: string) => {
    return (
      data?.data
        .map((eventTicket) => {
          return {
            label: eventTicket.name,
            value: eventTicket.id,
          };
        })
        .filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ) || []
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    trigger({ skip, take, search: inputValue });
    if (!isLoading) {
      callback(filterOptions(inputValue));
    }
  };
  const debounce = (func: any, wait: any) => {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debouncedLoadOptions = debounce(loadOptions, 300);

  if (isError) return <div>Error</div>;

  const inputName: string = name || "userId";

  return (
    <div className={`${className}`}>
      <div className="flex items-center">
        <div className="flex-1">
          <Field
            id={id}
            name={inputName}
            loadOptions={debouncedLoadOptions}
            placeholder="Select Event Tickets"
            isMulti
            isClearable
            value={values[inputName] || []}
            onChange={(options: any[]) => {
              setFieldValue(
                inputName,
                options && options.length > 0 ? options : []
              );
            }}
            component={Select}
          />
        </div>
        {values[inputName] && values[inputName].length > 0 && (
          <button
            className="w-8 h-8 ml-1 text-red-500 border border-red-500 rounded-full"
            onClick={() => setFieldValue(inputName, [])}
          >
            X
          </button>
        )}
      </div>
      <ErrorMessage name={inputName}>
        {(message) => (
          <span className="text-red-500">
            {typeof message === "object"
              ? Object.values(message).join(", ")
              : message}
          </span>
        )}
      </ErrorMessage>
    </div>
  );
};

export default SelectEventTicket;
