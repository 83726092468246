import React from "react";
import { Modal } from "../../../../components/common/Modals/Modal";
import { EventTicket } from "../../../../models/event-ticket.model";
import { useUpdateEventTicketMutation } from "../../../../services/event.api";
import { EventTicketForm } from "./EventTicketForm";
import { EventTicketInclusionForm } from "./EventTicketInclusionForm";
interface ComponentProps {
  open: string;
  setOpen: React.Dispatch<React.SetStateAction<string>>;
  eventId: string;
  eventTicket: EventTicket;
}
export const UpdateEventTicketModal: React.FC<ComponentProps> = ({
  eventId,
  open,
  setOpen,
  eventTicket,
}) => {
  const [addEventTicket, { isError, error, isLoading, isSuccess }] =
    useUpdateEventTicketMutation();

  React.useEffect(() => {
    if (isSuccess) setOpen("");
  }, [isSuccess, setOpen]);

  const handleOpen = () => {
    setOpen("");
  };
  return (
    <Modal
      open={open === eventTicket.id}
      setOpen={handleOpen}
      title="Update Ticket"
      size="m"
    >
      <EventTicketForm
        eventId={eventId}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSubmit={addEventTicket}
        error={error}
        eventTicket={eventTicket}
        disabledInputs={["quantity"]}
        buttonText="Update Event Tickets"
      />
    </Modal>
  );
};
