import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import Select from "react-select/async";
import { useLazyGetProductCategoriesQuery } from "../../../../services/product-category.api";

interface ComponentProps {
  excludeIds?: string[];
  className?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
}

interface FormValues {
  [key: string]: string | undefined;
}

const SelectProductCategory: React.FC<ComponentProps> = ({
  excludeIds,
  className,
  disabled = false,
  name,
  id,
}) => {
  const inputName: string = name || "productCategoryId";
  const [defaultOption, setDefaultOption] = React.useState<{
    label: string;
    value: string;
  } | null>(null);
  const [skip] = React.useState(0);
  const [take] = React.useState(10);
  const [trigger, { data, isLoading, isError }] =
    useLazyGetProductCategoriesQuery();
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const filterOptions = (inputValue: string) => {
    return (
      data?.data
        .map((productCategory) => {
          return {
            label: productCategory.name,
            value: productCategory.id,
          };
        })
        .filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ) || []
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    trigger({ skip, take, search: inputValue });
    if (!isLoading) return callback(filterOptions(inputValue));
  };

  React.useEffect(() => {
    trigger({ skip, take, search: "" });
  }, [trigger, skip, take]);

  React.useEffect(() => {
    if (data?.data && values[inputName]) {
      const defaultCategory = data.data.find(
        (productCategory) => productCategory.id === values[inputName]
      );
      if (defaultCategory) {
        setDefaultOption({
          label: defaultCategory.name,
          value: defaultCategory.id,
        });
      }
    }
  }, [data, inputName, setDefaultOption, values]);

  if (isError) return <div>Error</div>;

  return (
    <div className={`${className}`}>
      <div className="flex items-center">
        <div className="flex-1">
          <Field
            id={id}
            name={inputName}
            loadOptions={loadOptions}
            placeholder="Select a Category"
            value={defaultOption}
            onChange={(option: any) => {
              setFieldValue(inputName, option ? option.value : "");
            }}
            component={Select}
          />
        </div>
      </div>
      <ErrorMessage name={inputName}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default SelectProductCategory;
