import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Label, Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Event, EventBrand } from "../../../../models/event.model";
import BrandSelect from "../../../Brand/BrandSelect";
import {
  CustomError,
  useAddEventBrandMutation,
  useDeleteEventBrandMutation,
} from "../../../../services/event.api";
import { Brand } from "../../../../models/brand.model";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  event: Event;
}

interface FormValues {
  eventId: string;
  brandId: string;
}

const EventBrands: React.FC<ComponentProps> = ({ event }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventBrand, setEventBrand] = React.useState<Brand>();
  const [addEventBrand, { isError, error, isLoading, isSuccess }] =
    useAddEventBrandMutation();

  const [
    deleteEventBrand,
    {
      isError: deleteEventBrandIsError,
      error: deleteEventBrandError,
      isLoading: deleteEventBrandIsLoading,
    },
  ] = useDeleteEventBrandMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    eventId: event.id.toString(),
    brandId: "",
  };

  const validationSchema = Yup.object().shape({
    brandId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    addEventBrand({
      brandId: values.brandId,
      eventId: values.eventId,
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <>
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Brands</h2>
          <Button title="Add Brand" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {event.brands.map(({ brand }: EventBrand, i) => (
              <ul key={i} className="grid justify-end grid-cols-3 mb-2">
                <li>{brand.name}</li>
                <li>{brand.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setEventBrand(brand);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Brand" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message, i) => (
                        <li key={i}>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="brands">Brand</Label>
                  <BrandSelect
                    selectedOptions={event.brands.map(
                      ({ brand }: EventBrand) => {
                        return {
                          label: brand.name,
                          value: brand.id.toString(),
                        };
                      }
                    )}
                  />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {eventBrand && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Brand`}
          size="s"
          action={deleteEventBrand}
          item={{ eventId: event.id, brandId: eventBrand.id }}
        />
      )}
    </>
  );
};

export default EventBrands;
