import React from "react";
import SignInPic from "../../assets/images/signin-pic.svg";

import SigninForm from "../../features/Auth/SigninForm";

export const SigninPage: React.FC = () => {
  return (
    <div className="flex flex-grow ">
      <SigninForm className="lg:w-[45%] flex justify-center flex-grow" />
      <div className="items-center justify-center flex-1 hidden text-white bg-lightBlue-500 lg:flex ">
        <div className="ml-10 mr-10">
          <img src={SignInPic} alt="SignInPic" />
        </div>
      </div>
    </div>
  );
};
