import React from "react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  EventTicketRequest,
  EventTicket,
} from "../../../../models/event-ticket.model";
import { CustomError } from "../../../../services/event.api";

import DynamicForm, {
  FieldDefinition,
} from "../../../../components/common/DynamicForm";
import { EventTicketInclusionRequest } from "../../../../models";
import { useCreateEventTicketInclusionMutation } from "../../../../services/event-ticket.api";
interface ComponentProps {
  eventTicketId: string;
  setActive?: React.Dispatch<React.SetStateAction<string>>;
  disabledInputs?: string[];
  buttonText?: string;
}

export const EventTicketInclusionForm: React.FC<ComponentProps> = ({
  eventTicketId,
  setActive,
  buttonText = "Submit",
}) => {
  const [createEventTicketInclusion, { isError, error, isLoading, isSuccess }] =
    useCreateEventTicketInclusionMutation();

  React.useEffect(() => {
    if (isSuccess) {
      if(setActive)setActive("Inclusions");
    }
  }, [isSuccess, setActive]);
  const fields: FieldDefinition[] = [
    {
      name: "name",
      type: "text",
      label: "Name",
      required: true,
      defaultValue: "",
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      required: false,
      defaultValue: "",
    },
  ];

  const handleSubmit = (values: any) => {
    createEventTicketInclusion({
      ...values,
      additionalEvents: values.additionalEvents?.map(
        (event: any) => event.value
      ),
      eventTicketId: eventTicketId,
    });
  };

  const errorIsString =
    error && typeof (error as CustomError).data.message === "string";

  return (
    <div>
      {isError && error && (
        <div className="text-red-500">
          <h3 className="font-semibold">Error(s)</h3>
          <ul>
            {errorIsString
              ? (error as CustomError).data.message
              : (error as CustomError).data.message.map(
                  (message) => (error as CustomError).data.message
                )}
          </ul>
        </div>
      )}
      <DynamicForm
        buttonText={`Add Event Ticket Inclusion`}
        fields={fields}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
