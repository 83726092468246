import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useLogoutMutation } from "../../../services/auth.api";
import { logout } from "../authSlice";

const Logout = () => {
  const dispatch = useAppDispatch();
  const [logoutMutation, { isSuccess }] = useLogoutMutation();
  React.useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem("refreshToken");
      dispatch(logout());
    }
  }, [isSuccess, dispatch]);

  const handleLogout = () => {
    logoutMutation({});
  };
  return (
    <button
      className="px-4 py-2 text-blue-900 bg-white border border-blue-900 rounded"
      onClick={handleLogout}
    >
      Logout
    </button>
  );
};

export default Logout;
