import React, { useRef } from "react";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label, Checkbox } from "../../../components/common";
import {
  CustomError,
  useGetUserQuery,
  useRemoveUserPhotoMutation,
  useUpdateUserMutation,
  useUploadUserImageMutation,
} from "../../../services/user.api";
import OrganizationSelect from "../../Organization/OrganizationSelect";
import { UserRole } from "../../../models/user-role.model";
import { RoleSelect } from "../../Role";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  isOrgOwner: boolean;
  subscribe: boolean;
  organizationId: string;
  isActive: boolean;
  userRoles: UserRole[];
}
interface ComponentProps {
  userId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateUser: React.FC<ComponentProps> = ({ setOpen, userId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateUserMutation();
  const [upload, { isLoading: uploadIsLoading }] = useUploadUserImageMutation();

  const { data: user, isLoading: userIsLoading } = useGetUserQuery(userId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveUserPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (user)
      update({
        id: user.id,
        ...values,
        organizationId: values.organizationId,
        userRoles: values.userRoles,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: userId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(userId);
  };

  if (isLoading || userIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!user) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    email: user?.email || "",
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    mobileNumber: user?.mobileNumber || "",
    isOrgOwner: user?.isOrgOwner || false,
    subscribe: user?.subscribe || false,
    isActive: user?.isActive || false,
    organizationId: user?.organization?.id
      ? user?.organization?.id.toString()
      : "",
    userRoles: user?.userRoles || [],
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    isOrgOwner: Yup.string().required(),
    isActive: Yup.string().required(),
    subscribe: Yup.string().required(),
    // organizationId: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {user && user.image ? (
                    <img
                      alt={`${user.firstName}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        user.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2">
                <Label htmlFor="firstName">First Name</Label>
                <Field name="firstName">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="First Name"
                      id="firstName"
                      {...field}
                    />
                  )}
                </Field>

                <Label htmlFor="lastName">Last Name</Label>
                <Field name="lastName">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Last Name"
                      id="lastName"
                      {...field}
                    />
                  )}
                </Field>

                <Label htmlFor="email">Email</Label>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Email"
                      id="email"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="mobileNumber">Mobile Number</Label>
                <Field name="mobileNumber">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Mobiel Number"
                      id="mobileNumber"
                      {...field}
                    />
                  )}
                </Field>

                <Label htmlFor="isOrgOwner">Organization</Label>
                <OrganizationSelect className="col-span-2" />
                <div className="grid grid-cols-2 col-span-3 gap-1">
                  <div>
                    <Label htmlFor="isOrgOwner">Is Organization Owner</Label>
                    <Field name="isOrgOwner">
                      {({ field }: FieldProps) => (
                        <Checkbox
                          className="col-span-2"
                          id="isOrgOwner"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="isActive">Is Active</Label>
                    <Field name="isActive">
                      {({ field }: FieldProps) => (
                        <Checkbox
                          className="col-span-2"
                          id="isActive"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Label htmlFor="subscribe">Subscribe</Label>
                    <Field name="subscribe">
                      {({ field }: FieldProps) => (
                        <Checkbox
                          className="col-span-2"
                          id="subscribe"
                          {...field}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateUser;
