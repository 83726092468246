import { EmailMarketing } from "./email-marketing.model";
import { EventTicket } from "./event-ticket.model";
import { Ticket } from "./ticket.model";

export enum OrderType {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC",
}

interface User {
  email: string;
  id: string;
  emailMarketing: EmailMarketing[];
}

export interface OrderEventTicket {
  createdAt: string;
  eventTicket: EventTicket;
  price: number;
  quantity: number;
  ticketQuantity: number;
  total: number;
  tickets: Ticket[];
}
export interface Order {
  id: string;
  orderNumber: number;
  orderFee: number;
  total: number;
  totalPerTicketFee: number;
  status: string;
  exchange: string;
  referenceId?: string;
  type: OrderType;
  createdAt: string;
  user: User;
  subTotal: number;
  totalCommission: number;
  isPrinted: boolean;
  orderEventTickets: OrderEventTicket[];
  isExpiredEmailSent: boolean;
  payments: Payment[]
}

interface Payment {
  amount: number;
  createdAt: string;
  id: string;
  orderId: string;
  provider: string;
  status: string;
  updatedAt: string;
}

export interface OrderSummary {
  total: number;
  subTotal: number;
  totalQuantity: number;
  totalPerTicketFee: number;
  totalOrderFee: number;
}

export interface UpdateOrder {
  id: string;
  status?: string;
  isPrinted?: boolean;
}

export interface DownloadHardTicketsPDF {
  eventId: string;
  eventName: string;
  orderIds?: string[];
}

export interface DownloadOrderInvoicePDF {
  orderId: string;
  orderNumber: number;
}

export interface SendEmailMarketing {
  sendEmailId: string;
  voucherId?: string;
}
