import { Event } from "./event.model";

export enum EventTicketType {
  RESERVED_SEAT = "RESERVED_SEAT",
  GENERAL_ADMISSION = "GENERAL_ADMISSION",
  VIP = "VIP",
}

export enum SeatType {
  CONSECUTIVE = "CONSECUTIVE",
  ODDEVEN = "ODDEVEN",
}

export interface EventTicket {
  id: string;
  eventId: string;
  name: string;
  description: string;
  type: EventTicketType | string;
  quantity: number;
  price: number;
  multiplier: number;
  color: string;
  fontColor: string;
  originalPrice: number;
  remainingQuantity: number;
  perTicketFee?: number;
  section: string;
  row: string;
  seatType: SeatType | string;
  event?: Event;
  published?: boolean;
  isMultipleEvent?: boolean;
  isHotDeal?: boolean;
  _count?: {
    tickets: number;
  };
  commissionPercentage: number;
  additionalEventTicketRelations: AdditionalEventTicketRelation[];
  mainEventTicketRelations: AdditionalEventTicketRelation[];
}

export interface EventTicketByEvent {
  id: string;
  claimedAt: string;
  section: string;
  row: string;
  seat: number;
  fullName: string;
  status: string;
  email: string;
  isClaimed: boolean;
}

export interface DeleteEventTicket {
  eventId: string;
  eventTicketId: string;
}
export interface EventTicketRequest extends EventTicket {
  eventId: string;
}
export interface AddEventVoucher {
  voucherId: string;
  eventId: string;
}

export interface AddEventBrand {
  brandId: string;
  eventId: string;
}

export interface DeleteEventVoucher {
  voucherId: string;
  eventId: string;
}

export interface DeleteEventBrand {
  brandId: string;
  eventId: string;
}

export interface AdditionalEventTicketRelation {
  additionalEventTicketRelations: string;
  id: string;
  mainEventTicketId: string;
  additionalEventTicketId: string;
  additionalEventTicket: EventTicket;
  mainEventTicket: EventTicket;
}
