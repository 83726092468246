import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import ReactSelect from "react-select";
import { OptionValue } from "../../../../components/common/TNSelect";
import { useGetEventsQuery } from "../../../../services/event.api";

interface ComponentProps {
  className?: string;
  setEventsId: React.Dispatch<React.SetStateAction<string[]>>;
}

type TSelectOption = {
  value: string;
  label: string;
};

const SelectMultipleEvents: React.FC<ComponentProps> = ({
  setEventsId,
  className,
}) => {
  const [optionSelected, setOptionSelected] = React.useState<TSelectOption[]>(
    []
  );
  const { data } = useGetEventsQuery({});

  const options: TSelectOption[] = data
    ? data.data.map((event) => ({
        label: event.name,
        value: event.id,
      }))
    : [{ label: "", value: "" }];

  React.useEffect(() => {
    if (optionSelected !== undefined) {
      const eventIds = optionSelected.map((data: OptionValue) => data.value);
      setEventsId(eventIds);
    }
  }, [optionSelected, setEventsId]);

  return (
    <div className={`${className}`}>
      <div>
        <Field name="eventIds">
          {({ field, form }: FieldProps) => {
            return (
              <ReactSelect
                id="eventIds"
                isMulti
                name="eventIds"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={optionSelected}
                options={options}
                onChange={(option: any) => setOptionSelected(option)}
                styles={{
                  valueContainer: (base) => ({
                    ...base,
                    overflowX: "scroll",
                    flexWrap: "unset",
                  }),
                  multiValue: (base) => ({
                    ...base,
                    flex: "1 0 auto",
                  }),
                }}
              />
            );
          }}
        </Field>
        <ErrorMessage name="eventIds">
          {(message) => <span className="text-red-500">{message}</span>}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default SelectMultipleEvents;
