import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Brand, CreateBrand, UpdateBrand } from "../models/brand.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Brands {
  data: Brand[];
  skip: number;
  take: number;
  count: number;
}

interface BrandsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const brandApi = createApi({
  reducerPath: "brandApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/brands",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Brands", "Brand"],
  endpoints: (builder) => ({
    createBrand: builder.mutation<Brand, CreateBrand>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),
    getBrands: builder.query<Brands, BrandsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Brands", "Brand"],
    }),
    getBrand: builder.query<Brand, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Brand"],
    }),
    updateBrand: builder.mutation<Brand, UpdateBrand>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),
    uploadBrandImage: builder.mutation<Brand, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Brands", "Brand"],
    }),

    deleteBrand: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),

    removeBrandPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brands", "Brand"],
    }),
  }),
});

export const {
  useCreateBrandMutation,
  useGetBrandQuery,
  useGetBrandsQuery,
  useUpdateBrandMutation,
  useUploadBrandImageMutation,
  useDeleteBrandMutation,
  useRemoveBrandPhotoMutation,
} = brandApi;
