import dayjs from "dayjs";
import React from "react";
import { Button, PaginationContainer } from "../../../components/common";
import { Modal } from "../../../components/common/Modals/Modal";
import { EventTicketByEvent } from "../../../models";
import { useGetEventTicketByEventQuery } from "../../../services/event-ticket.api";
import { useUpdateTicketStatusMutation } from "../../../services/ticket.api";

interface ComponentProps {
  search?: string;
  eventId?: string;
}

const EventTicketList: React.FC<ComponentProps> = ({ search, eventId }) => {
  const [open, setOpen] = React.useState(false);
  const [ticket, setTicket] = React.useState<{
    ticketId?: string;
    isClaimed?: boolean;
    status?: string;
  }>();
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);

  const [
    update,
    { isLoading: ticketUpdateIsLoading, isError, error, isSuccess },
  ] = useUpdateTicketStatusMutation();

  const { data, isLoading } = useGetEventTicketByEventQuery({
    skip,
    take,
    search: search || "",
    eventId,
  });

  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    if (isSuccess && !ticketUpdateIsLoading) {
      setOpen(false);
    }

    return () => {
      setTicket(undefined);
    };
  }, [isSuccess, ticketUpdateIsLoading, setOpen]);

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div>
        <ul className="grid grid-cols-7 p-4 font-semibold">
          <li>ID</li>
          <li>Full Name</li>
          <li>Email</li>
          <li>Claimed At</li>
          <li>
            <ul className="grid grid-cols-3">
              <li className="mr-4">Row</li>
              <li className="mr-4">Seat</li>
              <li>Section</li>
            </ul>
          </li>
          <li>Status</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.currentCount}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((event: EventTicketByEvent, index: number) => {
              return (
                <ul
                  className="grid items-center grid-cols-7 p-4 border rounded shadow-sm"
                  key={index}
                >
                  <li>{event.id}</li>
                  <li>{event.fullName}</li>
                  <li>{event.email}</li>
                  <li>
                    {event.claimedAt
                      ? dayjs(event.claimedAt).format("MM-DD-YY hh:mmA")
                      : "Not Yet Claimed"}
                  </li>
                  <li>
                    <ul className="grid grid-cols-3">
                      <li className="mr-4">{event.row}</li>
                      <li className="mr-4">{event.seat}</li>
                      <li>{event.section}</li>
                    </ul>
                  </li>
                  <li className="text-white">
                    {event.status.toLowerCase() !== "available" ? (
                      <span className="px-4 py-2 bg-green-400 rounded">
                        Claimed
                      </span>
                    ) : (
                      <span className="px-4 py-2 bg-yellow-300 rounded">
                        Available
                      </span>
                    )}
                  </li>

                  <li>
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setTicket({
                          ticketId: event.id,
                          status: event.status,
                          isClaimed: !event.isClaimed,
                        });
                      }}
                      title={`Mark as ${
                        event.status.toLowerCase() !== "available"
                          ? "Available"
                          : "Claimed"
                      }`}
                      variant="secondary"
                    />
                  </li>
                </ul>
              );
            })}
          </div>
        </PaginationContainer>
      )}
      <Modal open={open} setOpen={setOpen} title="Update ticket">
        <div>
          <h1>Are you sure to mark this ticket as claimed?</h1>
          <div className="flex justify-end gap-5 mt-10">
            <button
              onClick={() => {
                window.location.reload();
                update({
                  id: ticket?.ticketId || "",
                  isClaimed: ticket?.isClaimed,
                  status:
                    ticket?.status?.toLowerCase() === "available"
                      ? "claimed"
                      : "available",
                });
              }}
              className="px-5 py-2 text-blue-900 border-2 border-blue-900 rounded-lg"
            >
              Yes
            </button>
            <button
              onClick={() => {
                setTicket(undefined);
                setOpen(false);
              }}
              className="px-5 py-2 text-white bg-red-500 border rounded-lg"
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventTicketList;
