import React, { useState } from "react";

export interface TableData {
  id: string;
  [key: string]: any;
}

interface Column {
  key: string;
  label: string;
  collapsible?: boolean;
}

interface Props {
  data: TableData[];
  columns: Column[];
  currentPage?: number;
  itemsPerPage?: number;
  total?: number;
  setCurrentPage?: (pageNumber: number) => void;
  actionComponent?: React.ComponentType<{ id: string, identifier: string }>;
  isCollapsible?: boolean;
  active?: string;
  children?: React.ComponentType<{ id: string }>;
}

const DynamicTable: React.FC<Props> = ({
  data,
  columns,
  currentPage,
  itemsPerPage = 0,
  setCurrentPage,
  total = 0,
  actionComponent: Component,
  isCollapsible,
  active,
  children: Children,
}) => {
  const paginate = (pageNumber: number) => {
    if (setCurrentPage) setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full overflow-x-auto ">
      <table className="min-w-full border-separate border-spacing-y-2">
        <thead>
          <tr>
            {Component && (
              <TableHead className="w-56 border-r-2 border-black rounded-l-xl border-opacity-20">
                Actions
              </TableHead>
            )}
            {columns.map((col, index) => (
              <TableHead
                className={`${
                  columns.length === index + 1
                    ? "rounded-r-xl"
                    : "border-r-2 border-opacity-20 border-black "
                }`}
                key={col.key}
              >
                {col.label}
              </TableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="h-1"></td>
          </tr>
        </tbody>
        <tbody className="text-sm">
          {data && data.length > 0 ? (
            data.map((row, index) => (
              <>
                <tr key={index}>
                  {Component && (
                    <TableData className="rounded-l-xl">
                      <Component id={row["id"]} identifier={row["identifier"]}/>
                    </TableData>
                  )}
                  {columns.map((col, index) => (
                    <TableData
                      className={`${
                        columns.length === index + 1 && "rounded-r-xl"
                      }`}
                      key={col.key}
                    >
                      {typeof row[col.key] === "object"
                        ? row[col.key]
                        : row[col.key].toString()}
                    </TableData>
                  ))}
                </tr>
                {isCollapsible && Children && active === row.id && (
                  <tr key={row.id}>
                    <td colSpan={columns.length + 1}>
                      <div className="p-2 text-sm text-gray-700 break-words">
                        <Children id={row.id} />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))
          ) : (
            <tr>
              <td>No data found</td>
            </tr>
          )}
        </tbody>
      </table>

      {itemsPerPage > 0 && setCurrentPage && currentPage && (
        <div className="flex justify-center mt-5">
          <ul className="flex">
            {currentPage > 1 && (
              <li key="prev" className="page-item">
                <button
                  className="w-8 h-8 mx-2 rounded-full"
                  onClick={() => paginate(currentPage - 1)}
                >
                  &lsaquo;
                </button>
              </li>
            )}
            {[...Array(Math.ceil(total / itemsPerPage))].map((_, i) => {
              if (
                i + 1 === currentPage ||
                (currentPage - i <= 5 && i + 1 - currentPage <= 5)
              ) {
                return (
                  <li key={i} className="page-item">
                    <button
                      className={`mx-2 w-8 h-8 rounded-full ${
                        currentPage === i + 1 ? "bg-gray-800 text-white " : ""
                      }`}
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                );
              }
            })}
            {currentPage < Math.ceil(total / itemsPerPage) && (
              <li key="next" className="page-item">
                <button
                  className="w-8 h-8 mx-2 rounded-full"
                  onClick={() => paginate(currentPage + 1)}
                >
                  &rsaquo;
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

interface TableHeadProps {
  children?: React.ReactNode;
  className?: string;
}
const TableHead: React.FC<TableHeadProps> = ({ children, className }) => {
  return <th className={`p-4 bg-white ${className}`}>{children}</th>;
};

interface TableDataProps {
  className?: string;
  children: React.ReactNode;
}
const TableData: React.FC<TableDataProps> = ({ children, className }) => {
  return (
    <td className={`p-2 text-center bg-white ${className}`}>{children}</td>
  );
};

export default DynamicTable;
