import React from "react";
import { UserRole } from "../../../models/user-role.model";
import { useDeleteUserRoleByIdMutation } from "../../../services/user-role.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  role: UserRole;
}
export const UserRoleList: React.FC<ComponentProps> = ({ role }) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [userRole, setUserRole] = React.useState(role);

  const [deleteUserRole] = useDeleteUserRoleByIdMutation();

  return (
    <>
      {role && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete ${userRole.role?.name} Role`}
          size="s"
          action={deleteUserRole}
          item={userRole.id}
        />
      )}
      <div className="flex gap-3 p-4 rounded-2xl bg-blue-900 text-white w-fit">
        <p>{role.role?.name}</p>
        {role.role?.name !== "USER" && (
          <button
            className="cursor-pointer"
            onClick={() => {
              setOpenDelete(true);
              setUserRole(role);
            }}
          >
            X
          </button>
        )}
      </div>
    </>
  );
};
