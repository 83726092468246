import React from "react";
import { useMediaQuery } from "react-responsive";
import { Button } from "../../../../components/common";
import { Event } from "../../../../models/event.model";
import {
  CustomError,
  useDeleteEventTicketMutation,
} from "../../../../services/event.api";
import { EventTicket } from "../../../../models/event-ticket.model";
import { AddEventTicketModal } from "./AddEventTicketModal";
import { UpdateEventTicketModal } from "./UpdateEventTicketModal";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";
import { ScannedTotal } from "./ScannedTotal";
import EventTicketConversations from "./EventTicketConversation";
import { EventTicketInclusionModal } from "./EventTicketInclusionModal";
import {
  useLazyGetEventTicketOrdersQuery,
} from "../../../../services/event-ticket.api";

interface ComponentProps {
  event: Event;
}

interface FormValues {
  eventId: string;
  name: string;
  description: string;
  type: string;
  quantity: string;
  price: string;
  section: string;
  row: string;
  seatType: string;
}

export const EventTickets: React.FC<ComponentProps> = ({ event }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [active, setActive] = React.useState("");
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventTicket, setEventTicket] = React.useState<EventTicket>();

  const [eventTicketOrders] = useLazyGetEventTicketOrdersQuery();

  const [
    deleteEventTicket,
    {
      isError: deleteEventTicketIsError,
      error: deleteEventTicketError,
      isLoading: deleteEventTicketIsLoading,
    },
  ] = useDeleteEventTicketMutation();

  if (deleteEventTicketIsLoading) <h2>Loading...</h2>;

  return (
    <>
      <div className="p-1 border rounded shadow-sm lg:p-4">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Tickets</h2>
          <Button title="Add Ticket" onClick={() => setOpenAddModal(true)} />
        </div>
        <div className="">
          {isDesktopOrLaptop && (
            <ul className="grid justify-end grid-cols-11 mb-4 text-lg font-semibold">
              <li>ID</li>
              <li>Name</li>
              <li>Description</li>
              <li>Type</li>
              <li>Quantity</li>
              <li>Sold</li>
              <li>Scanned</li>
              <li>Remaining</li>
              <li>Price</li>
              <li>Status</li>
              <li className="flex justify-end ">Action</li>
            </ul>
          )}

          <div>
            {event.eventTickets.map((eventTicket: EventTicket) => (
              <div className="collapse collapse-plus">
                <input type="checkbox" />
                <div className="text-xl font-medium collapse-title" />
                <div key={eventTicket.id}>
                  <ul className="grid grid-cols-2 py-1 mb-2 text-xs border-b lg:justify-end lg:grid-cols-11 lg:text-md">
                    <li className="font-semibold lg:hidden">ID</li>
                    <li>
                      <button
                        className="px-2 py-1 border rounded-md"
                        onClick={() =>
                          navigator.clipboard.writeText(eventTicket.id || "")
                        }
                      >
                        COPY ID
                      </button>
                    </li>
                    <li className="font-semibold lg:hidden">NAME</li>
                    <li>{eventTicket.name}</li>
                    <li className="font-semibold lg:hidden">DESCRIPTION</li>
                    <li>{eventTicket.description}</li>
                    <li className="font-semibold lg:hidden">TYPE</li>
                    <li>{eventTicket.type}</li>
                    <li className="font-semibold lg:hidden">QUANTITY</li>
                    <li>{eventTicket.quantity}</li>
                    <li className="font-semibold lg:hidden">SOLD</li>
                    <li>{eventTicket._count?.tickets || 0}</li>
                    <li className="font-semibold lg:hidden">SCANNED</li>
                    <li>{0}</li>
                    <li className="font-semibold lg:hidden">
                      REMAINING QUANTITY
                    </li>
                    <li>{eventTicket.remainingQuantity || 0}</li>
                    {/* {eventTicket.id && (
                      <ScannedTotal eventTicketId={eventTicket.id} />
                    )} */}
                    <li className="font-semibold lg:hidden">PRICE</li>
                    <li>{eventTicket.price}</li>
                    <li className="font-semibold lg:hidden">STATUS</li>
                    <li>
                      {eventTicket.published ? (
                        <span className="px-2 py-1 text-white bg-green-500 rounded-md">
                          Active
                        </span>
                      ) : (
                        <span className="px-2 py-1 text-white bg-red-500 rounded-md">
                          Inactive
                        </span>
                      )}
                    </li>
                    <li className="flex lg:justify-end ">
                      <Button
                        title="Order"
                        variant="secondary"
                        onClick={() => eventTicketOrders(eventTicket.id)}
                        className="mr-2"
                      />
                      <EventTicketInclusionModal eventTicket={eventTicket} />
                      <Button
                        title="Edit"
                        variant="secondary"
                        onClick={() => setOpenUpdateModal(eventTicket.id || "")}
                        className="mr-2"
                      />
                      <Button
                        title="Delete"
                        variant="warning"
                        onClick={() => {
                          setEventTicket(eventTicket);
                          setOpenDelete(true);
                        }}
                      />
                    </li>
                  </ul>
                  <UpdateEventTicketModal
                    open={openUpdateModal}
                    setOpen={setOpenUpdateModal}
                    eventId={event.id}
                    eventTicket={eventTicket}
                  />
                </div>
                <div className="collapse-content">
                  {active === eventTicket.id ? (
                    <EventTicketConversations eventTicketId={eventTicket.id} />
                  ) : (
                    <button
                      className="px-3 py-2 text-white bg-blue-400"
                      onClick={() => setActive(eventTicket.id)}
                    >
                      View Conversations
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <AddEventTicketModal
          open={openAddModal}
          setOpen={setOpenAddModal}
          eventId={event.id}
        />
      </div>
      {eventTicket && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Ticket`}
          size="s"
          action={deleteEventTicket}
          item={{ eventId: event.id, eventTicketId: eventTicket.id || "" }}
        />
      )}
    </>
  );
};

export default EventTickets;
