import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { Input, Label } from "../../../components/common";
import { CustomError, useSignupMutation } from "../../../services/auth.api";

interface FormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

const SignupForm = () => {
  const [signup, { error, isError, isSuccess, isLoading }] =
    useSignupMutation();
  const initialValues: FormValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
  };
  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    signup(values);
  };

  React.useEffect(() => {
    if (isSuccess) alert("success");
  }, [isSuccess]);

  if (isLoading) <h2>Loading...</h2>;

  return (
    <Formik
      initialValues={initialValues}
      validate={() => {}}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="flex items-center justify-center col-span-2 p-10 lg:col-span-1">
            <div className="grid flex-1 gap-y-2">
              <div className="mb-10">
                <h2 className="text-4xl font-bold">Sign Up</h2>
              </div>
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-3">
                <label htmlFor="email">Email</label>
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Email" id="email" {...field} />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-3">
                <Label htmlFor="password">Passsword</Label>
                <Field name="password">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Password"
                      type="password"
                      id="password"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-3">
                <Label htmlFor="firstName">First Name</Label>
                <Field name="firstName">
                  {({ field }: FieldProps) => (
                    <Input placeholder="First Name" id="firstName" {...field} />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-3">
                <Label htmlFor="lasttName">Last Name</Label>
                <Field name="lastName">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Last Name" id="lasttName" {...field} />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-3">
                <Label htmlFor="mobileNumber">Mobile Number</Label>
                <Field name="mobileNumber">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Mobile Number"
                      id="mobileNumber"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Sign up
              </button>
              <div>
                <p>
                  <span className="mr-2"> Already have an account?</span>
                  <Link to="/signin">
                    <span className="text-blue-500">Click here Sign in</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
