import React from "react";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { SocialMedia } from "../../../models/social-media.model";
import { useDeleteSocialMediaByIdMutation } from "../../../services/social-media.api";

interface ComponentProps {
  socMed: SocialMedia;
}
export const SocMedList: React.FC<ComponentProps> = ({ socMed }) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [socialMedia, setSocialMedia] = React.useState(socMed);
  const [deleteSocialMedia] = useDeleteSocialMediaByIdMutation();

  return (
    <>
      {socialMedia && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete ${socialMedia.name}`}
          size="s"
          action={deleteSocialMedia}
          item={socialMedia.id}
        />
      )}
      <div className="flex gap-3 p-4 rounded-2xl bg-blue-900 text-white w-full justify-center">
        <p>{socialMedia?.name}</p>
        <button
          className="cursor-pointer"
          onClick={() => {
            setOpenDelete(true);
            setSocialMedia(socialMedia);
          }}
        >
          X
        </button>
      </div>
    </>
  );
};
