import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import EventSelect from "../../../Event/EventSelect";
import { Event } from "../../../../models/event.model";

interface FormValues {
  event: string;
}

interface ComponentProps {
  setEvent: React.Dispatch<React.SetStateAction<Event | undefined>>;
  setUsersEmail: React.Dispatch<React.SetStateAction<string[]>>;
  event: Event | undefined;
}

const SelectEvent: React.FC<ComponentProps> = ({
  setEvent,
  event,
  setUsersEmail,
}) => {
  const validationSchema = Yup.object().shape({
    event: Yup.string().required("Event is a required field"),
  });

  const initialValues: FormValues = {
    event: "",
  };

  const handleSelectEvent = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setEvent(JSON.parse(values.event));
    setUsersEmail([]);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSelectEvent}
    >
      {() => (
        <Form>
          <EventSelect isSelectEvent />
          <button
            type="submit"
            className="px-4 py-2 font-bold w-full text-white mt-5 bg-darkBlue-700 border rounded"
          >
            {!event ? "Select Event" : "Change Event"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SelectEvent;
