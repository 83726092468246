import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../components/common";
import { Carousel } from "../../../models/carousel.model";
import {
  useDeleteCarouselMutation,
  useGetCarouselsQuery,
} from "../../../services/carousel.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { UpdateCarousel } from "../UpdateCarousel";
import { Filter, FilterField } from "../../../components/common/Filter";

interface ComponentProps {
  search?: string;
}
interface Filter {
  [key: string]: any;
}

export const CarouselList: React.FC<ComponentProps> = ({ search }) => {
  const [filter, setFilter] = React.useState<Filter>({});
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [carousel, setCarousel] = React.useState<Carousel>();
  const { data, isLoading } = useGetCarouselsQuery({
    skip,
    take,
    search: search || "",
    ...filter,
  });
  const [deleteCarousel] = useDeleteCarouselMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  const fields: FilterField[] = [
    {
      type: "select",
      label: "status",
      options: [
        { label: "Active", value: "true" },
        { label: "InActive", value: "false" },
      ],
    },
  ];

  if (isLoading) return <h2>Loading...</h2>;
  console.log("filter", filter);
  return (
    <div>
      <div>
        <Filter fields={fields} filter={filter} setFilter={setFilter} />
        <ul className="grid grid-cols-5 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Title</li>
          <li>Description</li>
          <li>Position</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((carousel: Carousel, index: number) => (
              <ul
                className="grid items-center grid-cols-5 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                    <li className="mr-4">
                      <button
                        className="px-2 py-1 border rounded-md"
                        onClick={() =>
                          navigator.clipboard.writeText(carousel.id || "")
                        }
                      >
                        COPY ID
                      </button>
                    </li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {carousel && carousel.image ? (
                          <img
                            alt={`${carousel.title}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              carousel.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{carousel.title}</li>
                <li>{carousel.description}</li>
                <li>{carousel.position}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setCarousel(carousel);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setCarousel(carousel);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {carousel && (
        <Modal open={open} setOpen={setOpen} title="Update Carousel" size="m">
          <UpdateCarousel setOpen={setOpen} carouselId={carousel.id} />
        </Modal>
      )}

      {carousel && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Carousel`}
          size="s"
          action={deleteCarousel}
          item={carousel.id}
        />
      )}
    </div>
  );
};
