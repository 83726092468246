import React, { useRef } from "react";
import DynamicForm, {
  FieldDefinition,
} from "../../../../components/common/DynamicForm";
import {
  useGetProductVariantQuery,
  useUpdateProductVariantMutation,
  useUploadProductVariantImageMutation,
  useRemoveProductVariantPhotoMutation,
} from "../../../../services/product-variant.api";
import ImageUploader from "../../../../components/common/ImageUploader";
import ToggleSwitch from "../../../../components/common/ToggleSwitch";

interface ComponentProps {
  productVariantId: string;
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateProductVariant: React.FC<ComponentProps> = ({
  setOpen,
  productVariantId,
  productId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateProductVariantMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadProductVariantImageMutation();

  const { data: productVariant, isLoading: productVariantIsLoading } =
    useGetProductVariantQuery({ id: productVariantId, productId });

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveProductVariantPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (values: any) => {
    if (productVariant)
      update({
        id: productVariant.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: productVariantId,
        productId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = (productVariantImageId: string) => {
    removePhoto({
      productId: productId,
      id: productVariantId,
      productVariantImageId,
    });
  };

  if (isLoading || productVariantIsLoading) return <h2>Loading...</h2>;
  if (!productVariant) return <h2>Something went wrong..</h2>;

  const formFields: FieldDefinition[] = [
    {
      name: "name",
      type: "text",
      label: "Name",
      required: true,
      defaultValue: productVariant.name,
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      defaultValue: productVariant.description,
    },
    {
      name: "slug",
      type: "text",
      label: "Slug",
      defaultValue: productVariant.slug,
    },
    {
      name: "size",
      type: "text",
      label: "Size",
      defaultValue: productVariant.size,
    },
    {
      name: "color",
      type: "text",
      label: "Color",
      defaultValue: productVariant.color,
    },
    {
      name: "weight",
      type: "number",
      label: "Weight",
      defaultValue: productVariant.weight,
    },
    {
      name: "height",
      type: "number",
      label: "Height",
      defaultValue: productVariant.height,
    },
    {
      name: "quantity",
      type: "number",
      label: "Quantity",
      required: true,
      defaultValue: productVariant.quantity,
    },
    {
      name: "price",
      type: "number",
      label: "Price",
      required: true,
      defaultValue: productVariant.price,
    },
    {
      name: "sku",
      type: "text",
      label: "SKU",
      defaultValue: productVariant.sku,
    },
    {
      name: "isPublished",
      type: "toggle",
      label: "Publish",
      defaultValue: productVariant.isPublished,
      customInput: <ToggleSwitch />,
    },
  ];

  return (
    <>
      <ImageUploader
        onFileChange={handleFileChange}
        onBrowseClick={() => handleBrowse()}
        images={
          productVariant.ProductVariantImage?.map((productVariantImage) => {
            return {
              id: productVariantImage.imageId,
              fileName: productVariantImage.image.fileName,
            };
          }) || []
        }
        onRemoveClick={(id) => handlePhotoRemove(id)}
      />

      <DynamicForm fields={formFields} onSubmit={handleSubmit} />
    </>
  );
};
