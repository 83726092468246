import React from "react";
import { useParams } from "react-router-dom";
import UpdateEvent from "../../../features/Event/UpdateEvent";

type UpdateEventParams = {
  eventId: string;
};

export const UpdateEventPage = () => {
  const { eventId } = useParams<UpdateEventParams>() as UpdateEventParams;
  return (
    <div className="flex-grow h-full">
      <h2 className="mb-4 text-2xl font-semibold">Update Event</h2>
      <UpdateEvent eventId={eventId} />
    </div>
  );
};

