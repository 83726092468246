import React from "react";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../../components/common";
import { Event } from "../../../../models/event.model";
import SelectEvent from "../../Components/SelectEvent";
import dayjs from "dayjs";
import CarouselSelect from "../../Components/SelectCarousel";
import SelectUser from "../../Components/SelectUser";
import { Modal } from "../../../../components/common/Modals";
import SelectVoucher from "../../Components/SelectVoucher";
import SelectMultipleEvents from "../../Components/SelectMultipleEvents";
import { Voucher } from "../../../../models/voucher.model";
import EventPromotionEmail from "./EventPromotionEmail";

interface FormValues {
  eventId: string;
  eventName: string;
  eventDate: string;
  eventAddress: string;
  eventDescription: string;
  imageFileName: string;
  eventSlug: string;
  voucherCode?: string;
  discountPercentage?: number;
  userEmails?: string[];
  eventIds?: string[];
  isManyUsers: boolean;
  isExpiredOrders: boolean;
}

const PromotionTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<Event | undefined>();
  const [item, setItem] = React.useState<FormValues>();
  const [values, setValues] = React.useState<FormValues | undefined>();
  const [usersEmail, setUsersEmail] = React.useState<string[]>([]);
  const [eventsId, setEventsId] = React.useState<string[]>([]);
  const [voucher, setVoucher] = React.useState<Voucher>();

  const initialValues: FormValues = {
    eventId: event?.id || "",
    eventName: event?.name || "",
    eventDate: dayjs(event?.dateTime).format("MMMM DD, YYYY - hh:mmA") || "",
    eventAddress: `${event?.venue?.name} - ${event?.venue?.address1}` || "",
    eventDescription: event?.description || "",
    eventSlug: event?.slug || "",
    voucherCode: "",
    discountPercentage: 0,
    imageFileName: event?.image?.fileName || "",
    userEmails: usersEmail || [],
    eventIds: eventsId || [],
    isManyUsers: false,
    isExpiredOrders: false,
  };

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().required("Event ID is required."),
    eventName: Yup.string().required("Event Name is required."),
    eventDate: Yup.string().required("Date is required."),
    eventAddress: Yup.string().required("Event Venue is required."),
    eventDescription: Yup.string().required("Event Description is required."),
    eventSlug: Yup.string().required("Event Slug is required."),
    userEmails: Yup.array(),
    isManyUsers: Yup.boolean(),
    imageFileName: Yup.string().required("Image is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setOpen(true);
    setItem({
      ...values,
    });
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title="Send Email Promotion"
        size="s"
      >
        <EventPromotionEmail setOpen={setOpen} item={item} setItem={setItem} />
      </Modal>
      <div
        className="flex p-3 gap-5 justify-center flex-col fon
      at-roboto items-center lg:items-start lg:flex-row "
      >
        <Formik
          innerRef={(formikActions) =>
            formikActions
              ? setValues(formikActions.values)
              : setValues(undefined)
          }
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <div className="grid p-5 w-[500px] gap-2 border rounded-md">
              <SelectEvent
                setUsersEmail={setUsersEmail}
                event={event}
                setEvent={setEvent}
              />
              {event && (
                <Form>
                  <div className="grid grid-cols-3 items-center gap-6 pt-6 ">
                    <Label htmlFor="eventName">Event Name</Label>
                    <Field name="eventName">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Name"
                          id="eventName"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDate">Date</Label>
                    <Field name="eventDate">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Date"
                          id="eventDate"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventAddress">Address</Label>
                    <Field name="eventAddress">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Address"
                          id="eventAddress"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="voucherCode">Select Voucher</Label>
                    <SelectVoucher
                      eventId={event.id}
                      name="voucherCode"
                      className="col-span-2"
                      setVoucher={setVoucher}
                      selectedVoucherCode={values?.voucherCode}
                    />
                    <Label htmlFor="voucherCode">Voucher Code</Label>
                    <Field name="voucherCode">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Voucher Code"
                          id="voucherCode"
                          disabled
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDescription">Event Description</Label>
                    <Field name="eventDescription">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Description"
                          id="eventDescription"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventSlug">Slug</Label>
                    <Field name="eventSlug">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Slug"
                          id="eventSlug"
                          disabled
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="imageFileName">Change Image</Label>
                    <CarouselSelect
                      name="imageFileName"
                      className="col-span-2"
                    />
                    <Label htmlFor="eventIds">
                      Select Event for ticket buyers targeting
                    </Label>
                    <SelectMultipleEvents
                      className="col-span-2"
                      setEventsId={setEventsId}
                    />
                    {!values?.isExpiredOrders && (
                      <>
                        <Label htmlFor="isManyUsers">Mupltiple Users</Label>
                        <Field name="isManyUsers">
                          {({ field }: FieldProps) => (
                            <Checkbox
                              className="col-span-2"
                              id="isManyUsers"
                              {...field}
                            />
                          )}
                        </Field>
                      </>
                    )}
                    {!values?.isManyUsers && (
                      <>
                        <Label htmlFor="isExpiredOrders">Expired Orders</Label>
                        <Field name="isExpiredOrders">
                          {({ field }: FieldProps) => (
                            <Checkbox
                              className="col-span-2"
                              id="isExpiredOrders"
                              {...field}
                            />
                          )}
                        </Field>
                      </>
                    )}
                    {!values?.isManyUsers &&
                      !values?.isExpiredOrders &&
                      eventsId.length !== 0 && (
                        <>
                          <Label htmlFor="userEmails">Add Users</Label>
                          <SelectUser
                            className="col-span-2"
                            setUsersEmail={setUsersEmail}
                            eventId={event.id}
                            eventIds={eventsId}
                            emailType="PROMOTION"
                          />
                        </>
                      )}

                    <div className="col-span-3">
                      <button
                        type="submit"
                        className="w-full px-4 py-2 font-bold text-white bg-darkBlue-700 border rounded"
                      >
                        Send Event Promotion Email
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </Formik>
        <div className="w-[500px] h-fit border rounded-md font-roboto tracking-wider">
          <div className="p-6 bg-lightBlue-300 font-semibold rounded-t-md text-center text-white">
            <div className="flex flex-col text-center items-center justify-center pt-5">
              <a
                className="mb-4"
                target="_blank"
                href="https://www.ticketnation.ph/"
                rel="noreferrer"
              >
                <img
                  className="w-36"
                  alt={`tnlogowhite`}
                  src={process.env.REACT_APP_IMAGE_BASE_URL + "tnlogowhite.png"}
                />
              </a>
              <div>
                <p className="text-2xl">Check out this Upcoming Event!</p>
                {voucher && (
                  <p className="text-lg">
                    Use this voucher for a {voucher.discountPercentage}%
                    discount
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-fit">
            <div className="flex items-center justify-center overflow-hidden w-full h-full bg-black object-cover">
              {values?.imageFileName && values.eventName && (
                <img
                  alt={`${values.eventName}_image`}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}${values.imageFileName}`}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col border-t py-4 gap-1">
              <p className="text-lg font-bold">
                {values?.eventName || "Event Name"}
              </p>
              <p className="text-sm">{values?.eventDate || "Event Date"}</p>
              <p className="text-sm">
                {values?.eventAddress || "Event Address"}
              </p>
              {values?.voucherCode !== "" && (
                <p className="text-sm">
                  Code:{" "}
                  <span className="text-darkBlue-700 text-base font-bold">
                    {values?.voucherCode || "Voucher Code"}
                  </span>
                </p>
              )}
              {values && (
                <a
                  target="_blank"
                  href={`https://www.ticketnation.ph/event/${
                    voucher
                      ? values.eventSlug + "?referral=" + voucher.code
                      : values.eventSlug
                  }`}
                  rel="noreferrer"
                >
                  <div className="p-2 text-sm rounded-md bg-darkBlue-700 text-center font-semibold text-white">
                    VIEW TICKETS
                  </div>
                </a>
              )}
            </div>
            <div className="flex flex-col border-t py-4 gap-1">
              <p className="text-lg font-bold">Get your Tickets Now!</p>
              <p className="text-sm pb-3">
                {values?.eventDescription || "Event Description"}
              </p>
              <p className="text-xs text-end">
                © 2022 Ticketnation Philippines
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionTemplate;
