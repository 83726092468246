import React, { useRef } from "react";
import {
  useGetEventGroupQuery,
  useRemoveEventGroupPhotoMutation,
  useUploadEventGroupImageMutation,
} from "../../../services/event-group.api";
import EventGroupEventOrders from "./EventGroupEventOrders";

interface ComponentProps {
  eventGroupId: string;
}

const ViewEventGroup: React.FC<ComponentProps> = ({ eventGroupId }) => {
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadEventGroupImageMutation();

  const { data: eventGroup, isLoading: eventGroupIsLoading } =
    useGetEventGroupQuery(eventGroupId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveEventGroupPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: eventGroupId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(eventGroupId);
  };

  if (eventGroupIsLoading || uploadIsLoading || removePhotoIsLoading)
    return <h2>Loading...</h2>;
  if (!eventGroup) return <h2>Something went wrong..</h2>;

  return (
    <>
      <h2 className="mb-4 text-2xl font-semibold">
        {eventGroup ? eventGroup.name : "View Event Group"}
      </h2>
      <div className="grid gap-2 p-4 mb-2 border rounded shadow-sm">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
            {eventGroup && eventGroup.image ? (
              <img
                alt={`${eventGroup.name}_image`}
                src={
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  eventGroup.image?.fileName
                }
              />
            ) : (
              <div className="bg-black " />
            )}
          </div>
          <div className="grid grid-cols-3 gap-1">
            <input
              name="upload"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              ref={ref}
            />
            <button
              className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
              type="button"
              onClick={() => handleBrowse()}
            >
              Update Photo
            </button>
            <button
              className="px-4 py-2 text-white bg-red-500 border rounded"
              type="button"
              onClick={() => handlePhotoRemove()}
            >
              Remove Photo
            </button>
            <p className="col-span-2 text-xs">
              Image should be at least 400 x 400px as a png or jpeg file.
            </p>
          </div>
        </div>
      </div>
      <div className="grid gap-2">
        <EventGroupEventOrders eventGroup={eventGroup} />
      </div>
    </>
  );
};

export default ViewEventGroup;
