import React from "react";
import DynamicForm, {
  FieldDefinition,
} from "../../../../components/common/DynamicForm";
import SelectProductCategory from "../../../Product/ProductCategory/SelectProductCategory";
import { useCreateEventProductMutation } from "../../../../services/event.api";
import { Event } from "../../../../models/event.model";

interface ComponentProps {
  event: Event;
  setOpen: (open: boolean) => void;
}
const CreateEventProduct: React.FC<ComponentProps> = ({ event, setOpen }) => {
  const [create] = useCreateEventProductMutation();
  const formFields: FieldDefinition[] = [
    {
      name: "name",
      label: "Name",
      type: "text",
    },
    {
      name: "slug",
      label: "Slug",
      type: "text",
    },
    {
      name: "commissionPercentage",
      label: "Commission Percentage",
      type: "number",
    },
    {
      name: "productCategoryId",
      label: "Product Category",
      type: "text",
      customInput: <SelectProductCategory />,
    },
  ];

  const handleSubmit = (values: any) => {
    create({
      eventId: event.id,
      ...values,
    });
    setOpen(false);
  };

  return (
    <div>
      <DynamicForm fields={formFields} onSubmit={handleSubmit} />
    </div>
  );
};

export default CreateEventProduct;
