import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  EmailMarketing,
  EmailMarketingQuery,
  SendEventAnnouncementEmail,
  SendEventPresaleEmail,
  SendEventPromotionEmail,
  SendEventReminderEmail,
  SendUpcomingEventsEmail,
} from "../models/email-marketing.model";

interface EmailMarketings {
  data: EmailMarketing[];
  skip: number;
  take: number;
  count: number;
}

export interface CustomError {
  data: {
    message: [string];
  };
}

export const emailMarketingApi = createApi({
  reducerPath: "emailMarketingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/email-marketing",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["EmailMarketing"],
  endpoints: (builder) => ({
    getAllEmailMarketing: builder.query<EmailMarketings, EmailMarketingQuery>({
      query: ({ ...rest }) => {
        return {
          url: "/",
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["EmailMarketing"],
    }),
    sendEventPresaleEmail: builder.mutation<void, SendEventPresaleEmail>({
      query: ({ eventId, take, skip, ...body }) => ({
        url: `/presale/${eventId}`,
        method: "POST",
        params: {
          skip,
          take,
        },
        body,
      }),
      invalidatesTags: ["EmailMarketing"],
    }),
    sendEventPromotionEmail: builder.mutation<void, SendEventPromotionEmail>({
      query: ({ eventId, ...body }) => ({
        url: `/promotion/${eventId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmailMarketing"],
    }),
    sendUpcomingEventsEmail: builder.mutation<void, SendUpcomingEventsEmail>({
      query: (body) => ({
        url: `/upcoming-events`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmailMarketing"],
    }),
    sendEventReminderEmail: builder.mutation<void, SendEventReminderEmail>({
      query: ({ eventId, take, skip, ...body }) => ({
        url: `/reminder/${eventId}`,
        method: "POST",
        params: {
          skip,
          take,
        },
        body,
      }),
      invalidatesTags: ["EmailMarketing"],
    }),
    sendEventAnnouncementEmail: builder.mutation<
      void,
      SendEventAnnouncementEmail
    >({
      query: ({ eventId, ...body }) => ({
        url: `/event-announcement/${eventId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["EmailMarketing"],
    }),
  }),
});

export const {
  useGetAllEmailMarketingQuery,
  useSendEventPresaleEmailMutation,
  useSendEventPromotionEmailMutation,
  useSendUpcomingEventsEmailMutation,
  useSendEventReminderEmailMutation,
  useSendEventAnnouncementEmailMutation,
} = emailMarketingApi;
