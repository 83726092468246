import React from "react";
import { Modal } from "../../../../components/common/Modals/Modal";
import { useAddEventTicketMutation } from "../../../../services/event.api";
import { EventTicketForm } from "./EventTicketForm";
interface ComponentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventId: string;
}
export const AddEventTicketModal: React.FC<ComponentProps> = ({
  eventId,
  open,
  setOpen,
}) => {
  const [addEventTicket, { isError, error, isLoading, isSuccess }] =
    useAddEventTicketMutation();
  React.useEffect(() => {
    if (isSuccess) setOpen(false);
  }, [isSuccess, setOpen]);
  return (
    <Modal open={open} setOpen={setOpen} title="Add Ticket" size="m">
      <EventTicketForm
        eventId={eventId}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        onSubmit={addEventTicket}
        error={error}
      />
    </Modal>
  );
};
