import React from "react";
import { useGetConversationQuery } from "../../services/conversation.api";
import ConversationParticipants from "./ConversationParticipants";

interface ConversationProps {
  conversationId: string;
}
const Conversation: React.FC<ConversationProps> = ({ conversationId }) => {
  const {data, isLoading} = useGetConversationQuery({ id: conversationId });
  if (isLoading) return <h2>Loading...</h2>;
  return <div className="max-w-4xl px-4 mx-auto my-4">
  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg font-medium leading-6 text-gray-900">Conversation Details</h3>
      <p className="max-w-2xl mt-1 text-sm text-gray-500">View and manage conversation details.</p>
    </div>
    <div className="border-t border-gray-200">
      <dl>
        <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Conversation Name</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.name}</dd>
        </div>
        <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Description</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data?.description}.</dd>
        </div>
      </dl>
    </div>
  </div>
  <ConversationParticipants conversationId={conversationId}/>
</div>;
};

export default Conversation;
