import React from "react";
import { useSendEventAnnouncementEmailMutation } from "../../../../services/email-marketing.api";
import {
  CustomError,
  SendEventAnnouncementEmail,
} from "../../../../models/email-marketing.model";
import {
  AnimatedLoading,
  AnimatedSuccess,
} from "../../../Event/UpdateEvent/ResendEmailModal";
import { Button } from "../../../../components/common";

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: SendEventAnnouncementEmail | undefined;
  setItem?: React.Dispatch<React.SetStateAction<any>>;
}

const EventAnnouncementEmail: React.FC<ComponentProps> = ({
  setOpen,
  item,
  setItem,
}) => {
  const [sendEventAnnouncementEmail, { isLoading, isError, error, isSuccess }] =
    useSendEventAnnouncementEmailMutation();

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false);
        setItem && setItem("");
      }, 1500);
    }

    return () => {
      setItem && setItem("");
    };
  }, [isSuccess, setOpen, setItem]);

  return (
    <div className="grid gap-6 justify-center p-8">
      {isLoading && !isSuccess && <AnimatedLoading />}
      {isSuccess && !isLoading && <AnimatedSuccess />}
      {isError && error && (
        <div className="text-red-500">
          <h3 className="font-semibold">Error(s)</h3>
          <ul>
            <li>{(error as CustomError).data.message}</li>
          </ul>
        </div>
      )}
      {!isSuccess && !isLoading && item && (
        <div>
          <span> Are you sure you want to Send Email Announcement? </span>
          <div className="flex justify-center gap-5">
            <Button
              title="Proceed"
              variant="secondary"
              onClick={() => {
                sendEventAnnouncementEmail(item);
              }}
            />
            <Button
              title="Cancel"
              variant="warning"
              onClick={() => {
                setOpen(false);
                setItem && setItem("");
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventAnnouncementEmail;
