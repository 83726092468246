import React from "react";
import { useParams } from "react-router-dom";
import UpdateGroup from "../../features/Group/UpdateGroup";

type UpdatePerformerParams = {
  id: string;
};

export const UpdateGroupPage = () => {
  const { id } = useParams<UpdatePerformerParams>() as UpdatePerformerParams;
  return (
    <div>
      <UpdateGroup groupId={id} />
    </div>
  );
};
