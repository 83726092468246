import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import { Button } from "../../../components/common";
import Dropdown from "../../../components/common/Dropdown";
import { Voucher } from "../../../models/voucher.model";
import { useGetEventVoucherByEventQuery } from "../../../services/event-voucher.api";
import { useSendOrderEmailMarketingMutation } from "../../../services/order.api";
import { VoucherSelect } from "../../Voucher";
import "./SendEmailMarketingModal.css";

const AnimatedSuccess = () => {
  return (
    <div className="wrapper">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

const AnimatedLoading = () => {
  return (
    <div className="flex justify-center">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

interface ComponentProps {
  eventId: string;
  sendEmailId: string;
  setSendEmailId: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendEmailMaketingModal: React.FC<ComponentProps> = ({
  eventId,
  sendEmailId,
  setSendEmailId,
  setOpen,
}) => {
  const [sendOrderEmailMarketing, { isLoading, isSuccess }] =
    useSendOrderEmailMarketingMutation();
  const [voucherId, setVoucherId] = React.useState<string>("");

  const { data: eventVouchers, isLoading: eventVoucherIsLoading } =
    useGetEventVoucherByEventQuery(eventId);

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false);
      }, 1500);
    }

    return () => {
      setSendEmailId("");
    };
  }, [isSuccess, setOpen, setSendEmailId]);

  return (
    <div className="grid justify-center gap-5">
      {isLoading && !isSuccess && <AnimatedLoading />}
      {isSuccess && !isLoading && <AnimatedSuccess />}
      {!isSuccess && !isLoading && (
        <>
          <div className="flex items-center gap-2">
            <label className="mr-2 font-semibold">Event Voucher</label>
            <Dropdown
              onChange={(value) => setVoucherId(value)}
              iconPosition="right"
              icon={<FontAwesomeIcon icon={faAngleDown} />}
              setValue={setVoucherId}
              placeholder={
                voucherId
                  ? eventVouchers?.find(
                      ({ voucher }) => voucher.id === voucherId
                    )?.voucher.name
                  : eventVouchers?.length === 0
                  ? "No Available Voucher"
                  : "Select Event Voucher"
              }
              options={
                eventVouchers?.map(({ voucher }) => ({
                  label: voucher.name,
                  value: voucher.id,
                })) || []
              }
            />
          </div>
          <div className="text-center">
            <p>Are you sure you want to resend this ticket?</p>
            <div className="flex justify-center gap-5 mt-5">
              <Button
                title="Proceed"
                variant="secondary"
                onClick={() => {
                  if (voucherId) {
                    sendOrderEmailMarketing({ sendEmailId, voucherId });
                  } else {
                    sendOrderEmailMarketing({ sendEmailId });
                  }
                }}
              />
              <Button
                title="Cancel"
                variant="warning"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SendEmailMaketingModal;
