import dayjs from "dayjs";
import React from "react";
import { PaginationContainer } from "../../../components/common";
import { TicketClaim } from "../../../models/ticket-claims.model";
import { useGetTicketClaimQuery } from "../../../services/ticket-claim.api";

interface ComponentProps {
  search?: string;
  id?: string;
}
const TicketClaimList: React.FC<ComponentProps> = ({ search, id }) => {
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  //   const [ticket, setTicket] = React.useState<Ticket>();
  const { data, isLoading } = useGetTicketClaimQuery({
    skip,
    take,
    search: search || id || undefined,
  });
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  console.log(data);

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div>
        <ul className="grid grid-cols-7 p-4 font-semibold">
          <li className="mr-4">ID</li>
          <li>Ticket ID</li>
          <li>User ID</li>
          <li>Claimed By</li>
          <li>Scanned By</li>
          <li>Claim Date</li>
          <li>Status</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((ticketClaim: TicketClaim, index: number) => (
              <ul
                className="grid items-center grid-cols-7 p-4 break-words rounded shadow-sm bticket"
                key={index}
              >
                <li className="mr-4">{index + 1}</li>
                <li>{ticketClaim.ticketId}</li>
                <li>{ticketClaim.userId}</li>
                <li>{ticketClaim.userClaim}</li>
                <li>{`${ticketClaim.firstName} ${ticketClaim.lastName}`}</li>
                <li>{`${dayjs(ticketClaim.claimDate).format(
                  "DD/MM/YYYY hh:mm A"
                )}`}</li>
                <li className="text-white">
                  {ticketClaim.isClaimed === true ? (
                    <span className="px-4 py-2 bg-green-400 rounded">
                      Claimed
                    </span>
                  ) : (
                    <span className="px-4 py-2 bg-yellow-300 rounded">
                      Available
                    </span>
                  )}
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
    </div>
  );
};

export default TicketClaimList;
