import React from 'react'
import { useParams } from 'react-router-dom';
import Conversation from '../../../features/Conversation/Conversation';

type SelectedConversationParams = {
  conversationId: string;
};

const SelectedConversationPage = () => {
  const { conversationId } = useParams<SelectedConversationParams>() as SelectedConversationParams;
  return (
    <div><Conversation conversationId={conversationId} /></div>
  )
}

export default SelectedConversationPage