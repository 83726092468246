import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetGroupsQuery } from "../../../services/group.api";
import { Option } from "../../../types/general-types";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  selectedOptions?: Option[];
  isMulti?: boolean;
  name?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const GroupSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  selectedOptions = [],
  isMulti = true,
  name = "groupIds",
  ...props
}) => {
  const { data: groups, isLoading } = useGetGroupsQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!groups) return <h2>Something went Wrong</h2>;

  const options = groups.data
    .map((group) => {
      return {
        label: group.name,
        value: group.id,
      };
    })
    .filter((group: Option) => {
      if (selectedOptions) {
        if (
          selectedOptions.find((data: Option) => data.value === group.value)
        ) {
          return false;
        }
      }
      return true;
    });

  const getValues = () => {
    return groups.data
      .filter((group) => defaultValue.includes(group.id))
      .map((group) => ({
        label: group.name,
        value: group.id,
      }));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="group"
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="groupId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default GroupSelect;
