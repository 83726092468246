import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import { EventTicketType, SeatType } from "../../../models";
import { VoucherType } from "../../../models/voucher.model";

type ValueProps = EventTicketType | SeatType | VoucherType | string;

export interface OptionValue {
  label: string;
  value: ValueProps;
}
interface ComponentProps {
  options?: OptionValue[];
  defaultValue?: ValueProps;
  name: string;
  isMulti?: boolean;
}

const TNSelect: React.FC<ComponentProps> = ({
  name,
  options,
  defaultValue,
  isMulti = false,
}) => {
  const getValues = () => {
    return options?.filter((option) => defaultValue?.includes(option.value));
  };

  return (
    <div>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id={name}
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(option: any) =>
                form.setFieldValue(field.name, option.value)
              }
              value={options?.filter((option) => {
                return option.value === field.value;
              })}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default TNSelect;
