import React from "react";
import { Modal } from "../../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../../components/common";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";
import { UpdateProductCategory } from "../UpdateProductCategory";
import { useDeleteProductCategoryMutation, useGetProductCategoriesQuery } from "../../../../services/product-category.api";
import { ProductCategory } from "../../../../models/product-category.model";

interface ComponentProps {
  search?: string;
}
export const ProductCategoryList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [productCategory, setProductCategory] = React.useState<ProductCategory>();
  const { data, isLoading } = useGetProductCategoriesQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteProduct] = useDeleteProductCategoryMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Slug</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((product: ProductCategory, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                  <li className="mr-4">
                      <button
                        className="px-2 py-1 border rounded-md"
                        onClick={() =>
                          navigator.clipboard.writeText(product.id || "")
                        }
                      >
                        COPY ID
                      </button>
                    </li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {product && product.image ? (
                          <img
                            alt={`${product.name}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              product.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{product.name}</li>
                <li>{product.slug}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setProductCategory(product);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setProductCategory(product);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {productCategory && (
        <Modal open={open} setOpen={setOpen} title="Update Category" size="m">
          <UpdateProductCategory setOpen={setOpen} productCategoryId={productCategory.id} />
        </Modal>
      )}
      {productCategory && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Category`}
          size="s"
          action={deleteProduct}
          item={productCategory.id}
        />
      )}
    </div>
  );
};
