import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Label, Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Event, EventVoucher } from "../../../../models/event.model";
import { VoucherSelect } from "../../../Voucher";
import {
  CustomError,
  useAddEventVoucherMutation,
  useDeleteEventVoucherMutation,
} from "../../../../services/event.api";
import { Option } from "../../../../types/general-types";
import { Voucher } from "../../../../models/voucher.model";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  event: Event;
}

interface FormValues {
  eventId: string;
  voucher: Option | undefined;
}

const EventVouchers: React.FC<ComponentProps> = ({ event }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventVoucher, setEventVoucher] = React.useState<Voucher>();
  const [addEventVoucher, { isError, error, isLoading, isSuccess }] =
    useAddEventVoucherMutation();

  const [
    deleteEventVoucher,
    {
      isError: deleteEventVoucherIsError,
      error: deleteEventVoucherError,
      isLoading: deleteEventVoucherIsLoading,
    },
  ] = useDeleteEventVoucherMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    eventId: event.id.toString(),
    voucher: undefined,
  };

  const validationSchema = Yup.object().shape({
    voucher: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    addEventVoucher({
      voucherId: values.voucher?.value || "0",
      eventId: values.eventId,
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <>
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Event Vouchers</h2>
          <Button title="Add Voucher" onClick={() => setOpen(true)} />
        </div>
        <ManualPagination
          vouchers={event.vouchers}
          setOpenDelete={setOpenDelete}
          setEventVoucher={setEventVoucher}
        />
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Voucher" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors }) => {
            return (
              <Form>
                <div className="grid gap-2">
                  {isError && error && (
                    <div className="text-red-500">
                      <h3 className="font-semibold">Error(s)</h3>
                      <ul>
                        {(error as CustomError).data.message.map(
                          (message, i) => (
                            <li key={i}>{message}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-2">
                    <Label htmlFor="vouchers">Voucher</Label>
                    <VoucherSelect />
                  </div>

                  <button
                    type="submit"
                    className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                  >
                    Add
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {eventVoucher && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Voucher`}
          size="s"
          action={deleteEventVoucher}
          item={{ eventId: event.id, voucherId: eventVoucher.id }}
        />
      )}
    </>
  );
};

export default EventVouchers;

interface ManualPaginationProps {
  vouchers: EventVoucher[];
  itemsPerPage?: number;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setEventVoucher: React.Dispatch<React.SetStateAction<Voucher | undefined>>;
}

const ManualPagination: React.FC<ManualPaginationProps> = ({
  vouchers,
  itemsPerPage = 10,
  setOpenDelete,
  setEventVoucher,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(vouchers.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleVouchers = vouchers.slice(startIndex, endIndex);

  return (
    <div className="">
      <ul className="grid justify-end grid-cols-5 mb-4 text-lg font-semibold">
        <li>Name</li>
        <li>Description</li>
        <li>Code</li>
        <li># of use</li>
        <li className="flex justify-end ">Action</li>
      </ul>
      <div>
        {visibleVouchers.map(({ voucher }, i) => (
          <ul key={i} className="grid justify-end grid-cols-5 mb-2">
            <li>{voucher.name}</li>
            <li>{voucher.description}</li>
            <li>{voucher.code}</li>
            <li>{voucher._count.orders}</li>
            <li className="flex justify-end ">
              <Button
                title="Delete"
                variant="warning"
                onClick={() => {
                  setOpenDelete(true);
                  setEventVoucher(voucher);
                }}
              />
            </li>
          </ul>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button
          className="mx-2"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="mx-2"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};
