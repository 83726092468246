import React from "react";

interface ComponentProps {
  variant?: "primary" | "secondary" | "warning";
  title: string;
}

type TExtendedProps = ComponentProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<TExtendedProps> = ({
  variant = "primary",
  title,
  className,
  ...props
}) => {
  const getVariant = () => {
    switch (variant) {
      case "primary":
        return "bg-blue-900 text-white";
      case "secondary":
        return "border border-blue-900 text-blue-900";
      case "warning":
        return "bg-red-500 text-white";
      default:
        return "";
    }
  };
  return (
    <button {...props} className={`px-4 py-2 rounded ${getVariant()} ${className}`}>
      {title}
    </button>
  );
};
