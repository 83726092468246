import React from "react";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "../../components/common/Tabs";
import ProductCategory from "../../features/Product/ProductCategory/ProductCategory";
import Product from "../../features/Product/Product";
import ProductOrders from "../../features/Product/ProductOrders";

export const ProductPage = () => {
  return (
    <Tabs>
      <div
        id="product-page"
        className="flex flex-grow h-full text-xs lg:flex-col lg:text-md"
      >
        <TabList className="flex flex-col items-start gap-4">
          <Tab>Product</Tab>
          <Tab>Orders</Tab>
          <Tab>Category</Tab>
        </TabList>
        <div className="flex-1 ">
          <TabPanels>
            <TabPanel>
              <Product />
            </TabPanel>
            <TabPanel>
              <ProductOrders />
            </TabPanel>
            <TabPanel>
              <ProductCategory />
            </TabPanel>
          </TabPanels>
        </div>
      </div>
    </Tabs>
  );
};
