import React from "react";
import { useParams } from "react-router-dom";
import ViewEventGroup from "../../../features/EventGroup/ViewEventGroup";

type UpdateEventParams = {
  eventGroupId: string;
};

export const SelectedEventGroup = () => {
  const { eventGroupId } = useParams<UpdateEventParams>() as UpdateEventParams;
  return <ViewEventGroup eventGroupId={eventGroupId} />;
};
