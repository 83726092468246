import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useGetVoucherQuery,
  useUpdateVoucherMutation,
} from "../../../services/voucher.api";
import * as Yup from "yup";
import TNSelect from "../../../components/common/TNSelect";
import { useGetBrandsQuery } from "../../../services/brand.api";
import SelectUser from "../../User/SelectUser";

interface FormValues {
  name: string;
  description: string;
  type: string;
  expiredAt: string;
  discountPercentage: number;
  quantity: number;
  code: string;
  brandId?: string | null;
  userId?: string | null;
}

interface ComponentProps {
  voucherId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateVoucher: React.FC<ComponentProps> = ({
  setOpen,
  voucherId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateVoucherMutation();

  const { data: voucher, isLoading: voucherIsLoading } =
    useGetVoucherQuery(voucherId);

  const { data: brands } = useGetBrandsQuery({});

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (voucher)
      update({
        id: voucher.id,
        ...values,
      });
  };

  if (isLoading || voucherIsLoading) return <h2>Loading...</h2>;

  if (!voucher) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: voucher?.name || "",
    description: voucher?.description || "",
    type: voucher?.type || "",
    expiredAt: voucher?.expiredAt || "",
    discountPercentage: voucher?.discountPercentage || 0,
    quantity: voucher?.quantity || 0,
    code: voucher?.code || "",
    brandId: voucher?.brandId || null,
    userId: voucher?.userId || null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const options =
    brands?.data?.map((brand) => ({
      value: brand.id,
      label: brand.name,
    })) || [];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="name">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="name">Type</Label>
                <Field name="type">
                  {({ field }: FieldProps) => (
                    <Input placeholder="type" id="type" {...field} />
                  )}
                </Field>
                <Label htmlFor="brandId">Brand</Label>
                <TNSelect name="brandId" options={options} />
                <Label htmlFor="userId">User</Label>
                <SelectUser name="userId" />
                <Label htmlFor="name">Expired At</Label>
                <Field name="expiredAt">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Expired At" id="expiredAt" {...field} />
                  )}
                </Field>
                <Label htmlFor="name">Discount Percentage</Label>
                <Field name="discountPercentage">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="discountPercentage"
                      id="discountPercentage"
                      {...field}
                      max={100}
                      min={0}
                    />
                  )}
                </Field>
                <Label htmlFor="name">Quantity</Label>
                <Field name="quantity">
                  {({ field }: FieldProps) => (
                    <Input
                      type="number"
                      placeholder="quantity"
                      id="quantity"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="name">Code</Label>
                <Field name="code">
                  {({ field }: FieldProps) => (
                    <Input placeholder="code" id="code" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
