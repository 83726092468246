import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Label } from "../../../../components/common";
import { ConfirmModal, Modal } from "../../../../components/common/Modals";
import { CustomError } from "../../../../models/email-marketing.model";
import EventsCard from "./EventsCard";
import SelectMultipleEvents from "../../Components/SelectMultipleEvents";
import SelectUser from "../../Components/SelectUser";
import { useSendUpcomingEventsEmailMutation } from "../../../../services/email-marketing.api";
import SendUpcomingEventsEmail from "./SendUpcomingEventsEmailModal";

interface FormValues {
  userEmails?: string[];
  eventIds: string[];
}

const UpcomingEventsTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const [item, setItem] = React.useState<FormValues>();
  const [eventsId, setEventsId] = React.useState<string[]>([]);
  const [usersEmail, setUsersEmail] = React.useState<string[]>([]);

  const initialValues: FormValues = {
    userEmails: usersEmail || [],
    eventIds: eventsId || [],
  };

  const validationSchema = Yup.object().shape({
    userEmails: Yup.array(),
    eventIds: Yup.array().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setOpen(true);
    setItem({
      ...values,
    });
  };

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title="Send Upcoming Events"
        size="s"
      >
        <SendUpcomingEventsEmail
          setOpen={setOpen}
          item={item}
          setItem={setItem}
          title={`Send Upcoming Events ${
            usersEmail.length !== 0 ? "to selected users" : "to all users"
          }`}
        />
      </Modal>
      <div className="flex flex-col items-center justify-center gap-5 p-3 font-roboto lg:items-start lg:flex-row ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <div className="grid p-5 w-[500px] gap-2 border rounded-md">
              <Form>
                <div className="grid items-center grid-cols-3 gap-6 pt-6 ">
                  <Label htmlFor="eventIds">Select Event</Label>
                  <SelectMultipleEvents
                    className="col-span-2"
                    setEventsId={setEventsId}
                  />
                  <Label htmlFor="userIds">Select Users</Label>
                  <SelectUser
                    className="col-span-2"
                    setUsersEmail={setUsersEmail}
                  />
                </div>
                <div className="grid items-center grid-cols-3 gap-6 pt-6">
                  <div className="col-span-3">
                    <button
                      type="submit"
                      disabled={eventsId.length === 0}
                      className={`w-full px-4 py-2 font-bold text-white border rounded ${
                        eventsId.length === 0
                          ? "bg-gray-700"
                          : "bg-darkBlue-700"
                      }`}
                    >
                      Send Upcoming Events List Email
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
        <div className="w-[800px] h-fit border rounded-md font-roboto tracking-wider bg-darkBlue-700">
          <div className="flex flex-col items-center justify-center w-full p-8 pb-5">
            <a href="https://www.ticketnation.ph" className="cursor-pointer">
              <img
                className="px-0 my-0 text-5xl font-bold tracking-wider text-blue-900 w-28 py-30"
                src="https://d1x6nuqi0g0x9s.cloudfront.net/tnlogowhite.png"
                alt="Ticketnation"
              />
            </a>
            <h1 className="pt-8 text-5xl font-bold text-white">
              UPCOMING EVENTS
            </h1>
          </div>
          <div className="grid justify-between w-full grid-cols-2 px-8 pb-8 gap-x-14 gap-y-6">
            {eventsId.length !== 0 &&
              eventsId.map((eventId, i) => (
                <EventsCard key={i} position={i % 2 === 0} eventId={eventId} />
              ))}
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center p-4 px-8 mb-6 text-sm text-center bg-white rounded-full cursor-pointer w-fit">
              <a
                className="text-xl font-bold no-underline text-darkBlue-700"
                href="https://www.ticketnation.ph"
              >
                Get your tickets now!
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingEventsTemplate;
