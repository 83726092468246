import React from "react";
import {
  OrderDetails,
  useGetAllOrderDetailsByOrderIdQuery,
} from "../../../services/order-details.api";
import { BiCheck } from "react-icons/bi";
import { Order } from "../../../models/order.model";
import TicketAccordion from "./TicketAccordion";

interface ComponentProps {
  order: Order;
}

export interface OrderDetailsGrouped {
  eventTicketName: string | null;
  subTotal: number;
  transactions: OrderDetails[];
}

const OrderDetailsPage: React.FC<ComponentProps> = ({ order }) => {
  const { data: orderDetails, isLoading: orderDetailsIsLoading } =
    useGetAllOrderDetailsByOrderIdQuery({ orderId: order.id });

  const currency = (orderDetails && orderDetails[0]?.currency) || "PHP";
  const transactionAmount = orderDetails?.find(
    (od: OrderDetails) => od.type === "TOTAL"
  )?.total;

  const groupedTransactions = orderDetails?.reduce(
    (acc: OrderDetailsGrouped[], orderDetail: OrderDetails) => {
      const subTotal =
        orderDetails
          .filter(
            (od) =>
              od.transactionType === "TICKET" &&
              od.eventTicketId === orderDetail.eventTicketId
          )
          .reduce((acc, { total }: OrderDetails) => acc + total, 0) -
        orderDetails
          .filter(
            (od) =>
              od.transactionType === "VOUCHER_DISCOUNT" &&
              od.eventTicketId === orderDetail.eventTicketId
          )
          .reduce((acc, { total }: OrderDetails) => acc + total, 0);

      if (orderDetail.eventTicketId) {
        const existingGroup = acc.find(
          (group) => group.eventTicketName === orderDetail.eventTicketType.name
        );
        if (existingGroup) {
          existingGroup.transactions.push(orderDetail);
        } else {
          acc.push({
            eventTicketName: orderDetail.eventTicketType.name,
            subTotal,
            transactions: [orderDetail],
          });
        }
      }
      return acc;
    },
    []
  );

  const titleCase = (str: string) => {
    return str
      .split("_")
      .join(" ")
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const includes = ["COMMISSION", "PROCESSING_FEE", "PRODUCT"];

  const transactions = [
    {
      label: "Total Ticket Fee",
      total: orderDetails
        ?.filter((od: OrderDetails) => od.transactionType === "TICKET_FEE")
        .reduce((acc: number, { total }: OrderDetails) => acc + total, 0),
      currency,
    },
  ];

  const otherTransactions = orderDetails
    ?.filter((od: OrderDetails) => includes.includes(od.transactionType))
    .map((od: OrderDetails) => ({
      label: titleCase(od.transactionType),
      total: od.total,
      currency,
    }));

  if (otherTransactions) {
    transactions.push(...otherTransactions);
  }

  if (orderDetailsIsLoading) return <h2>Loading...</h2>;

  return (
    <div className="flex w-full bg-white rounded-sm shadow-xl h-fit">
      {orderDetails?.length !== 0 && (
        <div className="w-full">
          <div className="flex flex-col items-center w-full gap-4 p-4 h-fit md:flex-row md:justify-between md:px-4">
            <div className="flex flex-col items-center">
              <span>Transaction Amount</span>
              <span className="text-3xl font-bold">
                {currency} {transactionAmount}
              </span>
            </div>
            <div
              className={`flex w-fit gap-1 items-center justify-between px-5 h-10  ${
                order?.status === "COMPLETED"
                  ? "bg-green-300 text-green-800"
                  : "bg-red-300 text-red-800"
              } rounded-full shadow-md`}
            >
              {order?.status === "COMPLETED" ? <BiCheck /> : null}
              <span>
                {order?.status.split("")[0]}
                {order?.status.substring(1).toLowerCase()}
              </span>
            </div>
          </div>
          {groupedTransactions && (
            <TicketAccordion
              items={groupedTransactions}
              titleCase={titleCase}
              currency={currency}
            />
          )}
          {transactions?.map(({ label, currency, total }, i) => (
            <div key={i}>
              <div className="flex flex-col gap-4 py-4 mx-4 border border-b-0 border-blue-100 md:flex-row md:p-8 border-x-0 border-t-1">
                <ul className="flex justify-between w-full">
                  <li className="font-medium">{label}</li>
                  <span className="flex flex-col justify-between w-1/4 gap-1 md:flex-row">
                    <li className="flex justify-end">{currency} </li>
                    <li className="flex justify-end">{total}</li>
                  </span>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;
