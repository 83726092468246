import {
  CreatePerformerSong,
  DeletePerformerSong,
} from "./../models/performer.model";
import {
  CreatePerformer,
  UpdatePerformer,
  Performer,
  CreatePerformerGenre,
  PerformerGenre,
  DeletePerformerGenre,
  PerformerSong,
} from "../models/performer.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Performers {
  data: Performer[];
  skip: number;
  take: number;
  count: number;
}

interface PerformersQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const performerApi = createApi({
  reducerPath: "performerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/performer",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Performers", "Performer"],
  endpoints: (builder) => ({
    createPerformer: builder.mutation<Performer, CreatePerformer>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Performers"],
    }),
    getPerformers: builder.query<Performers, PerformersQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Performers"],
    }),
    getPerformer: builder.query<Performer, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Performer"],
    }),
    updatePerformer: builder.mutation<Performer, UpdatePerformer>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Performers", "Performer"],
    }),
    uploadPerformerImage: builder.mutation<
      Performer,
      { id: string; file: File }
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Performers", "Performer"],
    }),
    deletePerformer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Performers"],
    }),
    removePerformerPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Performers", "Performer"],
    }),
    uploadPerformerBanner: builder.mutation<
      Performer,
      { id: string; file: File }
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/banner`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Performers", "Performer"],
    }),
    removePerformerBanner: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/banner`,
        method: "DELETE",
      }),
      invalidatesTags: ["Performers", "Performer"],
    }),
    createPerformerGenre: builder.mutation<
      PerformerGenre,
      CreatePerformerGenre
    >({
      query: ({ performerId, ...body }) => ({
        url: `/${performerId}/genre`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Performer"],
    }),

    deletePerformerGenre: builder.mutation<void, DeletePerformerGenre>({
      query: (body) => ({
        url: `/${body.performerId}/genre`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Performer"],
    }),

    createPerformerSong: builder.mutation<PerformerSong, CreatePerformerSong>({
      query: ({ performerId, ...body }) => ({
        url: `/${performerId}/song`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Performer"],
    }),
    deletePerformerSong: builder.mutation<void, DeletePerformerSong>({
      query: (body) => ({
        url: `/${body.performerId}/song`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Performer"],
    }),
  }),
});

export const {
  useCreatePerformerMutation,
  useGetPerformerQuery,
  useGetPerformersQuery,
  useUpdatePerformerMutation,
  useUploadPerformerImageMutation,
  useDeletePerformerMutation,
  useRemovePerformerPhotoMutation,
  useCreatePerformerGenreMutation,
  useDeletePerformerGenreMutation,
  useCreatePerformerSongMutation,
  useDeletePerformerSongMutation,
  useUploadPerformerBannerMutation,
  useRemovePerformerBannerMutation,
} = performerApi;
