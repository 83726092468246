import React from "react";
import OrganizationApiKeyList from "./OrganizationApiKey/OrganizationApiKeyList";

interface SelectedOrganizationProps {
  organizationId: string;
}
const SelectedOrganization: React.FC<SelectedOrganizationProps> = ({
  organizationId,
}) => {
  return <div><OrganizationApiKeyList organizationId={organizationId}/></div>;
};

export default SelectedOrganization;
