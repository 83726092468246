import { CreateGenre, UpdateGenre, Genre } from "../models/genre.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Genres {
  data: Genre[];
  skip: number;
  take: number;
  count: number;
}

interface GenresQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const genreApi = createApi({
  reducerPath: "genreApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/genres",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Genres", "Genre"],
  endpoints: (builder) => ({
    createGenre: builder.mutation<Genre, CreateGenre>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Genres"],
    }),
    getGenres: builder.query<Genres, GenresQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Genres"],
    }),
    getGenre: builder.query<Genre, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Genre"],
    }),
    updateGenre: builder.mutation<Genre, UpdateGenre>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Genres"],
    }),

    deleteGenre: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Genres"],
    }),

  }),
});

export const {
  useCreateGenreMutation,
  useGetGenreQuery,
  useGetGenresQuery,
  useUpdateGenreMutation,
  useDeleteGenreMutation,
} = genreApi;
