import React from "react";
import TicketNationAdminLogo from "../../assets/logo/ticket-nation-admin.svg";

export const SplashScreen = () => {
  return (
    <div className="w-full h-screen bg-blue-200">
      <div
        style={{
          background:
            "conic-gradient(from -1.53deg at 50% 50%, #00B0FF -5.36deg, #0099FF 63.75deg, #00B0FF 354.64deg, #0099FF 423.75deg), conic-gradient(from 5.86deg at 50% 50%, #00B0FF -22.78deg, #009EFF 121.02deg, rgba(0, 153, 255, 0) 325.49deg, #00B0FF 337.22deg, #009EFF 481.02deg)",
        }}
        className="flex items-center justify-center w-full h-screen animate-fade-out"
      >
        <img
          className="animate-pulse"
          src={TicketNationAdminLogo}
          alt="TicketNationAdminLogo"
        />
      </div>
    </div>
  );
};