import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { DatePicker, Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateVoucherMutation,
} from "../../../services/voucher.api";
import * as Yup from "yup";
import TNSelect from "../../../components/common/TNSelect";
import { VoucherType } from "../../../models/voucher.model";
import SelectUser from "../../User/SelectUser";
import { useGetBrandsQuery } from "../../../services/brand.api";

interface FormValues {
  name: string;
  description: string;
  type: string;
  expiredAt: string;
  discountPercentage: number;
  quantity: number;
  code: string;
  brandId?: string;
  userId?: string;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateVoucher: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateVoucherMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const { data: brands } = useGetBrandsQuery({});

  const initialValues: FormValues = {
    name: "",
    description: "",
    type: "",
    expiredAt: "",
    discountPercentage: 0,
    quantity: 0,
    code: "",
    brandId: "",
    userId: "",
  };

  const options =
    brands?.data?.map((brand) => ({
      value: brand.id,
      label: brand.name,
    })) || [];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input placeholder="Name" id="name" {...field} />
                )}
              </Field>
              <Label htmlFor="name">Description</Label>
              <Field name="description">
                {({ field }: FieldProps) => (
                  <Input
                    placeholder="description"
                    id="description"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="type">Type</Label>
              <TNSelect
                name="type"
                options={[
                  {
                    label: "Referral",
                    value: VoucherType.REFERRAL,
                  },
                  {
                    label: "Partnership",
                    value: VoucherType.PARTNERSHIP,
                  },
                  {
                    label: "Promotion",
                    value: VoucherType.PROMOTION,
                  },
                ]}
              />
              <Label htmlFor="brandId">Brand</Label>
              <TNSelect name="brandId" options={options} />
              <Label htmlFor="userId">User</Label>
              <SelectUser name="userId" />
              <Label htmlFor="expiredAt">Expired At</Label>
              <DatePicker name="expiredAt" minDate={new Date()} />
              <Label htmlFor="name">Discount Percentage</Label>
              <Field name="discountPercentage">
                {({ field }: FieldProps) => (
                  <Input
                    type="number"
                    placeholder="discountPercentage"
                    id="discountPercentage"
                    {...field}
                    max={100}
                    min={0}
                  />
                )}
              </Field>
              <Label htmlFor="name">Quantity</Label>
              <Field name="quantity">
                {({ field }: FieldProps) => (
                  <Input
                    type="number"
                    placeholder="quantity"
                    id="quantity"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="name">Code</Label>
              <Field name="code">
                {({ field }: FieldProps) => (
                  <Input placeholder="code" id="code" {...field} />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
