import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IContext {
  index: number;
  setIndex: (index: number) => void;
}

export const TabContext = React.createContext<IContext>({
  index: 0,
  setIndex: () => {},
});
interface TabsProps {
  children: React.ReactNode;
}
// export const Tabs: React.FC<TabsProps> = ({ children }) => {
//   const [index, setIndex] = React.useState(0);

//   return (
//     <TabContext.Provider value={{ index, setIndex }}>
//       {children}
//     </TabContext.Provider>
//   );
// };

interface ITabList {
  grid?: number;
  id?: string;
  children: React.ReactNode;
  className?: string;
}
interface ChildDetails {
  index: number;
}
export const TabList: React.FC<ITabList> = ({
  children,
  grid = 5,
  id,
  className,
}) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    let details: ChildDetails = { index: i };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, details);
    }
    return child;
  });

  return (
    <div
      id={id}
      className={`flex flex-col items-center justify-center gap-5 lg:justify-center lg:flex-row ${className}`}
    >
      {childrenWithProps}
    </div>
  );
};

interface ITab {
  index?: number;
  span?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}
// export const Tab: React.FC<ITab> = ({
//   index: childIndex = 0,
//   span = 1,
//   children,
//   style,
// }) => {
//   const { index, setIndex } = React.useContext(TabContext);
//   return (
//     <button
//       className={`${"md:text-xl px-1"} ${
//         index === childIndex ? "font-semibold underline text-blue-700" : ""
//       }`}
//       style={{ gridColumn: `span ${span} / span ${span}`, ...style }}
//       onClick={() => {
//         setIndex(childIndex);
//       }}
//     >
//       {children || "Link"}
//     </button>
//   );
// };

interface ComponentProps {
  style?: React.CSSProperties;
  id?: string;
  children: React.ReactNode;
}

export const TabPanels: React.FC<ComponentProps> = ({
  children,
  style,
  id,
}) => {
  const childrenWithProps = React.Children.map(children, (child, i) => {
    let details: ChildDetails = { index: i };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, details);
    }
    return child;
  });
  return (
    <div id={id} style={style}>
      {childrenWithProps}
    </div>
  );
};
// export const TabPanel: React.FC<ITab> = ({
//   index: childIndex = 0,
//   children,
//   span,
//   style,
// }) => {
//   const { index } = React.useContext(TabContext);
//   return index === childIndex ? <div style={style}>{children}</div> : null;
// };

export const Tabs: React.FC<TabsProps> = ({ children }) => {
  return <>{children}</>;
};

export const Tab: React.FC<ITab> = ({
  index: childIndex = 0,
  span = 1,
  children,
  style,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') || '0';

  return (
    <button
      className={`${'md:text-xl px-1'} ${
        currentTab === childIndex.toString() ? 'font-semibold underline text-blue-700' : ''
      }`}
      style={{ gridColumn: `span ${span} / span ${span}`, ...style }}
      onClick={() => {
        searchParams.set('tab', childIndex.toString());
        navigate({ search: searchParams.toString() });
      }}
    >
      {children || 'Link'}
    </button>
  );
};

export const TabPanel: React.FC<ITab> = ({
  index: childIndex = 0,
  children,
  span,
  style,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') || '0';

  return currentTab === childIndex.toString() ? <div style={style}>{children}</div> : null;
};