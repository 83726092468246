import React from "react";
import PresaleTemplate from "../Templates/Presale";
import PromotionTemplate from "../Templates/Promotion";
import ReminderTemplate from "../Templates/Reminder";
import UpcomingEventsTemplate from "../Templates/UpcomingEvents";
import AnnouncementTemplate from "../Templates/Announcement";

interface ComponentProps {
  template: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailMarketingTemplates: React.FC<ComponentProps> = ({
  template,
  setOpen,
}) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div
        onClick={() => setOpen(true)}
        className="flex w-fit cursor-pointer font-bold p-3 mb-6 rounded-md bg-darkBlue-700 text-white"
      >
        {template} Template
      </div>
      {template === "Reminder" && <ReminderTemplate />}
      {template === "Upcoming Events" && <UpcomingEventsTemplate />}
      {template === "Pre-sale" && <PresaleTemplate />}
      {template === "Promotion" && <PromotionTemplate />}
      {template === "Announcement" && <AnnouncementTemplate />}
    </div>
  );
};

export default EmailMarketingTemplates;
