import { CreateArtistInquiry, UpdateArtistInquiry, ArtistInquiry } from "../models/artist-inquiry.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ArtistInquiries {
  data: ArtistInquiry[];
  skip: number;
  take: number;
  count: number;
}

interface ArtistInquiriesQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const artistInquiryApi = createApi({
  reducerPath: "artistInquiryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/artist-inquiries",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["ArtistInquiries", "ArtistInquiry"],
  endpoints: (builder) => ({
    createArtistInquiry: builder.mutation<ArtistInquiry, CreateArtistInquiry>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ArtistInquiries"],
    }),
    getArtistInquiries: builder.query<ArtistInquiries, ArtistInquiriesQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["ArtistInquiries"],
    }),
    getArtistInquiry: builder.query<ArtistInquiry, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["ArtistInquiry"],
    }),
    updateArtistInquiry: builder.mutation<ArtistInquiry, UpdateArtistInquiry>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ArtistInquiries"],
    }),

    deleteArtistInquiry: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ArtistInquiries"],
    }),

  }),
});

export const {
  useCreateArtistInquiryMutation,
  useGetArtistInquiryQuery,
  useGetArtistInquiriesQuery,
  useUpdateArtistInquiryMutation,
  useDeleteArtistInquiryMutation,
} = artistInquiryApi;
