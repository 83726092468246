import React from "react";
import ConversationList from "../../features/Conversation/ConversationList";

const ConversationPage = () => {
  return (
    <div>
      <ConversationList />
    </div>
  );
};

export default ConversationPage;
