import dayjs from "dayjs";
import { ErrorMessage, Field } from "formik";
import React from "react";
import { useGetEventsQuery } from "../../../services/event.api";

interface ComponentProps {
  className?: string;
  excludeIds?: string[];
  isSelectEvent?: boolean;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const EventSelect: React.FC<TExtendedProps> = ({
  className,
  excludeIds,
  isSelectEvent,
  ...props
}) => {
  const { data: event } = useGetEventsQuery({});

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`w-full py-2 border rounded `}
        as="select"
        name={isSelectEvent ? "event" : "eventId"}
      >
        <option hidden>-- Select Event --</option>
        {event &&
          event.data
            .filter((data) => !excludeIds?.includes(data.id))
            .filter(
              (data) =>
                dayjs(new Date()) <= dayjs(data.dateTime) &&
                data.isActive === true
            )
            .map((event, index) => (
              <option
                key={index}
                value={isSelectEvent ? JSON.stringify(event) : event.id}
              >
                {event.name}
              </option>
            ))}
      </Field>
      <ErrorMessage name={isSelectEvent ? "event" : "eventId"}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default EventSelect;
