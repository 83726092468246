import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetGroupsQuery } from "../../../services/group.api";
import { Option } from "../../../types/general-types";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  selectedOptions?: Option[];
  isMulti?: boolean;
  name?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const CurrencySelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  selectedOptions = [],
  isMulti = true,
  name = "groupIds",
  ...props
}) => {
  const [currency] = React.useState<Option[]>([
    { label: "USD", value: "USD" },
    { label: "PHP", value: "PHP" },
    { label: "CAD", value: "CAD" },
  ]);

  const getValues = () => {
    return currency.filter((curr) => defaultValue.includes(curr.value));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name={name}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="currency"
              defaultValue={getValues()}
              name="currency"
              options={currency}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value!.value)}
              value={currency.find((curr) => curr.value === field.value)}
            />
          );
        }}
      </Field>
      <ErrorMessage name="currencyId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default CurrencySelect;
