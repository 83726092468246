import { ErrorMessage, FieldProps, FormikProps } from "formik";
import React from "react";
import { CgCheck } from "react-icons/cg";
import moment from "moment-timezone";

export interface Timezone {
  label: string;
  value: string;
}

interface TimezonePickerProps {
  name?: string;
  formik: FormikProps<{ form: FieldProps }>;
  initialTimezone: Timezone;
}

export const TimezonePicker: React.FC<TimezonePickerProps> = ({
  name = "timezone",
  formik,
  initialTimezone,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [timezone, setTimezone] = React.useState<Timezone | undefined>(
    initialTimezone
  );

  const handleTimezoneChange = (timezone: Timezone) => {
    setTimezone(timezone);
  };

  const timezones: Timezone[] = moment.tz.names().map((tz) => ({
    label: tz,
    value: tz,
  }));

  const filteredTimezones = timezones.filter((tz) =>
    tz.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (timezone: Timezone) => {
    handleTimezoneChange(timezone);
    formik.setFieldValue(name, timezone.value);
    setIsOpen(false);
  };

  return (
    <div>
      <div className="relative">
        <button
          type="button"
          className="relative z-10 w-full pl-3 pr-10 text-left bg-white shadow-sm text-sm font-normal text-gray-900 focus:outline-none focus:border-black focus:border-2 sm:text-sm border rounded px-4 py-2 col-span-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          {timezone ? timezone.label : "Select timezone"}
        </button>
        {isOpen && (
          <div className="absolute z-20 mt-2 w-full rounded-sm bg-white shadow-lg">
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                className="w-full border-gray-300 rounded shadow-sm p-2"
                placeholder="Search timezones..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <ul className="max-h-60 overflow-y-auto">
              {filteredTimezones.map((timezone) => (
                <li
                  key={timezone.value}
                  className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                  onClick={() => handleSelect(timezone)}
                >
                  {timezone.label}
                  {timezone && timezone.value === timezone.value && (
                    <CgCheck className="absolute w-5 h-5 text-blue-500 right-3 top-3" />
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};
