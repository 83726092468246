import React from "react";
import DynamicTable, { TableData } from "../../../components/common/Table";
import { useGetProductOrdersQuery } from "../../../services/product-order.api";
import {
  ProductOrder,
  ProductOrderVariant,
} from "../../../models/product-order.model";
import { formatCurrency, formatDateTime } from "../../../helpers/general";
import dayjs from "dayjs";

const ProductOrders = () => {
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(10);
  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);
  const { data, isLoading } = useGetProductOrdersQuery({
    skip,
    take,
    // search: search || "",
    // ...filter,
  });

  const columns = [
    {
      key: "orderNumber",
      label: "Order #",
    },
    {
      key: "orderDate",
      label: "Order-Date",
    },

    {
      key: "product",
      label: "Product",
    },
    {
      key: "amount",
      label: "Amount",
    },
  ];

  const tableData: TableData[] =
    data?.data.map((productOrder: ProductOrder) => ({
      id: productOrder.id || "",
      orderNumber: productOrder.Order.orderNumber.toString() || "",
      orderDate: dayjs(productOrder.createdAt).format("MM-DD-YY hh:mma") || "",
      product: (
        <>
          {productOrder.ProductOrderVariant.map(
            (productOrderVariant: ProductOrderVariant) => {
              return (
                <div className="grid justify-center grid-cols-2">
                  <div className="flex flex-col">
                    <span>
                      {
                        productOrderVariant.ProductVariant.product
                          .EventProduct[0]?.event.name
                      }
                    </span>
                    <span>
                      {productOrderVariant.ProductVariant.product
                        .EventProduct[0]?.event.dateTime
                        ? formatDateTime(
                            productOrderVariant.ProductVariant.product
                              .EventProduct[0]?.event.dateTime
                          )
                        : ""}
                    </span>
                  </div>
                  <div className="flex items-center gap-1">
                    {}
                    <div className="flex flex-col">
                      <span>
                        {productOrderVariant.ProductVariant.product.name}
                      </span>
                      <span>{productOrderVariant.ProductVariant.name}</span>
                    </div>
                    <span>X</span>
                    <div>{productOrderVariant.quantity}</div>
                  </div>
                </div>
              );
            }
          )}
        </>
      ),

      amount: formatCurrency(productOrder.subtotal),
    })) || [];
  return (
    <div>
      <DynamicTable
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={take}
        total={data?.count || 0}
        columns={columns}
        data={tableData}
        // actionComponent={({ id }) => {
        //   const order = data?.data.find((order) => order.id === id);
        //   if (!order) return null;
        //   return (
        //     <div className="p-2 text-sm text-gray-700 break-words">
        //       <ActionDropdown
        //         placeholder="Actions"
        //         icon={<Tool className="w-5 h-5" />}
        //         options={getOptions(order)}
        //       />
        //     </div>
        //   );
        // }}
      />
    </div>
  );
};

export default ProductOrders;
