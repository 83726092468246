import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetRolesQuery } from "../../../services/role.api";

interface ComponentProps {
  className?: string;
  userRoles: (string | undefined)[];
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
export const RoleSelect: React.FC<TExtendedProps> = ({
  className,
  userRoles,
  ...props
}) => {
  const { data: roles, isLoading } = useGetRolesQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!roles) return <h2>Something went Wrong</h2>;

  const options = roles.data
    .filter((r) => !userRoles.includes(r.name))
    .map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });

  return (
    <div className={`${className} flex flex-col`}>
      <Field name="roleIds">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="role"
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="roleId">
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};
