import React, { useRef } from "react";

import {
  CustomError,
  useGetArtistInquiryQuery,
  useUpdateArtistInquiryMutation,
} from "../../../services/artist-inquiry.api";
import { ArtistInquiryForm } from "../ArtistInquiryForm";

interface ComponentProps {
  artistInquiryId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateArtistInquiry: React.FC<ComponentProps> = ({
  setOpen,
  artistInquiryId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateArtistInquiryMutation();

  const { data: artistInquiry, isLoading: artistInquiryIsLoading } =
    useGetArtistInquiryQuery(artistInquiryId);


  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  if (isLoading || artistInquiryIsLoading  )
    return <h2>Loading...</h2>;
  if (!artistInquiry) return <h2>Something went wrong..</h2>;


  return (
     <ArtistInquiryForm onSubmit={update} isError={isError} error={error as CustomError} isSuccess={isSuccess}/>
  );
};
