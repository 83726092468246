import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetProductsQuery } from "../../../services/product.api";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  isMulti?: boolean;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
export const ProductSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  isMulti = false,
  ...props
}) => {
  const { data: products, isLoading } = useGetProductsQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!products) return <h2>Something went Wrong</h2>;

  const options = products.data.map((product) => {
    return {
      label: product.name,
      value: product.id,
    };
  });

  const getValues = () => {
    return products.data
      .filter((product) => defaultValue.includes(product.id))
      .map((product) => ({
        label: product.name,
        value: product.id,
      }));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name="product">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="product"
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="product">
        {(message) => <span className="text-red-500">Product is required</span>}
      </ErrorMessage>
    </div>
  );
};
