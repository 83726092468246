import { useState, useEffect } from "react";
import { Modal } from "../Modals";
import { HiFilter } from "react-icons/hi";

interface FilterOption {
  label: string;
  value: string;
}

export interface FilterField {
  type: "select" | "date";
  label: string;
  options?: FilterOption[];
}

interface FilterProps {
  fields: FilterField[];
  filter: any;
  setFilter: (filter: any) => void;
}

interface FilterState {
  [key: string]: any;
}

export const Filter: React.FC<FilterProps> = ({
  fields,
  filter,
  setFilter,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterState, setFilterState] = useState<FilterState>({});

  useEffect(() => {
    setFilter(filterState);
  }, [filterState]);

  const handleRemoveFilter = (key: string) => {
    setFilterState((prevState) => {
      const newState = { ...prevState };
      delete newState[key];
      return newState;
    });
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <>
      <div className="flex flex-col p-2 md:flex-row md:justify-between">
        <div className="flex flex-wrap gap-1 mb-2 lg:flex-row md:mb-0">
          {Object.values(filter).length > 0 &&
            Object.entries(filter).map(([key, value]) => {
              return (
                <div
                  className="flex justify-between px-2 py-1 text-white bg-blue-500 rounded-md"
                  key={key}
                >
                  <div>
                    <span className="mr-1 font-semibold capitalize">
                      {key}:
                    </span>
                    {`${value}`}
                  </div>
                  <button
                    className="w-6 h-6 ml-2 bg-red-400 rounded-full hover:cursor-pointer"
                    onClick={() => handleRemoveFilter(key)}
                  >
                    X
                  </button>
                </div>
              );
            })}
        </div>
        <button
          onClick={() => setOpenFilter(true)}
          className="flex items-center px-2 py-1 overflow-hidden text-white bg-blue-500 rounded-md"
        >
          <HiFilter /> <span className="ml-2">Filter</span>
        </button>
      </div>
      <Modal open={openFilter} setOpen={setOpenFilter} title="Order Filter">
        <div className="bg-gray-500">
          <table className="w-full">
            <tbody>
              {fields.map((field, index) => (
                <tr key={index}>
                  <td className="flex p-2">
                    <label className="mr-2 text-white">{field.label}</label>
                  </td>
                  <td className="w-[99%] p-2">
                    {field.type === "select" ? (
                      <select
                        className="w-full px-3 py-2 rounded-md"
                        value={filterState[field.label] || ""}
                        onChange={(e) =>
                          handleFilterChange(field.label, e.target.value)
                        }
                      >
                        <option value="">All</option>
                        {field.options?.map((option, i) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        value={filterState[field.label] || ""}
                        onChange={(e) =>
                          handleFilterChange(field.label, e.target.value)
                        }
                        className="w-full px-3 py-2 rounded-md"
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={() => setOpenFilter(false)}
            className="w-full col-span-2 px-3 py-2 text-white bg-red-500 rounded-md"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};
