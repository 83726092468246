import React, { useState } from "react";
import { Event } from "../../../../models/event.model";
import {
  useDeleteEventProductMutation,
  useGetEventProductsQuery,
} from "../../../../services/event.api";
import DynamicTable, { TableData } from "../../../../components/common/Table";
import { Product } from "../../../../models/product.model";
import { EventProduct } from "../../../../models/event-product.model";
import { Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals";

interface ComponentProps {
  event: Event;
}
const EventProductList: React.FC<ComponentProps> = ({ event }) => {
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = useState(false);
  const [take, setTake] = useState(10);
  const [skip, setSkip] = useState(0);
  const { data } = useGetEventProductsQuery({
    eventId: event.id,
    take,
    skip,
  });
  const [deleteEventProduct] = useDeleteEventProductMutation();
  const columns = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "slug",
      label: "Slug",
    },
  ];
  const tableData: TableData[] =
    data?.data.map((item: EventProduct, index) => ({
      id: item.eventId + item.productId,
      identifier: item.productId,
      name: item.product.name,
      slug: item.product.slug,
    })) || [];

  return (
    <div>
      <DynamicTable
        data={tableData}
        columns={columns}
        total={data?.count || 0}
        actionComponent={({
          id,
          identifier,
        }: {
          id: string;
          identifier: string;
        }) => (
          <div>
            {/* <Button title="Add Variant" onClick={() => {
              setSelectedId(id);
              setOpen(true);
            }} /> */}
            <Button
              title="Delete"
              variant="warning"
              onClick={() =>
                deleteEventProduct({ eventId: event.id, productId: identifier })
              }
            />
          </div>
        )}
      />
    </div>
  );
};

export default EventProductList;
