import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useGetGenreQuery,
  useUpdateGenreMutation,
} from "../../../services/genre.api";
import * as Yup from "yup";

interface FormValues {
  name: string;
  description: string;
}

interface ComponentProps {
  genreId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateGenre: React.FC<ComponentProps> = ({ setOpen, genreId }) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateGenreMutation();

  const { data: genre, isLoading: genreIsLoading } = useGetGenreQuery(genreId);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (genre)
      update({
        id: genre.id,
        ...values,
      });
  };

  if (isLoading || genreIsLoading) return <h2>Loading...</h2>;

  if (!genre) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: genre?.name || "",
    description: genre?.description || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="name">Description</Label>
                <Field name="description">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="description"
                      id="description"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
