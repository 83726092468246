import React from "react";
import { PaginationContainer } from "../../../components/common";
import { ArtistInquiry } from "../../../models/artist-inquiry.model";
import {
  useDeleteArtistInquiryMutation,
  useGetArtistInquiriesQuery,
} from "../../../services/artist-inquiry.api";
import { ConfirmModal, Modal } from "../../../components/common/Modals";
import { UpdateArtistInquiry } from "../UpdateArtistInquiry";

interface ComponentProps {
  search?: string;
}
export const ArtistInquiryList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [artistInquiry, setArtistInquiry] = React.useState<ArtistInquiry>();
  const { data, isLoading } = useGetArtistInquiriesQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteArtistInquiry] = useDeleteArtistInquiryMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>ID</li>
          <li>Name</li>
          <li>Target Date</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((artistInquiry: ArtistInquiry, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li className="mr-4">{artistInquiry.id}</li>
                <li>{artistInquiry.name}</li>
                <li>{artistInquiry.targetDate}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setArtistInquiry(artistInquiry);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setArtistInquiry(artistInquiry);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {artistInquiry && (
        <Modal
          open={open}
          setOpen={setOpen}
          title="Update ArtistInquiry"
          size="m"
        >
          <UpdateArtistInquiry
            setOpen={setOpen}
            artistInquiryId={artistInquiry.id || ""}
          />
        </Modal>
      )}
      {artistInquiry && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Artist Inquiry`}
          size="s"
          action={deleteArtistInquiry}
          item={artistInquiry.id}
        />
      )}
    </div>
  );
};
