import React from "react";
import "./index.css";

interface ComponentProps {
  disabled?: boolean;
  className?: string;
  value?: boolean;
  onClick?: (value: boolean) => void;
}

const ToggleSwitch: React.FC<ComponentProps> = ({
  disabled,
  value,
  onClick,
}) => {
  const triggerToggle = () => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(!value);
    }
  };

  return (
    <div
      onClick={triggerToggle}
      className={`wrg-toggle ${value && "wrg-toggle--checked"}`}
    >
      <div className="wrg-toggle-container">
        <div className="wrg-toggle-check">
          <span>Yes</span>
        </div>
        <div className="wrg-toggle-uncheck">
          <span>No</span>
        </div>
      </div>
      <div className="wrg-toggle-circle"></div>
      <input
        className="wrg-toggle-input"
        type="checkbox"
        aria-label="Toggle Button"
      />
    </div>
  );
};

export default ToggleSwitch;
