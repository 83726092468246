import React from "react";
import { useNavigate } from "react-router-dom";
import { PaginationContainer } from "../../../components/common";
import { Group } from "../../../models/group.model";
import {
  useDeleteGroupMutation,
  useGetGroupsQuery,
} from "../../../services/group.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  search?: string;
}
const GroupList: React.FC<ComponentProps> = ({ search }) => {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [group, setGroup] = React.useState<Group>();
  const { data, isLoading } = useGetGroupsQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteGroup] = useDeleteGroupMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-3 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((group: Group, index: number) => (
              <ul
                className="grid items-center grid-cols-3 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                    <li className="mr-4">{group.id}</li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {group && group.image ? (
                          <img
                            alt={`${group.name}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              group.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{group.name}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      navigate(`${group.id}/edit`);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setGroup(group);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {group && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Group`}
          size="s"
          action={deleteGroup}
          item={group.id}
        />
      )}
    </div>
  );
};

export default GroupList;
