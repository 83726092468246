import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SelectedOrder from "../../../features/Order/SelectedOrder";
import { BiArrowBack } from "react-icons/bi";

type SelectedOrderParams = {
  orderId: string;
};

export const SelectedOrderPage = () => {
  const { orderId } = useParams<SelectedOrderParams>() as SelectedOrderParams;
  const navigate = useNavigate();

  return (
    <div className="flex-grow h-full md:p-5">
      <div className="flex md:gap-4 flex-col md:flex-row pt-5 md:pt-0">
        <div
          onClick={() => navigate("/order")}
          className="flex gap-1 w-20 items-center justify-between mb-4 px-3  bg-blue-500 hover:bg-blue-400 text-white rounded-lg cursor-pointer shadow-md"
        >
          <BiArrowBack />
          <span>Back</span>
        </div>
        <h2 className="mb-4 text-2xl font-semibold pt-16 md:pt-0">
          Order Details
        </h2>
      </div>
      <SelectedOrder orderId={orderId} />
    </div>
  );
};
