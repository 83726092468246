import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetVouchersQuery } from "../../../services/voucher.api";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  isMulti?: boolean
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
export const VoucherSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  isMulti = false,
  ...props
}) => {
  const { data: vouchers, isLoading } = useGetVouchersQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!vouchers) return <h2>Something went Wrong</h2>;

  const options = vouchers.data.map((voucher) => {
    return {
      label: voucher.name,
      value: voucher.id,
    };
  });

  const getValues = () => {
    return vouchers.data
      .filter((voucher) => defaultValue.includes(voucher.id))
      .map((voucher) => ({
        label: voucher.name,
        value: voucher.id,
      }));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name="voucher">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="voucher"
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="voucher">
        {(message) => <span className="text-red-500">Voucher is required</span>}
      </ErrorMessage>
    </div>
  );
};

