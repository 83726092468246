import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Label } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Genre } from "../../../../models/genre.model";

import { GenreSelect } from "../../../Genre";
import { Option } from "../../../../types/general-types";
import {
  useCreateGroupGenreMutation,
  useDeleteGroupGenreMutation,
  CustomError,
} from "../../../../services/group.api";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";

interface ComponentProps {
  genres: Genre[];
  groupId: string;
}

interface FormValues {
  genre: Option | undefined;
  groupId: string;
}

export const GroupGenre: React.FC<ComponentProps> = ({ genres, groupId }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [groupGenre, setGroupGenre] = React.useState<Genre>();
  const [createGroupGenre, { isError, error, isLoading, isSuccess }] =
    useCreateGroupGenreMutation();

  const [
    deleteGroupGenre,
    {
      isError: deleteEventGroupIsError,
      error: deleteEventGroupError,
      isLoading: deleteEventGroupIsLoading,
    },
  ] = useDeleteGroupGenreMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    groupId: groupId,
    genre: undefined,
  };

  const validationSchema = Yup.object().shape({
    groupId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    createGroupGenre({
      groupId: values.groupId,
      genreId: values.genre?.value || "",
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <div className="mb-2 lg:mb-4">
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Group Genre</h2>
          <Button title="Add Genre" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {genres.map((genre: Genre, index: number) => (
              <ul className="grid justify-end grid-cols-3 mb-2" key={index}>
                <li>{genre.name}</li>
                <li>{genre.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setGroupGenre(genre);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Group Genre" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="groups">Genre</Label>
                  <GenreSelect />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {groupGenre && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Group Genre`}
          size="s"
          action={deleteGroupGenre}
          item={{
            groupId,
            genreId: groupGenre.id,
          }}
        />
      )}
    </div>
  );
};
