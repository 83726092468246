import { ErrorMessage } from "formik";
import React from "react";

interface ComponentProps {
  className?: string;
  type?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<TExtendedProps> = ({
  className,
  type = "text",
  ...props
}) => {
  const renderInput = () => {
    if (type === "toggle") {
      return (
        <label
          htmlFor={props.id || ""}
          className={`inline-flex items-center cursor-pointer`}
        >
          <input
            {...props}
            type="checkbox"
            className={`w-6 h-6 border-2 border-gray-300 rounded-full focus:ring-0 focus:outline-none transition duration-200 ease-in-out ${
              props.checked ? "bg-blue-500" : "bg-white"
            }`}
          />
          <span
            className={`ml-3 text-gray-700 ${
              props.checked ? "font-semibold" : "font-normal"
            }`}
          >
            {props.name}
          </span>
        </label>
      );
    } else {
      return (
        <input
          {...props}
          type={type}
          className={`border rounded px-4 py-2 col-span-2 ${className}`}
        />
      );
    }
  };

  return (
    <div className={`${className} flex-col flex`}>
      {renderInput()}
      {props.name && (
        <ErrorMessage name={props.name || ""}>
          {(message) => <span className="text-red-500">{message}</span>}
        </ErrorMessage>
      )}
    </div>
  );
};
