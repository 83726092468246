import React from "react";
import { useGetConversationsQuery } from "../../services/conversation.api";
import { Link } from "react-router-dom";

const ConversationList = () => {
  const take = 10;
  const skip = 0;
  const { data, isLoading } = useGetConversationsQuery({ take, skip });
  if (isLoading) return <h2>Loading...</h2>;
  return (
    <table className="table w-full table-compact">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.data.map((conversation) => (
          <tr key={conversation.id}>
            <td className="w-20">
              <button
                className="px-2 py-1 border rounded-md"
                onClick={() =>
                  navigator.clipboard.writeText(conversation.id || "")
                }
              >
                COPY ID
              </button>
            </td>
            <td>{conversation.name}</td>
            <td>{conversation.description}</td>
            <td>
              <Link to={`/conversation/${conversation.id}`}>View</Link>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </tfoot>
    </table>
  );
};

export default ConversationList;
