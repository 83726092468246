import React from "react";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Checkbox, Input, Label } from "../../../../components/common";
import { Event } from "../../../../models/event.model";
import SelectEvent from "../../Components/SelectEvent";
import dayjs from "dayjs";
import CarouselSelect from "../../Components/SelectCarousel";
import SelectUser from "../../Components/SelectUser";
import {
  useGetAllEmailMarketingQuery,
  useSendEventReminderEmailMutation,
} from "../../../../services/email-marketing.api";
import { ConfirmModal } from "../../../../components/common/Modals";
import { CustomError } from "../../../../models/email-marketing.model";

interface FormValues {
  eventId: string;
  eventName: string;
  eventDate: string;
  imageFileName: string;
  eventAddress: string;
  userEmails?: string[];
  isManyUsers: boolean;
  withVenuePointers: boolean;
  skip?: number;
  take?: number;
}

const ReminderTemplate = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState<Event | undefined>();
  const [item, setItem] = React.useState<FormValues>();
  const [values, setValues] = React.useState<FormValues | undefined>();
  const [usersEmail, setUsersEmail] = React.useState<string[]>([]);
  const [sendEventReminderEmail, { isLoading, isError, error, isSuccess }] =
    useSendEventReminderEmailMutation();
  const { data: emailMarketing } = useGetAllEmailMarketingQuery({
    type: "REMINDER",
  });

  const initialValues: FormValues = {
    eventId: event?.id || "",
    eventName: event?.name || "",
    eventDate: dayjs(event?.dateTime).format("MMMM DD, YYYY - hh:mmA") || "",
    eventAddress: event?.venue?.address1 || "",
    imageFileName: event?.image?.fileName || "",
    userEmails: usersEmail || [],
    isManyUsers: false,
    withVenuePointers: true,
  };

  const excludeUsers =
    event &&
    emailMarketing?.data
      ?.find((em) => em.eventId === event.id)
      ?.user.map((u) => u.email);

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().required("Event ID is required."),
    eventName: Yup.string().required("Event Name is required."),
    eventDate: Yup.string().required("Date is required."),
    eventAddress: Yup.string().required("Address is required."),
    userEmails: Yup.array(),
    isManyUsers: Yup.boolean(),
    withVenuePointers: Yup.boolean(),
    imageFileName: Yup.string().required("Image is required."),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    setOpen(true);
    setItem({
      ...values,
      skip: 0,
      take: 500,
    });
  };

  React.useEffect(() => {
    if (isSuccess) {
      setUsersEmail([]);
    }
  }, [isSuccess]);

  return (
    <>
      {open && (
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          title={`Send Email Reminder`}
          size="s"
          action={sendEventReminderEmail}
          item={item}
        />
      )}
      <div className="flex flex-col items-center justify-center gap-5 p-3 font-roboto lg:items-start lg:flex-row ">
        <Formik
          innerRef={(formikActions) =>
            formikActions
              ? setValues(formikActions.values)
              : setValues(undefined)
          }
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <div className="grid p-5 w-[500px] gap-2 border rounded-md">
              <SelectEvent
                setUsersEmail={setUsersEmail}
                event={event}
                setEvent={setEvent}
              />
              {event && (
                <Form>
                  {isError && error && (
                    <div className="text-red-500">
                      <h3 className="font-semibold">Error(s)</h3>
                      <ul>
                        <li>{(error as CustomError).data.message}</li>
                      </ul>
                    </div>
                  )}
                  <div className="grid items-center grid-cols-3 gap-6 pt-6">
                    <Label htmlFor="eventName">Event Name</Label>
                    <Field name="eventName">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Name"
                          id="eventName"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventDate">Date</Label>
                    <Field name="eventDate">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Date"
                          id="eventDate"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="eventAddress">Address</Label>
                    <Field name="eventAddress">
                      {({ field }: FieldProps) => (
                        <Input
                          className="col-span-2"
                          placeholder="Event Address"
                          id="eventAddress"
                          {...field}
                        />
                      )}
                    </Field>
                    <Label htmlFor="imageFileName">Change Image</Label>
                    <CarouselSelect
                      name="imageFileName"
                      className="col-span-2"
                    />
                    <div className="grid col-span-3">
                      <div className="grid grid-cols-2">
                        <div className="flex justify-around col-span-1">
                          <Label htmlFor="withVenuePointers">
                            Venue Pointers
                          </Label>
                          <Field name="withVenuePointers">
                            {({ field }: FieldProps) => (
                              <Checkbox
                                className="items-center"
                                id="withVenuePointers"
                                {...field}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="flex justify-around col-span-1">
                          <Label htmlFor="isManyUsers">Mupltiple Users</Label>
                          <Field name="isManyUsers">
                            {({ field }: FieldProps) => (
                              <Checkbox
                                className="items-center"
                                id="isManyUsers"
                                {...field}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    {!values?.isManyUsers && (
                      <>
                        <Label htmlFor="userEmails">Add Users</Label>
                        <SelectUser
                          className="col-span-2"
                          setUsersEmail={setUsersEmail}
                          eventId={event.id}
                          excludeIds={excludeUsers}
                          emailType="REMINDER"
                        />
                      </>
                    )}
                    <div className="col-span-3">
                      <button
                        type="submit"
                        className="w-full px-4 py-2 font-bold text-white border rounded bg-darkBlue-700"
                      >
                        Send Event Reminder Email
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </div>
          )}
        </Formik>
        <div className="w-[500px] h-fit border rounded-md font-roboto tracking-wider">
          <div className="p-6 font-semibold text-center text-white bg-lightBlue-300 rounded-t-md">
            Important Information for your Event
          </div>
          <div className="w-full h-fit">
            <div className="flex items-center justify-center object-cover w-full h-full overflow-hidden bg-black">
              {values?.imageFileName && values.eventName && (
                <img
                  alt={`${values.eventName}_image`}
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}${values.imageFileName}`}
                />
              )}
            </div>
          </div>
          <div className="p-4">
            {values?.withVenuePointers && (
              <div className="py-4 border-t">
                <p className="text-sm font-bold">Venue Pointers:</p>
                <div className="grid grid-cols-2">
                  <p className="text-xs">No outside food & drinks</p>
                  <p className="text-xs">No pets</p>
                  <p className="text-xs">No deadly weapons</p>
                  <p className="text-xs">Bring extra clothes</p>
                  <p className="text-xs">No illegal substances</p>
                  <p className="text-xs">Bring Valid ID for verification</p>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="font-bold text-md">
                {values?.eventName || "Event Name"}
              </p>
              <p className="text-sm">{values?.eventDate || "Event Date"}</p>
              <p className="text-sm">
                {values?.eventAddress || "Event Address"}
              </p>
              <a
                target="_blank"
                href={process.env.REACT_APP_BASE_URL + "/profile"}
                rel="noreferrer"
              >
                <div className="p-2 text-sm font-semibold text-center text-white rounded-md bg-darkBlue-700">
                  CHECK TICKET
                </div>
              </a>
            </div>
            <div className="flex flex-col gap-1 py-4 border-t">
              <p className="font-bold text-md">See you there!</p>
              <p className="pb-3 text-sm">
                {`Don't forget these reminders and enjoy ${
                  values?.eventName || "Event Name"
                }`}
                !
              </p>
              <p className="text-xs text-end">
                © 2022 Ticketnation Philippines
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReminderTemplate;
