import dayjs from "dayjs";

export const formatDateTime = (date: Date): string => {
  return dayjs(date).format("MMM-D-YYYY hh:mm A");
};

export function formatCurrency(amount: number, currency: string = "PHP") {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
}
