import { CreatePermission, UpdatePermission, Permission } from "../models/permission.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Permissions {
  data: Permission[];
  skip: number;
  take: number;
  count: number;
}

interface PermissionsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const permissionApi = createApi({
  reducerPath: "permissionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/permissions",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Permissions", "Permission"],
  endpoints: (builder) => ({
    createPermission: builder.mutation<Permission, CreatePermission>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Permissions"],
    }),
    getPermissions: builder.query<Permissions, PermissionsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Permissions"],
    }),
    getPermission: builder.query<Permission, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Permission"],
    }),
    updatePermission: builder.mutation<Permission, UpdatePermission>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Permissions"],
    }),
    uploadPermissionImage: builder.mutation<Permission, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Permissions", "Permission"],
    }),
    deletePermission: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Permissions"],
    }),
    removePermissionPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Permissions", "Permission"],
    }),
  }),
});

export const {
  useCreatePermissionMutation,
  useGetPermissionQuery,
  useGetPermissionsQuery,
  useUpdatePermissionMutation,
  useUploadPermissionImageMutation,
  useDeletePermissionMutation,
  useRemovePermissionPhotoMutation,
} = permissionApi;
