import React from "react";
import { useGetPerformerQuery } from "../../../services/performer.api";

interface ComponentProps {
  id: string;
}

const ViewPerformer: React.FC<ComponentProps> = ({ id }) => {
  const { data, isLoading } = useGetPerformerQuery(id);


  return <div>ViewPerformer</div>;
};

export default ViewPerformer;
