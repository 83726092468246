import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import Select from "react-select/async";
import { useLazyGetUsersQuery } from "../../../services/user.api";

interface ComponentProps {
  excludeIds?: string[];
  className?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
}

interface FormValues {
  [key: string]: string | number | undefined;
  userId?: string;
  firstName: string;
  lastName: string;
  referenceId: string;
  eventTicketId: string;
  quantity: number;
}

const SelectUser: React.FC<ComponentProps> = ({
  excludeIds,
  className,
  disabled = false,
  name,
  id,
}) => {
  const [skip] = React.useState(0);
  const [take] = React.useState(10);
  const [trigger, { data, isLoading, isError }] = useLazyGetUsersQuery();
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const filterOptions = (inputValue: string) => {
    return (
      data?.data
        .map((user) => {
          return {
            label: user.email,
            value: user.id,
          };
        })
        .filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        ) || []
    );
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    trigger({ skip, take, search: inputValue });
    if (!isLoading) return callback(filterOptions(inputValue));
  };

  if (isError) return <div>Error</div>;

  const inputName: string = name || "userId";

  return (
    <div className={`${className}`}>
      <div className="flex items-center">
        <div className="flex-1">
          <Field
            id={id}
            name={inputName}
            loadOptions={loadOptions}
            placeholder="Select a User"
            value={
              values[inputName]
                ? { value: values[inputName], label: values[inputName] }
                : ""
            }
            onChange={(option: any) => {
              setFieldValue(inputName, option ? option.value : "");
            }}
            component={Select}
          />
        </div>
        {values[inputName] && (
          <button
            className="w-8 h-8 ml-1 text-red-500 border border-red-500 rounded-full"
            onClick={() => setFieldValue(inputName, "")}
          >
            X
          </button>
        )}
      </div>
      <ErrorMessage name={inputName}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default SelectUser;
