import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAddConversationParticipantsMutation } from "../../services/conversation.api";
import SelectUser from "../User/SelectUser";

interface FormValues {
  participantId: string;
}

const initialValues: FormValues = {
  participantId: "",
};
interface AddParticipantsFormProps {
  conversationId: string;
}
const AddParticipantsForm: React.FC<AddParticipantsFormProps> = ({
  conversationId,
}) => {
  const [create, { data, isLoading }] =
    useAddConversationParticipantsMutation();
  const handleSubmit = (values: FormValues) => {
    create({ conversationParticipantsId: [values.participantId], conversationId });
  };

  if(isLoading) return <h2>Loading...</h2>
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, errors, touched }) => (
        <Form>
          <SelectUser name="participantId" />
          <div className="flex items-center justify-between">
            <button
              className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddParticipantsForm;
