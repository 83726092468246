import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import React from "react";
import { Button, Label } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals/Modal";
import { Song } from "../../../../models/song.model";

import { SongSelect } from "../../../Song";
import { Option } from "../../../../types/general-types";
import {
  useCreateGroupSongMutation,
  useDeleteGroupSongMutation,
  CustomError,
} from "../../../../services/group.api";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";
interface ComponentProps {
  songs: Song[];
  groupId: string;
}

interface FormValues {
  song: Option | undefined;
  groupId: string;
}

export const GroupSong: React.FC<ComponentProps> = ({ songs, groupId }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [groupSong, setGroupSong] = React.useState<Song>();
  const [createGroupSong, { isError, error, isLoading, isSuccess }] =
    useCreateGroupSongMutation();

  const [
    deleteGroupSong,
    {
      isError: deleteGroupSongIsError,
      error: deleteGroupSongError,
      isLoading: deleteGroupSongIsLoading,
    },
  ] = useDeleteGroupSongMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isLoading, isSuccess, setOpen]);

  const initialValues: FormValues = {
    groupId: groupId,
    song: undefined,
  };

  const validationSchema = Yup.object().shape({
    groupId: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    createGroupSong({
      groupId: values.groupId,
      songId: values.song?.value || "",
    });
  };

  if (isLoading) <h2>Loading...</h2>;

  return (
    <div>
      <div className="p-4 border rounded shadow-sm">
        <div className="flex items-center justify-between pb-4 mb-4 border-b">
          <h2 className="text-xl font-semibold">Group Song</h2>
          <Button title="Add Song" onClick={() => setOpen(true)} />
        </div>
        <div className="">
          <ul className="grid justify-end grid-cols-3 mb-4 text-lg font-semibold">
            <li>Name</li>
            <li>Description</li>
            <li className="flex justify-end ">Action</li>
          </ul>
          <div>
            {songs.map((song: Song, index: number) => (
              <ul className="grid justify-end grid-cols-3 mb-2" key={index}>
                <li>{song.name}</li>
                <li>{song.description}</li>
                <li className="flex justify-end ">
                  <Button
                    title="Delete"
                    variant="warning"
                    onClick={() => {
                      setOpenDelete(true);
                      setGroupSong(song);
                    }}
                  />
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} title="Add Group Song" size="m">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="grid gap-2">
                {isError && error && (
                  <div className="text-red-500">
                    <h3 className="font-semibold">Error(s)</h3>
                    <ul>
                      {(error as CustomError).data.message.map(
                        (message: string) => (
                          <li>{message}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                <div className="grid grid-cols-2 gap-2">
                  <Label htmlFor="groups">Song</Label>
                  <SongSelect />
                </div>

                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
                >
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {groupSong && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Group Song`}
          size="s"
          action={deleteGroupSong}
          item={{
            groupId,
            songId: groupSong.id,
          }}
        />
      )}
    </div>
  );
};
