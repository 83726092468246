export enum VoucherType {
  REFERRAL = "REFERRAL",
  PARTNERSHIP = "PARTNERSHIP",
  PROMOTION = "PROMOTION",
}

export interface Voucher {
  id: string;
  name: string;
  description: string;
  type: string;
  expiredAt: string;
  discountPercentage: number;
  quantity: number;
  numberOfUse: number;
  code: string;
  brandId?: string;
  userId?: string;
  _count: {
    orders: number;
  };
}

export interface CreateVoucher {
  name: string;
  description: string;
  type: string;
  expiredAt: string;
  discountPercentage: number;
  quantity: number;
  code: string;
}

export interface UpdateVoucher {
  id: string;
  name: string;
  description: string;
  type: string;
  expiredAt: string;
  discountPercentage: number;
  quantity: number;
  code: string;
  brandId?: string | null;
}
