import React from "react";
import { Event } from "../../../../models/event.model";
import { Button } from "../../../../components/common";
import { Modal } from "../../../../components/common/Modals";
import CreateEventProduct from "./CreateEventProduct";
import EventProductList from "./EventProductList";

interface ComponentProps {
  event: Event;
}
const EventProduct: React.FC<ComponentProps> = ({ event }) => {
  const [addModelOpen, setAddModalOpen] = React.useState<boolean>(false);

  return (
    <div>
      <div className="flex justify-between my-2">
        <h1 className="text-2xl font-semibold">EventProduct</h1>
        <Button title="Add" onClick={() => setAddModalOpen(true)} />
      </div>
      <EventProductList event={event} />
      <Modal open={addModelOpen} setOpen={setAddModalOpen}>
        <CreateEventProduct event={event} setOpen={setAddModalOpen}/>
      </Modal>
    </div>
  );
};

export default EventProduct;
