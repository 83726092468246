import React from "react";
import { useParams } from "react-router-dom";
import TicketClaimList from "../../features/TicketClaim/TicketClaimList";

type FilteredTicketClaims = {
  id: string;
};

export const TicketClaimsPage = () => {
  const { id } = useParams<FilteredTicketClaims>() as FilteredTicketClaims;
  const [search, setSearch] = React.useState("");
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };
  return (
    <div>
      <div className="flex items-center justify-between p-6 mb-6 border rounded shadow-sm align">
        <h2 className="text-2xl font-semibold ">Ticket Claims</h2>
        <div className="flex items-center flex-1 px-6 ">
          <input
            className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
      <TicketClaimList id={id} search={search} />
    </div>
  );
};
