import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../components/common";
import { Genre } from "../../../models/genre.model";
import {
  useDeleteGenreMutation,
  useGetGenresQuery,
} from "../../../services/genre.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { UpdateGenre } from "../UpdateGenre";

interface ComponentProps {
  search?: string;
}
export const GenreList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [genre, setGenre] = React.useState<Genre>();
  const { data, isLoading } = useGetGenresQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteGenre] = useDeleteGenreMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li className="mr-4">ID</li>
          <li>Name</li>
          <li>Description</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((genre: Genre, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li className="mr-4">{genre.id}</li>
                <li>{genre.name}</li>
                <li>{genre.description}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setGenre(genre);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setGenre(genre);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {genre && (
        <Modal open={open} setOpen={setOpen} title="Update Genre" size="m">
          <UpdateGenre setOpen={setOpen} genreId={genre.id} />
        </Modal>
      )}
      {genre && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Genre`}
          size="s"
          action={deleteGenre}
          item={genre.id}
        />
      )}
    </div>
  );
};
