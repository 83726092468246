import React, { useState } from "react";
import { ChevronDown, ChevronUp, Tool, Copy } from "react-feather";

export interface ActionDropdownOptions {
  label: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  value?: any;
}

interface ComponentProps {
  setActive: (active: string) => void;
  id: string;
  active: string;
}

const CollapseButton: React.FC<ComponentProps> = ({
  setActive,
  id,
  active,
}) => {
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    if (id !== active) {
      setShow(false);
    }
  }, [active]);

  return (
    <>
      {show ? (
        <ChevronUp
          onClick={() => {
            setActive("");
            setShow(false);
          }}
          className="w-6 h-6 cursor-pointer text-blue-900"
        />
      ) : (
        <ChevronDown
          onClick={() => {
            setActive(id);
            setShow(true);
          }}
          className="w-6 h-6  cursor-pointer text-blue-900"
        />
      )}
    </>
  );
};

export default CollapseButton;
