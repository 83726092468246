import { CreateProductCategory, UpdateProductCategory, ProductCategory } from "../models/product-category.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ProductCategories {
  data: ProductCategory[];
  skip: number;
  take: number;
  count: number;
}

interface ProductsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const productCategoryApi = createApi({
  reducerPath: "productCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/product-category",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["ProductCategories", "ProductCategory"],
  endpoints: (builder) => ({
    createProductCategory: builder.mutation<ProductCategory, CreateProductCategory>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProductCategories"],
    }),
    getProductCategories: builder.query<ProductCategories, ProductsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["ProductCategories"],
    }),
    getProductCategory: builder.query<ProductCategory, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["ProductCategory"],
    }),
    updateProductCategory: builder.mutation<ProductCategory, UpdateProductCategory>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ProductCategories"],
    }),
    uploadProductCategoryImage: builder.mutation<ProductCategory, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["ProductCategories", "ProductCategory"],
    }),
    deleteProductCategory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategories"],
    }),
    removeProductCategoryPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCategories", "ProductCategory"],
    }),
  }),
});

export const {
  useCreateProductCategoryMutation,
  useGetProductCategoryQuery,
  useGetProductCategoriesQuery,
  useUpdateProductCategoryMutation,
  useUploadProductCategoryImageMutation,
  useDeleteProductCategoryMutation,
  useRemoveProductCategoryPhotoMutation,
  useLazyGetProductCategoriesQuery
} = productCategoryApi;
