import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../../components/common";


import * as Yup from "yup";
import { useCreateProductCategoryMutation, CustomError } from "../../../../services/product-category.api";

interface FormValues {
  name: string;
  description: string;
  slug: string;
  parentId: string;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateProductCategory: React.FC<ComponentProps> = ({
  setOpen,
}) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateProductCategoryMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    name: "",
    description: "",
    slug: "",
    parentId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    slug: Yup.string(),
    parentId: Yup.string(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input placeholder="Name" id="name" {...field} />
                )}
              </Field>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="slug">Slug</Label>
              <Field name="slug">
                {({ field }: FieldProps) => (
                  <Input placeholder="Slug (Optional)" id="slug" {...field} />
                )}
              </Field>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="description">Description</Label>
              <Field name="description">
                {({ field }: FieldProps) => (
                  <Input placeholder="Description" id="description" {...field} />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
