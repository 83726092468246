import {
  Ticket,
  CreateTicket,
  UpdateTicket,
  UpdateTicketStatus,
} from "../models/ticket.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Tickets {
  data: Ticket[];
  skip: number;
  take: number;
  count: number;
}

interface TicketsStatus {
  scanned: number;
  remaining: number;
}

interface TicketsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const ticketApi = createApi({
  reducerPath: "ticketApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/tickets",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Tickets", "Ticket", "ScannedTickets"],
  endpoints: (builder) => ({
    createTicket: builder.mutation<Ticket, CreateTicket>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Tickets"],
    }),
    getTickets: builder.query<Tickets, TicketsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Tickets"],
    }),
    getScannedTixByEventTicketId: builder.query<TicketsStatus, string>({
      query: (eventTicketId) => ({
        url: `/scanned/${eventTicketId}`,
      }),
      providesTags: ["ScannedTickets"],
    }),
    getTicket: builder.query<Ticket, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Ticket"],
    }),
    updateTicket: builder.mutation<Ticket, UpdateTicket>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Tickets", "Ticket"],
    }),
    updateTicketStatus: builder.mutation<Ticket, UpdateTicketStatus>({
      query: ({ id, ...body }) => ({
        url: `/${id}/status`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Tickets", "Ticket"],
    }),

    deleteTicket: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tickets"],
    }),
  }),
});

export const {
  useCreateTicketMutation,
  useGetTicketQuery,
  useGetScannedTixByEventTicketIdQuery,
  useGetTicketsQuery,
  useUpdateTicketMutation,
  useUpdateTicketStatusMutation,
  useDeleteTicketMutation,
} = ticketApi;
