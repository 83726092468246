import dayjs from "dayjs";
import { Formik, FormikHelpers, Form } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Button } from "../../../../components/common";
import {
  EventGroup,
  EventGroupEvent,
} from "../../../../models/event-group.model";
import {
  useAddEventGroupEventMutation,
  useGetEventGroupQuery,
  useRemoveEventGroupEventByIdMutation,
} from "../../../../services/event-group.api";
import { ConfirmModal } from "../../../../components/common/Modals/ConfirmModal";
import EventSelect from "../../../Event/EventSelect";

interface ComponentProps {
  eventGroupId: string;
}

interface FormValues {
  id: string;
  eventId: string;
}

const AddEventToEventGroup: React.FC<ComponentProps> = ({ eventGroupId }) => {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [eventGroupEvent, setEventGroupEvent] =
    React.useState<EventGroupEvent>();
  const [addEventGroupEvent, { isLoading, isError, error, isSuccess }] =
    useAddEventGroupEventMutation();
  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    addEventGroupEvent(values);
    values.eventId = "";
  };

  const { data: eventGroup, isLoading: eventGroupIsLoading } =
    useGetEventGroupQuery(eventGroupId);

  const [removeEventGroupEventById] = useRemoveEventGroupEventByIdMutation();

  const initialValues: FormValues = {
    id: eventGroup?.id || "",
    eventId: "",
  };

  const validationSchema = Yup.object().shape({
    eventId: Yup.string().required(),
  });

  if (isLoading) <p>Loading...</p>;

  return (
    <>
      {eventGroupEvent && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Event Group Event`}
          size="s"
          action={removeEventGroupEventById}
          item={eventGroupEvent.id}
        />
      )}
      <div className="my-5">
        <div className="grid grid-cols-6 font-semibold my-5 pb-5 border-b-2">
          <div>ID</div>
          <div className="col-span-2">Name</div>
          <div className="col-span-2">Date</div>
          <div className="col-span-1 flex justify-center">Actions</div>
        </div>
        <div className="max-h-64 min-h-36 overflow-y-scroll scrollbar-hide">
          {eventGroup?.eventGroupEvents.map((eventGroup, index) => {
            return (
              <div className="grid grid-cols-6 items-center">
                <div className="">{index + 1}</div>
                <div className="col-span-2">{eventGroup.event?.name}</div>
                <div className="col-span-2">
                  {dayjs(eventGroup.event?.dateTime).format(
                    "MMM DD YYYY HH:MM A"
                  )}
                </div>
                <div className="flex justify-center">
                  <button
                    className=" col-span-1 bg-blue-900 w-fit h-fit px-2 py-1 rounded-lg text-white"
                    onClick={() => {
                      setOpenDelete(true);
                      setEventGroupEvent(eventGroup);
                    }}
                  >
                    x
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <EventSelect
              excludeIds={eventGroup?.eventGroupEvents.map(
                (data) => data.eventId
              )}
            />
            <button
              type="submit"
              className="px-4 py-2 font-bold w-full text-white mt-5 bg-blue-900 border rounded"
            >
              Add Event to Event Group
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddEventToEventGroup;
