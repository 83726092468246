import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Input, Label } from "../../../components/common";
import {
  CustomError,
  useCreateEventMutation,
} from "../../../services/event.api";

import DatePicker from "../../../components/common/DatePicker";
import { Option } from "../../../types/general-types";
import GroupSelect from "../../Group/GroupSelect";
import PerformerSelect from "../../Performer/PerformerSelect";
import VenueSelect from "../../Venue/VenueSelect";
import CurrencySelect from "../CurrencySelect";

interface FormValues {
  name: string;
  description: string;
  venueId: string;
  performerIds: Option[];
  groupIds: Option[];
  title?: string;
  subTitle?: string;
  dateTime: string;
  eventOrderFee: number;
  discount: number;
  currency: string;
}
interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEvent: React.FC<ComponentProps> = ({ setOpen }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [onUploadFile, setOnUploadFile] = useState("");

  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateEventMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create({
      ...values,
      venueId: values.venueId,
      performerIds: values.performerIds.map((performer) => performer.value),
      groupIds: values.groupIds.map((group) => group.value),
    });
  };

  if (isLoading) return <h2>Loading...</h2>;

  const initialValues: FormValues = {
    name: "",
    description: "",
    venueId: "",
    performerIds: [],
    groupIds: [],
    dateTime: "",
    title: "",
    subTitle: "",
    eventOrderFee: 0,
    discount: 0,
    currency: "PHP",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    venueId: Yup.string().required(),
    dateTime: Yup.string().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input placeholder="Name" id="name" {...field} />
                )}
              </Field>
              <Label htmlFor="description">Description</Label>
              <Field name="description">
                {({ field }: FieldProps) => (
                  <Input
                    placeholder="Description"
                    id="description"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="title">Title</Label>
              <Field name="title">
                {({ field }: FieldProps) => (
                  <Input placeholder="Title" id="title" {...field} />
                )}
              </Field>
              <Label htmlFor="subTitle">Sub Title</Label>
              <Field name="subTitle">
                {({ field }: FieldProps) => (
                  <Input placeholder="Sub Title" id="subTitle" {...field} />
                )}
              </Field>
              <Label htmlFor="venue">Date and Time</Label>
              <DatePicker />
              <Label htmlFor="venue">Venue</Label>
              <VenueSelect />
              <Label htmlFor="start">Start</Label>
              <DatePicker name="start" />
              <Label htmlFor="end">End</Label>
              <DatePicker name="end" />
              <Label htmlFor="performers">Performer(s)</Label>
              <PerformerSelect isMulti />
              <Label htmlFor="groups">Group(s)</Label>
              <GroupSelect />
              <Label htmlFor="eventOrderFee">Order Fee</Label>
              <Field name="eventOrderFee">
                {({ field }: FieldProps) => (
                  <Input
                    type="number"
                    placeholder="Order Fee"
                    id="eventOrderFee"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="discount">Discount</Label>
              <Field name="discount">
                {({ field }: FieldProps) => (
                  <Input
                    type="number"
                    placeholder="Discount"
                    id="discount"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="isReseller">Is Reseller</Label>
              <Field name="isReseller">
                {({ field, form }: FieldProps) => (
                  <button
                    type="button"
                    className={`px-4 py-2 font-bold text-white 
                       border rounded ${
                         field.value ? "bg-green-400" : "bg-red-400"
                       }`}
                    onClick={() => form.setFieldValue(field.name, !field.value)}
                  >
                    {field.value ? "Reseller" : "Not Reseller"}
                  </button>
                )}
              </Field>
              <Label htmlFor="currency">Currency</Label>
              <CurrencySelect name="currency" />
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateEvent;
