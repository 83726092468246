import { CreateProduct, UpdateProduct, Product } from "../models/product.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Products {
  data: Product[];
  skip: number;
  take: number;
  count: number;
}

interface ProductsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/products",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Products", "Product"],
  endpoints: (builder) => ({
    createProduct: builder.mutation<Product, CreateProduct>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    getProducts: builder.query<Products, ProductsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Products"],
    }),
    getProduct: builder.query<Product, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Product"],
    }),
    updateProduct: builder.mutation<Product, UpdateProduct>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Products", "Product"],
    }),
    uploadProductImage: builder.mutation<Product, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Products", "Product"],
    }),
    deleteProduct: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products"],
    }),
    removeProductPhoto: builder.mutation<void, { id: string, imageId: string }>({
      query: ({ id, imageId }) => ({
        url: `/${id}/image/${imageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products", "Product"],
    }),
  }),
});

export const {
  useCreateProductMutation,
  useGetProductQuery,
  useGetProductsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
  useDeleteProductMutation,
  useRemoveProductPhotoMutation,
} = productApi;
