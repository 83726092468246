import { Order } from "../models/order.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { EmailMarketing } from "../models/email-marketing.model";
import { User } from "../models/user.model";
import { EventTicket } from "../models";

export interface CustomError {
  data: {
    message: [string];
  };
}

export interface OrderDetails {
  quantity: number;
  transactionType: string;
  type: string;
  currency: string;
  amount: number;
  total: number;
  order: Order;
  eventTicketId: string;
  eventTicketType: EventTicket;
}

interface OrderDetailsQuery {
  orderId: string;
}

export const orderDetailsApi = createApi({
  reducerPath: "orderDetailsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/order-details",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["OrderDetails", "Order"],
  endpoints: (builder) => ({
    getAllOrderDetailsByOrderId: builder.query<
      OrderDetails[],
      OrderDetailsQuery
    >({
      query: ({ orderId }) => {
        return {
          url: `/${orderId}`,
        };
      },
      providesTags: ["OrderDetails"],
    }),
  }),
});

export const { useGetAllOrderDetailsByOrderIdQuery } = orderDetailsApi;
