import React from "react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  EventTicketRequest,
  EventTicket,
  EventTicketType,
  SeatType,
} from "../../../../models/event-ticket.model";
import { CustomError } from "../../../../services/event.api";
import { Checkbox } from "../../../../components/common";
import TNSelect from "../../../../components/common/TNSelect";
import ToggleSwitch from "../../../../components/common/ToggleSwitch";
import DynamicForm, {
  FieldDefinition,
} from "../../../../components/common/DynamicForm";
import SelectEventTicket from "../../../EventTIcket/SelectUser";
import * as Yup from "yup";
interface ComponentProps {
  eventId: string;
  isError?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  error?: CustomError | SerializedError | undefined;
  onSubmit?: (values: EventTicketRequest) => void;
  eventTicket?: EventTicket;
  disabledInputs?: string[];
  buttonText?: string;
}

export const EventTicketForm: React.FC<ComponentProps> = ({
  eventId,
  isError,
  isSuccess,
  isLoading,
  error,
  onSubmit,
  eventTicket,
  disabledInputs,
  buttonText = "Submit",
}) => {
  const fields: FieldDefinition[] = [
    {
      name: "name",
      type: "text",
      label: "Name",
      required: true,
      defaultValue: eventTicket?.name || "",
    },
    {
      name: "description",
      type: "text",
      label: "Description",
      required: true,
      defaultValue: eventTicket?.description || "",
    },
    {
      name: "type",
      type: "select",
      label: "Type",
      required: true,
      defaultValue: eventTicket?.type || EventTicketType.GENERAL_ADMISSION,
      customInput: (
        <TNSelect
          name="type"
          options={[
            {
              label: "Reserved Seat",
              value: EventTicketType.RESERVED_SEAT,
            },
            {
              label: "General Admission",
              value: EventTicketType.GENERAL_ADMISSION,
            },
            {
              label: "VIP",
              value: EventTicketType.VIP,
            },
          ]}
        />
      ),
    },
    {
      name: "quantity",
      type: "number",
      label: "Quantity",
      required: true,
      defaultValue: eventTicket?.quantity || "",
    },
    {
      name: "price",
      type: "number",
      label: "Price",
      required: true,
      defaultValue: eventTicket?.price || "",
    },
    {
      name: "originalPrice",
      type: "number",
      label: "Original Price",
      required: false,
      defaultValue: eventTicket?.originalPrice || "0",
    },
    {
      name: "perTicketFee",
      type: "number",
      label: "Per Ticket Fee",
      required: false,
      defaultValue: eventTicket?.perTicketFee || "0",
    },
    {
      name: "multiplier",
      type: "number",
      label: "Multiplier",
      required: false,
      defaultValue: eventTicket?.multiplier || "",
    },
    {
      name: "commissionPercentage",
      type: "number",
      label: "Commission Percentage",
      required: true,
      defaultValue: eventTicket?.commissionPercentage || 0,
    },
    {
      name: "color",
      type: "text",
      label: "BG Color",
      required: true,
      defaultValue: eventTicket?.color || "white",
    },
    {
      name: "fontColor",
      type: "text",
      label: "Font Color",
      required: true,
      defaultValue: eventTicket?.fontColor || "rgb(0, 78, 146)",
    },
    {
      name: "section",
      type: "text",
      label: "Section",
      required: true,
      defaultValue: eventTicket?.section || "",
    },
    {
      name: "row",
      type: "text",
      label: "Row",
      required: true,
      defaultValue: eventTicket?.row || "",
    },
    {
      name: "seatType",
      type: "select",
      label: "Seat Type",
      required: true,
      defaultValue: eventTicket?.seatType || SeatType.CONSECUTIVE,
      customInput: (
        <TNSelect
          name="seatType"
          options={[
            {
              label: "Consecutive",
              value: SeatType.CONSECUTIVE,
            },
            {
              label: "Odd Even",
              value: SeatType.ODDEVEN,
            },
          ]}
        />
      ),
    },
    {
      name: "additionalEvents",
      type: "text",
      label: "Additional Events",
      required: false,
      defaultValue: eventTicket?.mainEventTicketRelations.map(
        (eventTicketRelation) => {
          return {
            label: eventTicketRelation.additionalEventTicket.name,
            value: eventTicketRelation.additionalEventTicketId,
          };
        }
      ),
      customValidation: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required("Required"),
          value: Yup.string().required("Required"),
        })
      ),
      customInput: (
        <SelectEventTicket
          excludeIds={eventTicket?.mainEventTicketRelations.map(
            (eventTicketRelation) => {
              return eventTicketRelation.additionalEventTicketId;
            }
          )}
        />
      ),
    },
    {
      name: "published",
      type: "toggle",
      label: "Publish",
      required: false,
      defaultValue: eventTicket?.published || false,
      customInput: <ToggleSwitch />,
    },
    {
      name: "isMultipleEvent",
      type: "toggle",
      label: "Is Multiple Event",
      required: false,
      defaultValue: eventTicket?.isMultipleEvent || false,
      customInput: <ToggleSwitch />,
    },
    {
      name: "isHotDeal",
      type: "toggle",
      label: "Is Hot Deal",
      required: false,
      defaultValue: eventTicket?.isHotDeal || false,
      customInput: <ToggleSwitch />,
    },
  ];

  const handleSubmit = (values: any) => {
    if (onSubmit) {
      onSubmit({
        ...values,
        additionalEvents: values.additionalEvents?.map(
          (event: any) => event.value
        ),
        eventId: eventId,
        id: eventTicket?.id,
      });
    }
  };

  const errorIsString =
    error && typeof (error as CustomError).data.message === "string";

  return (
    <div>
      {isError && error && (
        <div className="text-red-500">
          <h3 className="font-semibold">Error(s)</h3>
          <ul>
            {errorIsString
              ? (error as CustomError).data.message
              : (error as CustomError).data.message.map(
                  (message) => (error as CustomError).data.message
                )}
          </ul>
        </div>
      )}
      <DynamicForm buttonText={buttonText} fields={fields} onSubmit={handleSubmit} />
    </div>
  );
};
