import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface ComponentProps {
  name?: string;
  minDate?: Date;
}

export const DatePicker: React.FC<ComponentProps> = ({
  name = "dateTime",
  minDate,
}) => {
  return (
    <div>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <Datepicker
            {...field}
            selected={field.value}
            onChange={(date: Date) => form.setFieldValue(field.name, date)}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeSelect
            className="w-full px-4 py-2 border rounded z-100"
            placeholderText="Date and Time"
            popperClassName="!z-100"
            minDate={minDate}
          />
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default DatePicker;
