import { RootState } from "./../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/auth",
    prepareHeaders: (headers, { getState, endpoint }) => {
      let jwtToken;
      if (endpoint === "refresh" || endpoint === "logout") {
        jwtToken = localStorage.getItem("refreshToken");
      } else {
        jwtToken = (getState() as RootState).auth.refreshToken;
      }
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      headers.set("access_as", "ADMIN");
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (body) => ({
        url: "signup",
        method: "POST",
        body,
      }),
    }),
    signin: builder.mutation({
      query: (body) => ({
        url: "signin",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
      }),
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "refresh",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useSigninMutation,
  useLogoutMutation,
  useRefreshMutation,
} = authApi;
