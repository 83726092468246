import React from "react";
// import {
//   useDeleteOrganizationMutation,
//   useGetOrganizationsQuery,
// } from "../../../services/organization.api";
import { Link } from "react-router-dom";
import { OrganizationApiKey } from "../../../../../models/organization.model";
import { ConfirmModal, Modal } from "../../../../../components/common/Modals";
import { PaginationContainer } from "../../../../../components/common";
import {
  useDeleteOrganizationApiKeyMutation,
  useGetOrganizationApiKeysQuery,
} from "../../../../../services/organization.api";

interface ComponentProps {
  search?: string;
  organizationId: string;
}
const OrganizationApiKeyList: React.FC<ComponentProps> = ({
  search,
  organizationId,
}) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [organization, setOrganization] = React.useState<OrganizationApiKey>();
  const { data, isLoading } = useGetOrganizationApiKeysQuery({
    skip,
    take,
    search: search || "",
    organizationId,
  });
  const [
    deleteOrganization,
    { isSuccess: isSuccessDelete, isLoading: isDeleteLoading },
  ] = useDeleteOrganizationApiKeyMutation();
  const handlePageChange = (value: number) => {
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Key</li>
          <li>Status</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map(
              (organization: OrganizationApiKey, index: number) => (
                <ul
                  className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                  key={index}
                >
                  <li>
                    <ul className="flex items-center gap-1">
                      <li>
                        <button
                          className="px-2 py-1 border rounded-md"
                          onClick={() =>
                            navigator.clipboard.writeText(organization.id || "")
                          }
                        >
                          COPY ID
                        </button>
                      </li>
                    </ul>
                  </li>
                  <li>{organization.name}</li>
                  <li>{organization.apiKey}</li>

                  <li className="grid gap-1 text-white lg:grid-cols-3">
                    <Link
                      to={`${organization.id}/view`}
                      className="px-4 py-2 bg-green-400 rounded"
                    >
                      View
                    </Link>
                    <button
                      className="px-4 py-2 bg-yellow-400 rounded"
                      onClick={() => {
                        setOpen(true);
                        setOrganization(organization);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="px-4 py-2 bg-red-400 rounded"
                      onClick={() => {
                        setOpenDelete(true);
                        setOrganization(organization);
                      }}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              )
            )}
          </div>
        </PaginationContainer>
      )}
      {/* {organization && (
        <Modal
          open={open}
          setOpen={setOpen}
          title="Update Organization"
          size="m"
        >
          <UpdateOrganization
            setOpen={setOpen}
            organizationId={organization.id}
          />
        </Modal>
      )} */}
      {organization && openDelete && (
        <ConfirmModal
          isLoading={isDeleteLoading}
          isSuccess={isSuccessDelete}
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Organization`}
          size="s"
          action={(id: string) => {
            deleteOrganization({ id, organizationId });
          }}
          item={organization.id}
        />
      )}
    </div>
  );
};

export default OrganizationApiKeyList;
