import {
  Organization,
  OrganizationApiKey,
} from "./../models/organization.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { Event } from "../models/event.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Organizations {
  data: Organization[];
  skip: number;
  take: number;
  count: number;
}

interface OrganizationApiKeys {
  data: OrganizationApiKey[];
  skip: number;
  take: number;
  count: number;
}

interface Events {
  data: Event[];
  skip: number;
  take: number;
  count: number;
}

interface OrganizationsQuery {
  skip?: number;
  take?: number;
  search?: string;
}
interface OrganizationApiKeysQuery {
  skip?: number;
  take?: number;
  search?: string;
  organizationId: string;
}

interface OrganizationEventsQuery {
  skip?: number;
  take?: number;
  search?: string;
  id: string;
}

interface DeleteOrganizationApiKey {
  id: string;
  organizationId: string;
}

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/organization",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: [
    "Organizations",
    "Organization",
    "OrganizationEvents",
    "OrganizationApiKeys",
  ],
  endpoints: (builder) => ({
    createOrganization: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Organizations"],
    }),
    getOrganizationEvents: builder.query<Events, OrganizationEventsQuery>({
      query: ({ take, skip, search, id }) => {
        return {
          url: `${id}/events`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["OrganizationEvents"],
    }),
    getOrganizations: builder.query<Organizations, OrganizationsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Organizations"],
    }),
    createOrganizationApiKey: builder.mutation({
      query: ({ organizationId, ...body }) => ({
        url: `/${organizationId}/api-key`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrganizationApiKeys"],
    }),
    getOrganizationApiKeys: builder.query<
      OrganizationApiKeys,
      OrganizationApiKeysQuery
    >({
      query: ({ take, skip, search, organizationId }) => {
        return {
          url: `/${organizationId}/api-key`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["OrganizationApiKeys"],
    }),

    deleteOrganizationApiKey: builder.mutation<void, DeleteOrganizationApiKey>({
      query: ({ id, organizationId }) => ({
        url: `/${organizationId}/api-key/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrganizationApiKeys"],
    }),
    getOrganization: builder.query<Organization, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Organization", "Organizations"],
    }),
    updateOrganization: builder.mutation<Organization, Organization>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Organizations"],
    }),
    uploadOrganizationImage: builder.mutation<
      Organization,
      { id: string; file: File }
    >({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Organizations", "Organization"],
    }),
    deleteOrganization: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organizations"],
    }),
    removeOrganizationPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organizations", "Organization"],
    }),
  }),
});

export const {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useGetOrganizationsQuery,
  useGetOrganizationEventsQuery,
  useUpdateOrganizationMutation,
  useUploadOrganizationImageMutation,
  useDeleteOrganizationMutation,
  useRemoveOrganizationPhotoMutation,
  useGetOrganizationApiKeysQuery,
  useDeleteOrganizationApiKeyMutation,
  useCreateOrganizationApiKeyMutation,
} = organizationApi;
