import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetSongsQuery } from "../../../services/song.api";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  isMulti?: boolean
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
export const SongSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  isMulti = false,
  ...props
}) => {
  const { data: songs, isLoading } = useGetSongsQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!songs) return <h2>Something went Wrong</h2>;

  const options = songs.data.map((song) => {
    return {
      label: song.name,
      value: song.id,
    };
  });

  const getValues = () => {
    return songs.data
      .filter((song) => defaultValue.includes(song.id))
      .map((song) => ({
        label: song.name,
        value: song.id,
      }));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name="song">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="song"
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="song">
        {(message) => <span className="text-red-500">Song is required</span>}
      </ErrorMessage>
    </div>
  );
};

