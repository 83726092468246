import { CreateVoucher, UpdateVoucher, Voucher } from "../models/voucher.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Vouchers {
  data: Voucher[];
  skip: number;
  take: number;
  count: number;
}

interface VouchersQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const voucherApi = createApi({
  reducerPath: "voucherApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/vouchers",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Vouchers", "Voucher"],
  endpoints: (builder) => ({
    createVoucher: builder.mutation<Voucher, CreateVoucher>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Vouchers"],
    }),
    getVouchers: builder.query<Vouchers, VouchersQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Vouchers"],
    }),
    getVoucher: builder.query<Voucher, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Voucher"],
    }),
    updateVoucher: builder.mutation<Voucher, UpdateVoucher>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Vouchers", "Voucher"],
    }),

    deleteVoucher: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Vouchers"],
    }),
  }),
});

export const {
  useCreateVoucherMutation,
  useGetVoucherQuery,
  useGetVouchersQuery,
  useUpdateVoucherMutation,
  useDeleteVoucherMutation,
} = voucherApi;
