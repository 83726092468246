import { CreateProductVariant, UpdateProductVariant, ProductVariant } from "../models/product-variant.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface ProductVariants {
  data: ProductVariant[];
  skip: number;
  take: number;
  count: number;
}

interface ProductsQuery {
  skip?: number;
  take?: number;
  search?: string;
  productId?: string;
}

export const productVariantApi = createApi({
  reducerPath: "productVariantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/products",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["ProductVariants", "ProductVariant"],
  endpoints: (builder) => ({
    createProductVariant: builder.mutation<ProductVariant, CreateProductVariant>({
      query: ({ productId, ...body }) => ({
        url: `/${productId}/variant`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProductVariants"],
    }),
    getProductVariants: builder.query<ProductVariants, ProductsQuery>({
      query: ({ take, skip, search, productId }) => {
        return {
          url: `/${productId}/variant`,
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["ProductVariants"],
    }),
    getProductVariant: builder.query<ProductVariant, { id: string; productId: string }>({
      query: ({id, productId}) => ({
        url: `/${productId}/variant/${id}`,
      }),
      providesTags: ["ProductVariant"],
    }),
    updateProductVariant: builder.mutation<ProductVariant, UpdateProductVariant>({
      query: ({ id, productId, ...body }) => ({
        url: `/${productId}/variant/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ProductVariants"],
    }),
    uploadProductVariantImage: builder.mutation<ProductVariant, { id: string; file: File, productId: string }>({
      query: ({ id, productId, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${productId}/variant/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["ProductVariants", "ProductVariant"],
    }),
    deleteProductVariant: builder.mutation<void, { id: string, productId: string }>({
      query: ({ productId, id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductVariants"],
    }),
    removeProductVariantPhoto: builder.mutation<void, { id: string, productId: string, productVariantImageId:string }>({
      query: ({ productId, id, productVariantImageId }) => ({
        url: `/${productId}/variant/${id}/image/${productVariantImageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductVariants", "ProductVariant"],
    }),
  }),
});

export const {
  useCreateProductVariantMutation,
  useGetProductVariantQuery,
  useGetProductVariantsQuery,
  useUpdateProductVariantMutation,
  useUploadProductVariantImageMutation,
  useDeleteProductVariantMutation,
  useRemoveProductVariantPhotoMutation,
  useLazyGetProductVariantsQuery
} = productVariantApi;
