import { CreateCarousel, UpdateCarousel, Carousel } from "../models";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CarouselCustomError {
  data: {
    message: [string];
  };
}

interface Carousels {
  data: Carousel[];
  skip: number;
  take: number;
  count: number;
}

interface CarouselsQuery {
  skip?: number;
  take?: number;
  search?: string;
  status?: string;
}

export const carouselApi = createApi({
  reducerPath: "carouselApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/carousels",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CarouselCustomError>,
  tagTypes: ["Carousels", "Carousel"],
  endpoints: (builder) => ({
    createCarousel: builder.mutation<Carousel, CreateCarousel>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Carousels"],
    }),
    getCarousels: builder.query<Carousels, CarouselsQuery>({
      query: ({ take, skip, search, status }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
            status
          },
        };
      },
      providesTags: ["Carousels"],
    }),
    getCarousel: builder.query<Carousel, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Carousel"],
    }),
    updateCarousel: builder.mutation<Carousel, UpdateCarousel>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Carousels", "Carousel"],
    }),
    uploadCarouselImage: builder.mutation<Carousel, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Carousels", "Carousel"],
    }),
    deleteCarousel: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Carousels"],
    }),
    removeCarouselPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Carousels", "Carousel"],
    }),
  }),
});

export const {
  useCreateCarouselMutation,
  useGetCarouselQuery,
  useGetCarouselsQuery,
  useUpdateCarouselMutation,
  useUploadCarouselImageMutation,
  useDeleteCarouselMutation,
  useRemoveCarouselPhotoMutation,
} = carouselApi;
