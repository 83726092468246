import React, { useEffect, useState } from "react";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ActionDropdownOptions {
  label: string;
  value?: any;
  onClick?: () => void;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
}

interface ComponentProps {
  options: ActionDropdownOptions[];
  placeholder?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  onChange?: (value: any) => void;
  defaultValue?: any;
  isNew?: boolean;
  newFeatureMessage?: string;
  setValue: (value: any) => void;
}

const Dropdown: React.FC<ComponentProps> = ({
  options,
  placeholder,
  icon,
  iconPosition,
  onChange,
  setValue,
  defaultValue,
  isNew,
  newFeatureMessage,
}) => {
  const [show, setShow] = useState(false);
  const [newValue, setNewValue] = useState(defaultValue);

  useEffect(() => {
    if (onChange) {
      onChange(newValue);
    }
  }, [newValue, onChange]);

  return (
    <>
      <div
        className="relative cursor-pointer w-72"
        // onMouseLeave={() => setShow(false)}
      >
        {isNew && (
          <div className="absolute p-1 text-xs text-white bg-red-500 rounded-full -right-3 -top-4">
            {newFeatureMessage || "New"}
          </div>
        )}
        <div
          className={`flex items-center w-full p-1 border-2 border-blue-900 rounded-lg ${
            iconPosition === "right" && "flex-row-reverse"
          }`}
          onClick={() => setShow(!show)}
        >
          {icon && <div className="ml-2 mr-2 text-blue-900">{icon}</div>}
          {placeholder ? placeholder : "Action Dropdown"}
        </div>
        <div
          className={`absolute top-[42px] left-0 right-0 bg-white shadow-md shadow-gray-400 rounded-md grid overflow-y-scroll scrollbar-hide ease-in-out transition-all duration-[0.1s] z-30  ${
            show ? "max-h-[200px]" : "max-h-0"
          }`}
        >
          {options.length > 0 &&
            options.map((opt, index) => (
              <div
                key={index}
                className={`flex items-center p-2 transition-all duration-200 hover:bg-gray-100 hover:text-blue-90 ${
                  opt.iconPosition && "flex-row-reverse"
                }`}
                onClick={() => {
                  setNewValue(opt.value);
                  setShow(false);
                  if (opt.onClick) {
                    opt.onClick();
                  }
                }}
              >
                {opt.icon && <div className="ml-2 mr-2">{opt.icon}</div>}
                {opt.label}
              </div>
            ))}
        </div>
      </div>
      <div
        className="ml-2 mr-2 text-blue-900 cursor-pointer"
        onClick={() => {
          setValue("");
          setNewValue("");
        }}
      >
        <FontAwesomeIcon icon={faX} title="Remove date filter" />
      </div>
    </>
  );
};

export default Dropdown;
