import React, { useRef } from "react";

interface ImageUploaderProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBrowseClick: () => void;
  images: Image[];
  onRemoveClick: (id: string) => void;
}

interface Image {
  id: string;
  fileName: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onFileChange,
  onBrowseClick,
  images,
  onRemoveClick,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const handleBrowse = () => {
    ref.current?.click();
  };

  return (
    <div className="flex items-center">
      <div className="grid grid-cols-3 gap-1">
        <div className="col-span-3">
          <input
            name="upload"
            type="file"
            onChange={onFileChange}
            style={{ display: "none" }}
            ref={ref}
          />
          <button
            className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
            type="button"
            onClick={handleBrowse}
          >
            Add Photo
          </button>
          <p className="col-span-2 text-xs">
            Image should be at least 400 x 400px as a png or jpeg file.
          </p>
        </div>
        {images &&
          images.map((image: Image, index: number) => {
            return (
              <div key={image.id}>
                <img
                  className="object-cover w-48 h-48"
                  alt={`image_${index}`}
                  src={process.env.REACT_APP_IMAGE_BASE_URL + image.fileName}
                />
                <button
                  className="w-full px-4 py-2 text-white bg-red-500 border rounded"
                  type="button"
                  onClick={() => onRemoveClick(image.id)}
                >
                  Remove Photo
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ImageUploader;
