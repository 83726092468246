import React, { useState } from "react";
import { Event } from "../../../../models/event.model";
import { Tool, Copy } from "react-feather";
import DynamicTable, { TableData } from "../../../../components/common/Table";
import { useGetEventProductOrdersQuery } from "../../../../services/product-order.api";
import { ProductOrder } from "../../../../models/product-order.model";
import dayjs from "dayjs";
import ActionDropdown from "../../../../components/common/ActionDropdown";
import CollapseButton from "../../../../components/common/CollapseButton";
import ProductOrderVariantPage from "./ProductOrderVariant";

interface ComponentProps {
  event: Event;
}
const EventProductOrdersList: React.FC<ComponentProps> = ({ event }) => {
  const [page, setPage] = React.useState(1);
  const [take] = useState(10);
  const [active, setActive] = React.useState("");
  const [skip, setSkip] = useState(0);
  const { data } = useGetEventProductOrdersQuery({
    id: event.id,
    take,
    skip,
  });
  const columns = [
    {
      key: "email",
      label: "Email",
    },
    {
      key: "orderDate",
      label: "Order Date",
    },
    {
      key: "total",
      label: "Total",
    },
    {
      key: "isClaimed",
      label: "Is Claimed",
    },
    {
      key: "ticketType",
      label: "Ticket Type",
    },
    {
      key: "dropdown",
      label: "",
    },
  ];

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  const tableData: TableData[] =
    data?.data
      .filter(({ subtotal }) => subtotal !== 0)
      .map((item: ProductOrder, index) => ({
        id: item.id,
        email: item.Order.user.email,
        orderDate: dayjs(item.Order.createdAt).format("DD/MM/YYYY hh:mm A"),
        total: item.subtotal,
        ticketType: item.Order?.orderEventTickets
          .map(
            (odt) =>
              `${
                item.Order.orderEventTickets.length > 1
                  ? odt.tickets.length
                  : ""
              } ${odt.eventTicket.section}`
          )
          .join(", "),
        isClaimed: (
          <div className={`flex justify-center  `}>
            <div
              className={`px-2 py-1 font-semibold text-white rounded-md w-fit ${
                item.isClaimed ? "bg-green-400" : "bg-red-400"
              }`}
            >
              {item.isClaimed ? "CLAIMED" : "NOT CLAIM"}
            </div>
          </div>
        ),
        dropdown: (
          <CollapseButton setActive={setActive} id={item.id} active={active} />
        ),
      })) || [];

  return (
    <div>
      <DynamicTable
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={take}
        total={data?.count || 0}
        columns={columns}
        data={tableData}
        actionComponent={({ id }) => {
          const productOrder = data?.data.find(
            (productOrder) => productOrder.id === id
          );
          if (!productOrder) return null;
          return (
            <div className="p-2 text-sm text-gray-700 break-words">
              <ActionDropdown
                placeholder="Actions"
                icon={<Tool className="w-5 h-5" />}
                options={[
                  {
                    label: "Copy ID",
                    onClick: () => {
                      navigator.clipboard.writeText(productOrder.id);
                    },
                    icon: <Copy className="w-5 h-5" />,
                  },
                ]}
              />
            </div>
          );
        }}
        isCollapsible
        active={active}
        children={({ id }) => {
          if (!id) return null;
          return <ProductOrderVariantPage id={id} />;
        }}
      />
    </div>
  );
};

export default EventProductOrdersList;
