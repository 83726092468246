import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import { PaginationContainer } from "../../../components/common";
import { Brand } from "../../../models/brand.model";
import {
  useDeleteBrandMutation,
  useGetBrandsQuery,
} from "../../../services/brand.api";
import { ConfirmModal } from "../../../components/common/Modals/ConfirmModal";
import { UpdateBrand } from "../UpdateBrand";

interface ComponentProps {
  search?: string;
}
export const BrandList: React.FC<ComponentProps> = ({ search }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [skip, setSkip] = React.useState(0);
  const [take] = React.useState(5);
  const [brand, setBrand] = React.useState<Brand>();
  const { data, isLoading } = useGetBrandsQuery({
    skip,
    take,
    search: search || "",
  });
  const [deleteBrand] = useDeleteBrandMutation();
  const handlePageChange = (value: number) => {
    console.log("value", value);
    setPage(value);
  };

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  if (isLoading) return <h2>Loading...</h2>;

  return (
    <div>
      <div>
        <ul className="grid grid-cols-4 p-4 font-semibold">
          <li>
            <ul className="flex items-center">
              <li className="mr-4">ID</li>
              <li>Photo</li>
            </ul>
          </li>
          <li>Name</li>
          <li>Description</li>
          <li>Action</li>
        </ul>
      </div>
      {data && (
        <PaginationContainer
          totalCount={data.count}
          currentPage={page}
          pageSize={take}
          onPageChange={handlePageChange}
        >
          <div className="grid gap-1">
            {data?.data?.map((brand: Brand, index: number) => (
              <ul
                className="grid items-center grid-cols-4 p-4 border rounded shadow-sm"
                key={index}
              >
                <li>
                  <ul className="flex items-center">
                    <li className="mr-4">{brand.id}</li>
                    <li>
                      <div className="flex items-center justify-center mr-4 overflow-hidden bg-black rounded w-14 h-14">
                        {brand && brand.image ? (
                          <img
                            alt={`${brand.name}_image`}
                            src={
                              process.env.REACT_APP_IMAGE_BASE_URL +
                              brand.image?.fileName
                            }
                          />
                        ) : (
                          <div className="bg-black " />
                        )}
                      </div>
                    </li>
                  </ul>
                </li>
                <li>{brand.name}</li>
                <li>{brand.description}</li>

                <li className="grid gap-1 text-white lg:grid-cols-3">
                  <button className="px-4 py-2 bg-green-400 rounded">
                    View
                  </button>
                  <button
                    className="px-4 py-2 bg-yellow-400 rounded"
                    onClick={() => {
                      setOpen(true);
                      setBrand(brand);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="px-4 py-2 bg-red-400 rounded"
                    onClick={() => {
                      setOpenDelete(true);
                      setBrand(brand);
                    }}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </PaginationContainer>
      )}
      {brand && (
        <Modal open={open} setOpen={setOpen} title="Update Brand" size="m">
          <UpdateBrand setOpen={setOpen} brandId={brand.id} />
        </Modal>
      )}
      {brand && openDelete && (
        <ConfirmModal
          open={openDelete}
          setOpen={setOpenDelete}
          title={`Delete Brand`}
          size="s"
          action={deleteBrand}
          item={brand.id}
        />
      )}
    </div>
  );
};
