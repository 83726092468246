import React from "react";
import { useNavigate } from "react-router-dom";
import { DashboardCard } from "../../../../components/common";

interface ComponentProps {
  data: number;
  title?: string;
  content?: string;
  link: string;
}

const OverviewCard: React.FC<ComponentProps> = ({
  data,
  title,
  content,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <DashboardCard
      title={title || ""}
      className="flex flex-col items-center justify-center"
    >
      <span className="text-5xl font-semibold text-lightBlue-500">{data}</span>
      <h3 className="mb-4 text-xl font-medium">{content}</h3>
      <div className="flex w-full lg:justify-end">
        <button
          onClick={() => navigate(`/${link}`)}
          className="w-full px-12 py-2 font-medium tracking-wide text-white text- bg-lightBlue-500 rounded-[8px]"
        >
          View
        </button>
      </div>
    </DashboardCard>
  );
};

export default OverviewCard;
