import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import { Input, Label } from "../../../components/common";
import { CustomError, useSigninMutation } from "../../../services/auth.api";
import { setUser } from "../authSlice";
import { useAppDispatch } from "../../../app/hooks";
import TicketNationLogo from "../../../assets/logo/ticket-nation-admin-2.svg";

interface FormValues {
  email: string;
  password: string;
}

const SigninForm: React.FC<{ className?: string }> = ({ className }) => {
  const dispatch = useAppDispatch();
  const [signin, { data, error, isError, isSuccess, isLoading }] =
    useSigninMutation();
  const initialValues: FormValues = {
    email:
      process.env.REACT_APP_INIT_EMAIL === "default"
        ? ""
        : process.env.REACT_APP_INIT_EMAIL || "",
    password:
      process.env.REACT_APP_INIT_PASSWORD === "default"
        ? ""
        : process.env.REACT_APP_INIT_PASSWORD || "",
  };

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    signin(values);
  };

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      localStorage.setItem("refreshToken", data.refreshToken);
      dispatch(
        setUser({
          refreshToken: data.refreshToken,
          accessToken: data.accessToken,
        })
      );
    }
  }, [isSuccess, isLoading, data, dispatch]);

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validate={() => {}}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className={className}>
          <div className="flex items-center justify-center flex-1 w-full col-span-2 p-2 lg:p-10 lg:col-span-1">
            <div className="flex-1 gap-y-10">
              <div className="">
                <div className="flex justify-center mb-8">
                  <img
                    className=""
                    src={TicketNationLogo}
                    alt="TicketNationLogo"
                  />
                </div>
                <div className="flex justify-center mb-8">
                  <h2
                    className="font-semibold text-[38px] tracking-[0.08em] h-[47px] left-[234px] top-[406px] normal-case leading-10 text-center"
                    style={{ color: "#1C1C1C" }}
                  >
                    SIGN IN
                  </h2>
                </div>
              </div>
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  {typeof (error as CustomError).data.message === "string" ? (
                    (error as CustomError).data.message
                  ) : (
                    <ul>
                      {(error as CustomError).data.message.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              <div className="grid justify-center grid-rows-4 gap-1 mb-10">
                <Field name="email">
                  {({ field }: FieldProps) => (
                    <div className="row-span-2">
                      <Input
                        style={{
                          fontFamily: "ClashDisplay",
                          height: "48px",
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                          fontWeight: "500",
                        }}
                        placeholder="E-Mail"
                        id="email"
                        {...field}
                      />
                    </div>
                  )}
                </Field>
                <div className="row-span-2">
                  <Field name="password" className="h-[48px]">
                    {({ field }: FieldProps) => (
                      <Input
                        style={{
                          fontFamily: "ClashDisplay",
                          height: "48px",
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
                          fontWeight: "500",
                          marginBottom: "10px",
                        }}
                        placeholder="Password"
                        type="password"
                        id="password"
                        {...field}
                      />
                    )}
                  </Field>
                  <p
                    className="font-medium text-[16px] text-center leading-[19.68px]"
                    style={{
                      fontFamily: "ClashDisplay",
                    }}
                  >
                    Forgot Password?
                  </p>
                </div>
              </div>
              <div className="grid justify-center gap-3 grid-row-2 ">
                <button
                  type="submit"
                  className="px-4 py-2 font-bold text-white  border rounded  h-[48px] justify-center"
                  style={{
                    background:
                      "conic-gradient(from -1.53deg at 50% 50%, #00B0FF -5.36deg, #0099FF 63.75deg, #00B0FF 354.64deg, #0099FF 423.75deg), conic-gradient(from 5.86deg at 50% 50%, #00B0FF -22.78deg, #009EFF 121.02deg, rgba(0, 153, 255, 0) 325.49deg, #00B0FF 337.22deg, #009EFF 481.02deg)",
                  }}
                >
                  <span
                    className="font-bold text-xl text-center tracking-[0.08em]"
                    style={{
                      fontFamily: "ClashDisplay",
                    }}
                  >
                    SIGN IN
                  </span>
                </button>
                <p
                  className="font-medium text-[16px] text-center leading-[19.68px]"
                  style={{
                    fontFamily: "ClashDisplay",
                  }}
                >
                  Don't have an account yet?
                  <Link to="/signup">
                    <span className="font-bold underline"> Sign Up</span>
                  </Link>
                </p>
              </div>
              <div className="grid justify-center"></div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SigninForm;
