import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateOrderMutation,
} from "../../../services/order.api";
import * as Yup from "yup";
import { OrderType } from "../../../models/order.model";
import TNSelect from "../../../components/common/TNSelect";
import SelectUser from "../../User/SelectUser";
interface FormValues {
  userId?: string;
  creatorId?: string;
  firstName: string;
  lastName: string;
  referenceId: string;
  eventTicketId: string;
  quantity: number;
  exchange: number;
  type: OrderType;
}
interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateOrderV2: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateOrderMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    userId: "",
    creatorId: "",
    firstName: "",
    lastName: "",
    referenceId: "",
    eventTicketId: "",
    quantity: 0,
    exchange: 0,
    type: OrderType.MANUAL,
  };

  const validationSchema = Yup.object().shape({
    eventTicketId: Yup.string().required(),
    quantity: Yup.number().required(),
    exchange: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    console.log("values", values);
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    <li>{(error as CustomError).data.message}</li>
                  </ul>
                </div>
              )}
              <p>Select a user or input firstname and lastname</p>
              <div className="grid grid-cols-2">
                <Label htmlFor="userId">User</Label>
                {values.firstName !== "" || values.lastName !== "" ? (
                  <span>Remove firstname or lastname</span>
                ) : (
                  <SelectUser />
                )}
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="firstName">Firstname</Label>
                {values.userId !== "" ? (
                  <span>Remove User</span>
                ) : (
                  <Field name="firstName">
                    {({ field, form }: FieldProps) => (
                      <Input
                        placeholder="Firstname"
                        id="firstName"
                        {...field}
                        disabled={values.userId !== ""}
                      />
                    )}
                  </Field>
                )}
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="lastName">Lastname</Label>

                {values.userId !== "" ? (
                  <span>Remove User</span>
                ) : (
                  <Field name="lastName">
                    {({ field, form }: FieldProps) => (
                      <Input
                        placeholder="Lastname"
                        id="lastName"
                        {...field}
                        disabled={values.userId !== ""}
                      />
                    )}
                  </Field>
                )}
              </div>

              <div className="grid grid-cols-2">
                <Label htmlFor="creator">Creator (Optional)</Label>
                <SelectUser name="creatorId" />
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="eventTicketId">Event Ticket ID</Label>
                <Field name="eventTicketId">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Event Ticket ID"
                      id="eventTicketId"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="referenceId">Reference ID</Label>
                <Field name="referenceId">
                  {({ field }: FieldProps) => (
                    <Input
                      placeholder="Reference ID"
                      id="referenceId"
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="quantity">Quantity</Label>
                <Field name="quantity">
                  {({ field }: FieldProps) => (
                    <Input id="quantity" {...field} type="number" />
                  )}
                </Field>
              </div>
              <div className="grid grid-cols-2">
                <Label htmlFor="quantity">Exchange</Label>
                <TNSelect
                  options={[
                    {
                      label: "Ticketnation",
                      value: "ticketnation",
                    },
                    {
                      label: "Partner",
                      value: "partner",
                    },
                    {
                      label: "Shoppee",
                      value: "shoppee",
                    },
                    {
                      label: "Lazada",
                      value: "lazada",
                    },
                    {
                      label: "Tiktok",
                      value: "tiktok",
                    },
                  ]}
                  name="exchange"
                />
              </div>

              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateOrderV2;
