import React from "react";
import { Event } from "../../../../models/event.model";
import EventProductOrdersList from "./EventProductOrdersList";

interface ComponentProps {
  event: Event;
}
const EventProductOrders: React.FC<ComponentProps> = ({ event }) => {
  return (
    <div>
      <EventProductOrdersList event={event} />
    </div>
  );
};

export default EventProductOrders;
