import { CreateSong, UpdateSong, Song } from "../models/song.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Songs {
  data: Song[];
  skip: number;
  take: number;
  count: number;
}

interface SongsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const songApi = createApi({
  reducerPath: "songApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/songs",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Songs", "Song"],
  endpoints: (builder) => ({
    createSong: builder.mutation<Song, CreateSong>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Songs"],
    }),
    getSongs: builder.query<Songs, SongsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Songs"],
    }),
    getSong: builder.query<Song, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Song"],
    }),
    updateSong: builder.mutation<Song, UpdateSong>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Songs"],
    }),
    uploadSongImage: builder.mutation<Song, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Songs", "Song"],
    }),

    deleteSong: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Songs"],
    }),

    removeSongPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Songs", "Song"],
    }),
  }),
});

export const {
  useCreateSongMutation,
  useGetSongQuery,
  useGetSongsQuery,
  useUpdateSongMutation,
  useUploadSongImageMutation,
  useDeleteSongMutation,
  useRemoveSongPhotoMutation,
} = songApi;
