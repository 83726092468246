import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../../components/common/Modals/Modal";
import { OrderEventTicket } from "../../../../models/order.model";
import { Ticket } from "../../../../models/ticket.model";
import QRCode from "react-qr-code";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface ComponentProps {
  isOpen: boolean;
  setOpen: (value: string) => void;
  orderEventTickets: OrderEventTicket[];
}
const TicketsModal: React.FC<ComponentProps> = ({
  isOpen,
  setOpen,
  orderEventTickets,
}) => {
  const navigate = useNavigate();
  const [activeQR, setActiveQR] = useState<string | null>(null);

  const toggleQRCode = (ticketId: string) => {
    if (activeQR === ticketId) {
      setActiveQR(null); // Close the QR if it's the same ticket
    } else {
      setActiveQR(ticketId); // Open the QR for the clicked ticket
    }
  };

  const tickets = orderEventTickets
    .map((orderEventTicket) => {
      return orderEventTicket.tickets.map((ticket) => {
        return {
          ...ticket,
          price: orderEventTicket.eventTicket.price,
          perTicketFee: orderEventTicket.eventTicket.perTicketFee,
        };
      });
    })
    .reduce((prev, curr) => {
      return [...prev, ...curr];
    }, []);

  return (
    <Modal open={isOpen} setOpen={(open) => setOpen("")} title="Tickets">
      <div>
        <ul className="grid grid-cols-8 mb-2 font-semibold">
          <li></li>
          <li>Section</li>
          <li>Row</li>
          <li>Seat</li>
          <li>Price</li>
          <li>Per Ticket Fee</li>
          <li>Status</li>
          <li>Show QR</li>
        </ul>
        {tickets.map((ticket: Ticket, index) => {
          return (
            <div className="flex flex-col gap-5">
              <ul
                key={ticket.id}
                className="grid grid-cols-8"
                onClick={() =>
                  ticket.isClaimed && navigate(`/ticket-claims/${ticket.id}`)
                }
              >
                <li>{index + 1}</li>
                <li>{ticket.section}</li>
                <li>{ticket.row}</li>
                <li>{ticket.seat}</li>
                <li>{ticket.price}.00</li>
                <li>{ticket.perTicketFee}.00</li>
                <li>{ticket.isClaimed ? "Claimed" : "Available"}</li>
                <li
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleQRCode(ticket.id);
                  }}
                >
                  {activeQR === ticket.id ? <FaEyeSlash /> : <FaEye />}
                </li>{" "}
              </ul>
              {activeQR === ticket.id && (
                <div className="w-full mb-5">
                  {ticket.isClaimed && (
                    <div className=" flex items-center justify-center text-4xl lg:text-[90px] font-bold text-red-500">
                      <div className="relative flex items-center justify-center w-full h-full">
                        <div className="absolute  border-8  border-red-500 rounded-full top-[10px] bg-white bg-opacity-90 z-0 w-52 lg:w-96 h-52 lg:h-96 " />
                        <h1 className="absolute z-10 -rotate-45 lg:top-[180px] top-[105px]">
                          CLAIMED
                        </h1>
                      </div>
                    </div>
                  )}
                  <QRCode value={ticket.code} size={400} className="w-full" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default TicketsModal;
