import React from "react";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { Label } from "../../../components/common";
import { CustomError } from "../../../services/user.api";
import { UserRole } from "../../../models/user-role.model";
import { RoleSelect } from "../../Role";
import {
  useCreateUserRoleMutation,
  useGetUserRoleByUserQuery,
} from "../../../services/user-role.api";
import { UserRoleList } from "./UserRoleList";

interface FormValues {
  userId: string;
  roleId: string;
  roleIds: {
    value: string;
  };
}
interface ComponentProps {
  userId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateUserRole: React.FC<ComponentProps> = ({ setOpen, userId }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateUserRoleMutation();

  const { data: userRolesByUser, isLoading: userRolesIsLoading } =
    useGetUserRoleByUserQuery(userId);

  const userRoles = userRolesByUser
    ? userRolesByUser?.map((ur: UserRole) => ur.role?.name)
    : [];

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(true);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    userRolesByUser &&
      create({
        userId,
        roleId: values.roleIds.value,
      });
  };

  if (isLoading || userRolesIsLoading) return <h2>Loading...</h2>;
  if (!userRolesByUser) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    userId: userId,
    roleId: userId,
    roleIds: {
      value: "",
    },
  };
  const validationSchema = Yup.object().shape({
    userId: Yup.string().required(),
    roleId: Yup.string().required(),
  });
  return (
    <>
      <div className="flex gap-6 items-center">
        <p className="font-bold">USER ASSIGNED ROLES:</p>
        {userRolesByUser &&
          userRolesByUser.map((role, index) => (
            <div key={index}>
              <UserRoleList role={role} />
            </div>
          ))}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-6 gap-2 items-center my-10">
                <Label htmlFor="roleId">Roles</Label>
                <RoleSelect userRoles={userRoles} className="col-span-5" />
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add Role
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateUserRole;
