import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetPerformersQuery } from "../../../services/performer.api";
import { Option } from "../../../types/general-types";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  isMulti?: boolean;
  selectedOptions?: Option[];
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const PerformerSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  isMulti = false,
  selectedOptions = [],
  ...props
}) => {
  const { data: performers, isLoading } = useGetPerformersQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!performers) return <h2>Something went Wrong</h2>;

  const options: Option[] = performers.data
    .map((performer) => {
      const option: Option = {
        label: performer.name,
        value: performer.id.toString(),
      };
      return option;
    })
    .filter((performer: Option) => {
      if (selectedOptions) {
        if (
          selectedOptions.find((data: Option) => data.value === performer.value)
        ) {
          return false;
        }
      }
      return true;
    });

  // const getValues = () => {
  //   return performers.data
  //     .filter((performer) => defaultValue.includes(performer.id))
  //     .map((performer) => ({
  //       label: performer.name,
  //       value: performer.id,
  //     }));
  // };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name={isMulti ? "performerIds" : "performerId"}>
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="performer"
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => {
                if (value instanceof Array) {
                  form.setFieldValue(field.name, value);
                } else {
                  form.setFieldValue(field.name, value?.value);
                }
              }}
              value={options.find(
                (option: Option) => option.value === field.value
              )}
            />
          );
        }}
      </Field>
      <ErrorMessage name={isMulti ? "performerIds" : "performerId"}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default PerformerSelect;
