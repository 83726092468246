import React from "react";

interface ComponentProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  active: string;
  setActive: (value: string) => void;
  titles: string[];
  size?: "xs" | "s" | "m" | "l";
  children?: JSX.Element | JSX.Element[];
}
export const TabModal: React.FC<ComponentProps> = ({
  children,
  open,
  setOpen,
  active,
  setActive,
  titles,
  size,
}) => {
  const getSize = () => {
    switch (size) {
      case "xs":
        return "w-1/4";
      case "s":
        return "w-1/3";
      case "m":
        return "w-2/3";
      case "l":
        return "w-full";
      default:
        return "w-1/3";
    }
  };

  return (
    <>
      {open ? (
        <div
          id="defaultModal"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 z-50 flex items-center justify-center w-full h-screen overflow-y-auto bg-gray-400 bg-opacity-50 md:inset-0 h-modal md:h-full"
        >
          <div
            className={`relative max-w-2xl  bg-white rounded min-h-32 ${getSize()}`}
          >
            <div className="flex items-center justify-between p-4 text-white bg-blue-900">
              <>
                <div className="flex gap-5">
                  {titles.map((title: string, index) => (
                    <div
                      key={index}
                      className={`px-4 py-2 text-2xl cursor-pointer  ${
                        title === active &&
                        "bg-white text-blue-900 font-semibold"
                      } `}
                      onClick={() => setActive(title)}
                    >
                      {title}
                    </div>
                  ))}
                </div>
                <button
                  className="px-4 py-2 bg-blue-900 border border-white rounded"
                  onClick={() => setOpen(false)}
                >
                  Close
                </button>
              </>
            </div>
            <div className="p-10">{children}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};
