import {
  CreateGroupPerformer,
  DeleteGroupPerformer,
} from "./../models/group.model";
import {
  CreateGroup,
  UpdateGroup,
  Group,
  CreateGroupSong,
  CreateGroupGenre,
  GroupGenre,
  DeleteGroupGenre,
  GroupSong,
  DeleteGroupSong,
  GroupPerformer,
} from "../models/group.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Groups {
  data: Group[];
  skip: number;
  take: number;
  count: number;
}

interface GroupsQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const groupApi = createApi({
  reducerPath: "groupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/group",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Groups", "Group"],
  endpoints: (builder) => ({
    createGroup: builder.mutation<Group, CreateGroup>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Groups"],
    }),
    getGroups: builder.query<Groups, GroupsQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Groups"],
    }),
    getGroup: builder.query<Group, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Group"],
    }),
    updateGroup: builder.mutation<Group, UpdateGroup>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Groups", "Group"],
    }),
    uploadGroupImage: builder.mutation<Group, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Groups", "Group"],
    }),
    deleteGroup: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Groups"],
    }),
    removeGroupPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Groups", "Group"],
    }),
    uploadGroupBanner: builder.mutation<Group, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/banner`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Groups", "Group"],
    }),
    removeGroupBanner: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/banner`,
        method: "DELETE",
      }),
      invalidatesTags: ["Groups", "Group"],
    }),
    createGroupGenre: builder.mutation<GroupGenre, CreateGroupGenre>({
      query: ({ groupId, ...body }) => ({
        url: `/${groupId}/genre`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroupGenre: builder.mutation<void, DeleteGroupGenre>({
      query: (body) => ({
        url: `/${body.groupId}/genre`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
    createGroupSong: builder.mutation<GroupSong, CreateGroupSong>({
      query: ({ groupId, ...body }) => ({
        url: `/${groupId}/song`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroupSong: builder.mutation<void, DeleteGroupSong>({
      query: (body) => ({
        url: `/${body.groupId}/song`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
    createGroupPerformer: builder.mutation<
      GroupPerformer,
      CreateGroupPerformer
    >({
      query: ({ groupId, ...body }) => ({
        url: `/${groupId}/performer`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroupPerformer: builder.mutation<void, DeleteGroupPerformer>({
      query: (body) => ({
        url: `/${body.groupId}/performer`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Group"],
    }),
  }),
});

export const {
  useCreateGroupMutation,
  useGetGroupQuery,
  useGetGroupsQuery,
  useUpdateGroupMutation,
  useUploadGroupImageMutation,
  useDeleteGroupMutation,
  useRemoveGroupPhotoMutation,
  useUploadGroupBannerMutation,
  useRemoveGroupBannerMutation,
  useCreateGroupGenreMutation,
  useDeleteGroupGenreMutation,
  useCreateGroupSongMutation,
  useDeleteGroupSongMutation,
  useCreateGroupPerformerMutation,
  useDeleteGroupPerformerMutation,
} = groupApi;
