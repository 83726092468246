import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateCategoryMutation,
} from "../../../services/category.api";
import * as Yup from "yup";
import { ProductSelect } from "../../Product";

interface Option {
  label: string;
  value: string;
}
interface FormValues {
  name: string;
  product?: Option;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCategory: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateCategoryMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    name: "",
    product: undefined,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    product: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (values.product)
      create({
        ...values,
        productId: values.product.value,
      });
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values }) => {
  
        return (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="grid grid-cols-2 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input placeholder="Name" id="name" {...field} />
                  )}
                </Field>
                <Label htmlFor="product">Product</Label>
                <ProductSelect />
              </div>

              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Add
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
