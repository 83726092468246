import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateVenueMutation,
} from "../../../services/venue.api";
import * as Yup from "yup";

interface FormValues {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  lat?: number;
  lng?: number;
}

interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateVenue: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateVenueMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    address1: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    lat: Yup.number().required(),
    lng: Yup.number().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-2 gap-2">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input placeholder="Name" id="name" {...field} />
                )}
              </Field>
              <Label htmlFor="address1">Address 1</Label>
              <Field name="address1">
                {({ field }: FieldProps) => (
                  <Input placeholder="Address 1" id="address1" {...field} />
                )}
              </Field>
              <Label htmlFor="address2">Address 2</Label>
              <Field name="address2">
                {({ field }: FieldProps) => (
                  <Input
                    placeholder="Address 2 (Optional)"
                    id="address2"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="city">City</Label>
              <Field name="city">
                {({ field }: FieldProps) => (
                  <Input placeholder="City" id="city" {...field} />
                )}
              </Field>
              <Label htmlFor="state">State</Label>
              <Field name="state">
                {({ field }: FieldProps) => (
                  <Input placeholder="State" id="state" {...field} />
                )}
              </Field>
              <Label htmlFor="country">Country</Label>
              <Field name="country">
                {({ field }: FieldProps) => (
                  <Input placeholder="Country" id="country" {...field} />
                )}
              </Field>
              <Label htmlFor="latitude">Latitude</Label>
              <Field name="lat">
                {({ field }: FieldProps) => (
                  <Input
                    placeholder="Latitude"
                    type="number"
                    id="latitude"
                    {...field}
                  />
                )}
              </Field>
              <Label htmlFor="longitude">Longitude</Label>
              <Field name="lng">
                {({ field }: FieldProps) => (
                  <Input
                    placeholder="Longitude"
                    type="number"
                    id="longitude"
                    {...field}
                  />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateVenue;
