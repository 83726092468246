import React from "react";
import Select from "react-select";
import { ErrorMessage, Field, FieldProps } from "formik";
import { useGetGenresQuery } from "../../../services/genre.api";

interface ComponentProps {
  className?: string;
  defaultValue?: string[];
  isMulti?: boolean
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
export const GenreSelect: React.FC<TExtendedProps> = ({
  className,
  defaultValue = [],
  isMulti = false,
  ...props
}) => {
  const { data: genres, isLoading } = useGetGenresQuery({});
  if (isLoading) return <h2>Loading...</h2>;
  if (!genres) return <h2>Something went Wrong</h2>;

  const options = genres.data.map((genre) => {
    return {
      label: genre.name,
      value: genre.id,
    };
  });

  const getValues = () => {
    return genres.data
      .filter((genre) => defaultValue.includes(genre.id))
      .map((genre) => ({
        label: genre.name,
        value: genre.id,
      }));
  };

  return (
    <div className={`${className} flex flex-col`}>
      <Field name="genre">
        {({ field, form }: FieldProps) => {
          return (
            <Select
              id="genre"
              defaultValue={getValues()}
              isMulti={isMulti}
              name="colors"
              options={options}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 51 }) }}
              menuPortalTarget={document.querySelector("body")}
              onChange={(value) => form.setFieldValue(field.name, value)}
              value={field.value}
            />
          );
        }}
      </Field>
      <ErrorMessage name="genre">
        {(message) => <span className="text-red-500">Genre is required</span>}
      </ErrorMessage>
    </div>
  );
};

