import React from "react";
import { Modal } from "../../../components/common/Modals/Modal";
import ActionDropdown from "../../../components/common/ActionDropdown";
import CreateEvent from "../../../features/Event/CreateEvent";
import EventList from "../../../features/Event/EventList";

export const EventPage = () => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [activeFilter, setActiveFilter] = React.useState<
    | {
        value?: boolean;
        label?: string;
      }
    | undefined
  >();
  const [input, setInput] = React.useState("");
  const handleSearch = () => {
    setSearch(input);
  };

  return (
    <>
      <div className="flex items-center justify-between gap-5 p-6 mb-6 border rounded shadow-sm align">
        <h2 className="text-2xl font-semibold ">Event</h2>
        <div className="flex items-center flex-1 px-6 ">
          <input
            className="flex-1 px-4 py-2 border border-blue-900 rounded-l-md"
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button
            className="px-4 py-2 text-white bg-blue-900 border-r border-blue-900 border-y rounded-r-md"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <button
          className="w-24 px-4 py-2 text-blue-900 border border-blue-900 rounded"
          type="button"
          onClick={() => setOpen(true)}
        >
          Add
        </button>
        <ActionDropdown
          className="w-[10%]"
          placeholder={activeFilter?.label || "All"}
          onClick={(value) => setActiveFilter(value)}
          options={[
            {
              label: "All",
              value: undefined,
            },
            {
              label: "Active",
              value: true,
            },
            {
              label: "Inactive",
              value: false,
            },
          ]}
        />
      </div>
      <EventList search={search} activeFilter={activeFilter?.value} />
      <Modal open={open} setOpen={setOpen} title="Add Event" size="m">
        <CreateEvent setOpen={setOpen} />
      </Modal>
    </>
  );
};
