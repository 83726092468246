import { User } from "../models/user.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Users {
  data: User[];
  skip: number;
  take: number;
  count: number;
}

interface UsersQuery {
  skip?: number;
  take?: number;
  search?: string;
  emailType?: string;
  eventsId?: string[];
  id?: string | string[];
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/users",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Users", "User", "UserRoles"],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    getUsers: builder.query<Users, UsersQuery>({
      query: ({ take, skip, search }) => ({
        url: `/?skip=${skip}&take=${take}&search=${search}`,
      }),
      providesTags: ["Users", "UserRoles"],
    }),
    getUser: builder.query<User, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<User, User>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Users", "User"],
    }),
    uploadUserImage: builder.mutation<User, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Users", "User"],
    }),
    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    removeUserPhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users", "User"],
    }),
    getUsersByEmailType: builder.query<Users, UsersQuery>({
      query: ({ id, ...rest }) => {
        return {
          url: `/email-type/${id}`,
          params: {
            ...rest,
          },
        };
      },
      providesTags: ["Users"],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useUploadUserImageMutation,
  useDeleteUserMutation,
  useRemoveUserPhotoMutation,
  useLazyGetUsersQuery,
  useGetUsersByEmailTypeQuery,
} = userApi;
