import React, { useRef } from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label, Checkbox } from "../../../components/common";

import {
  CustomError,
  useGetOrganizationQuery,
  useRemoveOrganizationPhotoMutation,
  useUpdateOrganizationMutation,
  useUploadOrganizationImageMutation,
} from "../../../services/organization.api";

interface FormValues {
  name: string;
  url: string;
  isActive: boolean;
}
interface ComponentProps {
  organizationId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateOrganization: React.FC<ComponentProps> = ({
  setOpen,
  organizationId,
}) => {
  const [update, { isLoading, isError, error, isSuccess }] =
    useUpdateOrganizationMutation();
  const [upload, { isLoading: uploadIsLoading }] =
    useUploadOrganizationImageMutation();

  const { data: organization, isLoading: organizationIsLoading } =
    useGetOrganizationQuery(organizationId);

  const [removePhoto, { isLoading: removePhotoIsLoading }] =
    useRemoveOrganizationPhotoMutation();

  const ref = useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    if (organization)
      update({
        id: organization.id,
        ...values,
      });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files?.length > 0)
      upload({
        id: organizationId,
        file: e.target.files[0],
      });
  };

  const handleBrowse = () => {
    if (ref.current) ref.current.click();
  };

  const handlePhotoRemove = () => {
    removePhoto(organizationId);
  };

  if (
    isLoading ||
    organizationIsLoading ||
    uploadIsLoading ||
    removePhotoIsLoading
  )
    return <h2>Loading...</h2>;
  if (!organization) return <h2>Something went wrong..</h2>;
  const initialValues: FormValues = {
    name: organization?.name || "",
    url: organization?.url || "",
    isActive: organization?.isActive || false,
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={() => {}}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="grid gap-2">
              {isError && error && (
                <div className="text-red-500">
                  <h3 className="font-semibold">Error(s)</h3>
                  <ul>
                    {(error as CustomError).data.message.map((message) => (
                      <li>{message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex items-center">
                <div className="flex items-center justify-center w-20 h-20 mr-4 overflow-hidden bg-black rounded">
                  {organization && organization.image ? (
                    <img
                      alt={`${organization.name}_image`}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL +
                        organization.image?.fileName
                      }
                    />
                  ) : (
                    <div className="bg-black " />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <input
                    name="upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    ref={ref}
                  />
                  <button
                    className="px-4 py-2 text-blue-900 border border-blue-900 rounded"
                    type="button"
                    onClick={() => handleBrowse()}
                  >
                    Update Photo
                  </button>
                  <button
                    className="px-4 py-2 text-white bg-red-500 border rounded"
                    type="button"
                    onClick={() => handlePhotoRemove()}
                  >
                    Remove Photo
                  </button>
                  <p className="col-span-2 text-xs">
                    Image should be at least 400 x 400px as a png or jpeg file.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-2">
                <Label htmlFor="name">Name</Label>
                <Field name="name">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Name"
                      id="name"
                      {...field}
                    />
                  )}
                </Field>
                <Label htmlFor="url">Url</Label>
                <Field name="url">
                  {({ field }: FieldProps) => (
                    <Input
                      className="col-span-2"
                      placeholder="Url"
                      id="url"
                      {...field}
                    />
                  )}
                </Field>
                {/* To do check default value */}
                <Label htmlFor="isActive">Active</Label>
                <Field name="isActive">
                  {({ field }: FieldProps) => (
                    <Checkbox className="col-span-2" id="isActive" {...field} />
                  )}
                </Field>
              </div>
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UpdateOrganization;
