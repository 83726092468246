import { RemovePermissionToRole } from './../models/role.model';
import { CreateRole, UpdateRole, Role, AddPermissionToRole } from "../models/role.model";
import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface Roles {
  data: Role[];
  skip: number;
  take: number;
  count: number;
}

interface RolesQuery {
  skip?: number;
  take?: number;
  search?: string;
}

export const roleApi = createApi({
  reducerPath: "roleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/roles",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["Roles", "Role"],
  endpoints: (builder) => ({
    createRole: builder.mutation<Role, CreateRole>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    addPermissionToRole: builder.mutation<Role, AddPermissionToRole>({
      query: ({roleId, ...body}) => ({
        url: `/${roleId}/permission`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles", 'Role'],
    }),
    getRoles: builder.query<Roles, RolesQuery>({
      query: ({ take, skip, search }) => {
        return {
          url: "/",
          params: {
            skip,
            take,
            search,
          },
        };
      },
      providesTags: ["Roles"],
    }),
    getRole: builder.query<Role, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["Role"],
    }),
    updateRole: builder.mutation<Role, UpdateRole>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    uploadRoleImage: builder.mutation<Role, { id: string; file: File }>({
      query: ({ id, ...body }) => {
        const formData = new FormData();
        formData.append("file", body.file);
        return {
          url: `/${id}/upload`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: ["Roles", "Role"],
    }),
    deleteRole: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Roles"],
    }),

    removePermissionToRole: builder.mutation<Role, RemovePermissionToRole>({
      query: ({roleId, permissionId}) => ({
        url: `/${roleId}/permission/${permissionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Roles", 'Role'],
    }),
    removeRolePhoto: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/photo`,
        method: "DELETE",
      }),
      invalidatesTags: ["Roles", "Role"],
    }),
  }),
});

export const {
  useCreateRoleMutation,
  useGetRoleQuery,
  useGetRolesQuery,
  useUpdateRoleMutation,
  useUploadRoleImageMutation,
  useDeleteRoleMutation,
  useRemoveRolePhotoMutation,
  useAddPermissionToRoleMutation,
  useRemovePermissionToRoleMutation
} = roleApi;
