import { ErrorMessage, Field } from "formik";
import React from "react";
import { useGetEventVoucherByEventQuery } from "../../../../services/event-voucher.api";
import { Voucher } from "../../../../models/voucher.model";

interface ComponentProps {
  eventId: string;
  name: string;
  className?: string;
  setVoucher: React.Dispatch<React.SetStateAction<Voucher | undefined>>;
  selectedVoucherCode?: string;
}

type TExtendedProps = ComponentProps & React.HTMLProps<HTMLSelectElement>;
const SelectVoucher: React.FC<TExtendedProps> = ({
  className,
  eventId,
  name,
  setVoucher,
  selectedVoucherCode,
  ...props
}) => {
  const { data: eventVouchers, isLoading: eventVoucherIsLoading } =
    useGetEventVoucherByEventQuery(eventId);

  React.useEffect(() => {
    if (selectedVoucherCode && selectedVoucherCode !== "") {
      const selectedVoucher =
        eventVouchers &&
        eventVouchers.find(
          ({ voucher }) => voucher.code === selectedVoucherCode
        )?.voucher;
      setVoucher(selectedVoucher);
    } else if (selectedVoucherCode === "") {
      setVoucher(undefined);
    }
  }, [selectedVoucherCode, eventVouchers, setVoucher]);

  return (
    <div className={`${className} flex flex-col`}>
      <Field
        {...props}
        className={`py-2 border rounded `}
        as="select"
        name={name}
      >
        <option value="">-- Select Voucher --</option>
        {eventVouchers &&
          eventVouchers.map(({ voucher }, index) => (
            <option key={index} value={voucher.code}>
              {voucher.name}
            </option>
          ))}
      </Field>
      <ErrorMessage name={name}>
        {(message) => <span className="text-red-500">{message}</span>}
      </ErrorMessage>
    </div>
  );
};

export default SelectVoucher;
