import { ErrorMessage, Field, FieldProps } from "formik";
import React from "react";
import ReactSelect from "react-select";
import { OptionValue } from "../../../../components/common/TNSelect";
import { useGetUsersByEmailTypeQuery } from "../../../../services/user.api";

interface ComponentProps {
  eventId?: string;
  eventIds?: string[];
  excludeIds?: string[];
  className?: string;
  emailType?: string;
  setUsersEmail: React.Dispatch<React.SetStateAction<string[]>>;
}

type TSelectOption = {
  value: string;
  label: string;
};

const SelectUser: React.FC<ComponentProps> = ({
  eventId,
  eventIds,
  setUsersEmail,
  excludeIds,
  className,
  emailType,
}) => {
  const [search, setSearch] = React.useState<string>();
  const [typingTimeout, setTypingTimeout] =
    React.useState<NodeJS.Timeout | null>(null);
  const [skip] = React.useState<number>(0);
  const [take] = React.useState<number>(500);
  const [optionSelected, setOptionSelected] = React.useState<TSelectOption[]>(
    []
  );

  const eventsId =
    emailType === "PROMOTION" || emailType === "PRESALE" ? eventIds : [];

  console.log("eventsId", eventsId);
  const { data, isLoading } = useGetUsersByEmailTypeQuery({
    id: eventId,
    emailType,
    eventsId,
    search,
    skip,
    take,
  });

  const options: TSelectOption[] = data
    ? data.data
        .filter((data) => !excludeIds?.includes(data.email))
        .map((user) => ({
          label: user.email,
          value: user.email,
        }))
    : [{ label: "", value: "" }];

  React.useEffect(() => {
    if (optionSelected !== undefined) {
      const userEmails = optionSelected.map((data: OptionValue) => data.value);
      setUsersEmail(userEmails);
    }
  }, [optionSelected, setUsersEmail]);

  React.useEffect(() => {
    setOptionSelected([]);
  }, [eventId]);

  const handleSearchChange = (value: string) => {
    // Clear the previous timer, if any
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timer to debounce the search
    const newTypingTimeout = setTimeout(() => {
      setSearch(value);
    }, 500); // Adjust the debounce delay (in milliseconds) as needed

    setTypingTimeout(newTypingTimeout);
  };

  return (
    <div className={`${className}`}>
      <div>
        <Field name="userEmails">
          {({ field, form }: FieldProps) => {
            return (
              <ReactSelect
                id="userEmails"
                isMulti
                name="userEmails"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={optionSelected}
                options={options}
                onInputChange={handleSearchChange}
                onChange={(option: any) => setOptionSelected(option)}
                styles={{
                  valueContainer: (base) => ({
                    ...base,
                    overflowX: "scroll",
                    flexWrap: "unset",
                  }),
                  multiValue: (base) => ({
                    ...base,
                    flex: "1 0 auto",
                  }),
                }}
              />
            );
          }}
        </Field>
        <ErrorMessage name="userEmails">
          {(message) => <span className="text-red-500">{message}</span>}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default SelectUser;
