import React from "react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Input, Label } from "../../../components/common";

import {
  CustomError,
  useCreateOrganizationMutation,
} from "../../../services/organization.api";
import * as Yup from "yup";
interface FormValues {
  name: string;
  url: string;
}
interface ComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateOrganization: React.FC<ComponentProps> = ({ setOpen }) => {
  const [create, { isLoading, isError, error, isSuccess }] =
    useCreateOrganizationMutation();

  React.useEffect(() => {
    if (isSuccess && !isLoading) {
      setOpen(false);
    }
  }, [isSuccess, isLoading, setOpen]);

  const initialValues: FormValues = {
    name: "",
    url: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const handleSubmit = (
    values: FormValues,
    action: FormikHelpers<FormValues>
  ) => {
    create(values);
  };

  if (isLoading) return <h2>Loading...</h2>;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <div className="grid gap-2">
            {isError && error && (
              <div className="text-red-500">
                <h3 className="font-semibold">Error(s)</h3>
                <ul>
                  {(error as CustomError).data.message.map((message) => (
                    <li>{message}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="grid grid-cols-3 gap-2">
              <Label htmlFor="name">Name</Label>
              <Field name="name">
                {({ field }: FieldProps) => (
                  <Input  className="col-span-2" placeholder="Name" id="name" {...field} />
                )}
              </Field>
              <Label htmlFor="url">Url</Label>
              <Field name="url">
                {({ field }: FieldProps) => (
                  <Input  className="col-span-2" placeholder="Url" id="url" {...field} />
                )}
              </Field>
            </div>

            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-blue-900 border rounded"
            >
              Add
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrganization;
