import React from "react";
import { Tool, Copy, Delete } from "react-feather";
import {
  useDeleteEventTicketInclusionMutation,
  useGetEventTicketInclusionQuery,
} from "../../../../services/event-ticket.api";
import { EventTicketInclusion } from "../../../../models";
import DynamicTable, { TableData } from "../../../../components/common/Table";
import ActionDropdown from "../../../../components/common/ActionDropdown";

interface ComponentProps {
  eventTicketId: string;
}
const EventTicketInclusionList: React.FC<ComponentProps> = ({
  eventTicketId,
}) => {
  const [page, setPage] = React.useState(1);
  const [take] = React.useState(10);
  const [active, setActive] = React.useState("");
  const [skip, setSkip] = React.useState(0);
  const { data, isLoading } = useGetEventTicketInclusionQuery({
    eventTicketId,
  });
  const [deleteEventTicketInclusion] = useDeleteEventTicketInclusionMutation();
  console.log("data", data);

  const columns = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "description",
      label: "Description",
    },
  ];

  React.useEffect(() => {
    setSkip((page - 1) * take);
  }, [page, take]);

  const tableData: TableData[] =
    data?.data.map((item: EventTicketInclusion, index) => ({
      id: item.id,
      name: item.name,
      description: item.description,
    })) || [];

  return (
    <div>
      <DynamicTable
        currentPage={page}
        setCurrentPage={setPage}
        itemsPerPage={take}
        total={data?.count || 0}
        columns={columns}
        data={tableData}
        actionComponent={({ id }) => {
          const eventTicketInclusion = data?.data.find(
            (eventTicketInclusion) => eventTicketInclusion.id === id
          );
          if (!eventTicketInclusion) return null;
          return (
            <div className="p-2 text-sm text-gray-700 break-words">
              <ActionDropdown
                placeholder="Actions"
                icon={<Tool className="w-5 h-5" />}
                options={[
                  {
                    label: "Copy ID",
                    onClick: () => {
                      navigator.clipboard.writeText(eventTicketInclusion.id);
                    },
                    icon: <Copy className="w-5 h-5" />,
                  },
                  {
                    label: "Delete",
                    onClick: () => {
                      deleteEventTicketInclusion({
                        eventTicketId: eventTicketId,
                        eventTicketInclusionId: eventTicketInclusion.id,
                      });
                    },
                    icon: <Delete className="w-5 h-5" />,
                  },
                ]}
              />
            </div>
          );
        }}
        isCollapsible
        active={active}
      />
    </div>
  );
};

export default EventTicketInclusionList;
