import React from "react";
import { useParams } from "react-router-dom";
import UpdatePerformer from "../../features/Performer/UpdatePerformer";
import ViewPerformer from "../../features/Performer/ViewPerformer";

type UpdatePerformerParams = {
  id: string;
};

export const UpdatePerformerPage: React.FC = () => {
  const { id } = useParams<UpdatePerformerParams>() as UpdatePerformerParams;

  return (
    <div>
      <UpdatePerformer performerId={id} />
    </div>
  );
};
