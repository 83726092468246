import { RootState } from "../app/store";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { CreateUserRole, UserRole } from "../models/user-role.model";

export interface CustomError {
  data: {
    message: [string];
  };
}

interface UserRoles {
  data: UserRole[];
  skip: number;
  take: number;
  count: number;
}

interface UsersRolesQuery {
  skip: number;
  take: number;
  search: string;
}

export const userRoleApi = createApi({
  reducerPath: "userRoleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL + "/user-role",
    prepareHeaders: (headers, { getState }) => {
      const jwtToken = (getState() as RootState).auth.accessToken;
      if (jwtToken) {
        headers.set("Authorization", `Bearer ${jwtToken}`);
      }
      return headers;
    },
  }) as BaseQueryFn<FetchArgs, unknown, CustomError>,
  tagTypes: ["UserRoles", "UserRole"],
  endpoints: (builder) => ({
    createUserRole: builder.mutation<UserRole, CreateUserRole>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserRoles"],
    }),
    getUserRoles: builder.query<UserRoles, UsersRolesQuery>({
      query: ({ take, skip }) => ({
        url: `/?skip=${skip}&take=${take}`,
      }),
      providesTags: ["UserRoles"],
    }),
    getUserRole: builder.query<UserRole, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ["UserRoles"],
    }),
    getUserRoleByUser: builder.query<UserRole[], string>({
      query: (userId) => ({
        url: `/user/${userId}`,
      }),
      providesTags: ["UserRoles"],
    }),
    deleteUserRoleById: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserRoles"],
    }),
  }),
});

export const {
  useCreateUserRoleMutation,
  useGetUserRoleQuery,
  useGetUserRolesQuery,
  useGetUserRoleByUserQuery,
  useDeleteUserRoleByIdMutation,
} = userRoleApi;
