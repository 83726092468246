import React from "react";

interface ComponentProps {
  children: JSX.Element | JSX.Element[];
}
const Body: React.FC<ComponentProps> = ({ children }) => {
  return <div className="flex-grow p-2 overflow-scroll bg-white lg:p-6 rounded-l-3xl">{children}</div>;
};

export default Body;
