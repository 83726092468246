import dayjs from "dayjs";
import React from "react";
import { OrderDetails } from "../../../services/order-details.api";
import { useGetOrderQuery } from "../../../services/order.api";
import OrderDetailsPage from "../OrderDetails";

interface ComponentProps {
  orderId: string;
}

interface OrderDetailsGrouped {
  eventTicketName: string | null;
  subTotal: number;
  transactions: OrderDetails[];
}

const SelectedOrder: React.FC<ComponentProps> = ({ orderId }) => {
  const { data: order, isLoading: orderIsLoading } = useGetOrderQuery(orderId);

  const orderData = [
    {
      label: "Order ID",
      value: order?.id,
    },
    {
      label: "Order Number",
      value: order?.orderNumber,
    },
    {
      label: "User Email",
      value: order?.user.email,
    },
    {
      label: "Order Date",
      value: dayjs(order?.createdAt).format("MMMM DD, YYYY hh:mm A"),
    },
    {
      label: "Event Name",
      value: order?.orderEventTickets[0]?.eventTicket?.event?.name,
    },
    {
      label: "Event Date",
      value: dayjs(
        order?.orderEventTickets[0]?.eventTicket?.event?.dateTime
      ).format("MMMM DD, YYYY hh:mm A"),
    },
  ];

  return (
    <div className="flex flex-col gap-5">
      {order && <OrderDetailsPage order={order} />}
      <div className="flex bg-white shadow-xl h-fit rounded-sm w-full">
        <div className="w-full">
          <div>
            {orderData?.map((order, i) => (
              <div
                key={i}
                className={`flex flex-col mx-4 py-4 md:flex-row gap-4 md:p-8 ${
                  i !== 0 &&
                  "border border-x-0 border-b-0 border-t-1 border-blue-100"
                }`}
              >
                <ul className="flex flex-col md:flex-row justify-between w-full">
                  <li className="font-medium">{order.label}</li>
                  <li>{order.value}</li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedOrder;
